import cn from 'utils/classnames'
import { supify } from '../../../utils'
import { LinkToRedirect } from '../../link-to-redirect'
import ProductRating from './product-rating'

export const DisplayName: React.FC<{
  layoutSlim: boolean
  displayName: boolean
  externalId: string
  productTip: any
  orderId: string
  query: string
  shouldShowAwardsBadge: boolean
}> = ({
  layoutSlim,
  displayName,
  externalId,
  productTip,
  orderId,
  query,
  shouldShowAwardsBadge,
}) => {
  return (
    <div
      className={`c-card-tile__content mb-1.5 / ${
        layoutSlim
          ? 'flex flex-col items-center justify-center / w-3/4 xxs:w-72pct pl-2 xxs:pl-3'
          : 'px-4'
      }`}
    >
      {displayName && (
        <h3
          className={cn(
            'text-primary-mid',
            'leading-snug',
            'font-bold',
            layoutSlim
              ? [
                  'text-left',
                  'text-sm',
                  'xxs:text-base',
                  'xs:text-lg',
                  'sm:text-sm',
                  ...(shouldShowAwardsBadge ? ['pr-7'] : []),
                ]
              : 'text-center',
          )}
        >
          <LinkToRedirect
            externalId={externalId}
            linkParams={
              productTip._type === 'card'
                ? productTip.applyNowLinkParameters
                : []
            }
            orderId={orderId}
            query={query !== '' ? query : undefined}
            slug={productTip.slug}
          >
            <div
              className="inline-block / w-full / hover:text-primary-bright cursor-pointer"
              dangerouslySetInnerHTML={{
                __html: supify(productTip.name),
              }}
            />
          </LinkToRedirect>
        </h3>
      )}

      <ProductRating
        productTip={productTip}
        fontSize="text-5xl"
        layoutSlim={layoutSlim ? true : false}
      />
    </div>
  )
}
