import Image from 'next/image'
import Link from 'next/link'
import { useEffect, useState } from 'react'

import cn from 'utils/classnames'
import { useShouldShowAwardsBadgeForExp364 } from 'utils/experimentation/useShouldShowAwardsBadgeForExp364'
import { useShouldShowAwardsBadgeForExp368 } from 'utils/experimentation/useShouldShowAwardsBadgeForExp368'
import { CompareCredit } from '../../../types/compare-credit'
import { clickRatesAndFees } from '../../clients/segment'
import { CardArt, LinkToRedirect } from '../../components'
import { supify, nextImageHelper } from '../../utils'
import { SecureSite } from '../secure-site'
import { ApplyNowIcon } from '../link-to-redirect/ApplyNowIcon'

export const CardNavV2: React.FC<{
  cardUpsellHideLg?: boolean
  cardUpsellInline?: boolean
  cardUpsellSecureSite?: boolean
  cardUpsellName?: boolean
  cardUpsellPosition?: string
  cardUpsellRibbon?: string
  cardUpsellOfferCopy?: string
  cardUpsellProduct?: CompareCredit.FormattedCard | CompareCredit.NonPaidCard
  orderId: string
  categoryId: string
  externalId: string
  theme?: string
}> = ({
  cardUpsellProduct: card,
  cardUpsellHideLg,
  cardUpsellInline,
  cardUpsellSecureSite,
  cardUpsellName,
  cardUpsellPosition,
  cardUpsellRibbon,
  cardUpsellOfferCopy,
  orderId,
  categoryId,
  externalId,
  theme,
}) => {
  const [browserSize, setBrowserSize] = useState({
    width: 0,
  })
  const [cardNavIsSticky, setCardNavIsSticky] = useState(false)

  // Display card nav on scroll
  useEffect(() => {
    let pgOffset = 350

    if (browserSize.width > 767) {
      pgOffset = 680
    }

    // Capture window width/height on resize
    const handleResize = () =>
      setBrowserSize({
        width: window.innerWidth,
      })
    window.addEventListener('resize', handleResize)

    // Reset pgOffset cutoff
    if (browserSize.width > 767) {
      pgOffset = 680
    }

    const handleCardNavStick = () =>
      setCardNavIsSticky(window.pageYOffset > pgOffset)
    window.addEventListener('scroll', handleCardNavStick)

    return () => {
      window.removeEventListener('scroll', handleCardNavStick)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const shouldShowAwardsBadgeForExp364 = useShouldShowAwardsBadgeForExp364()
  const shouldShowAwardsBadgeForExp368 = useShouldShowAwardsBadgeForExp368()

  const shouldShowAwardsBadge =
    shouldShowAwardsBadgeForExp364 || shouldShowAwardsBadgeForExp368

  return (
    <div
      aria-hidden={cardNavIsSticky}
      role="complementary"
      className={cn(
        'c-card-sticky / fixed left-0 z-70 / flex flex-col items-center / w-full / leading-snug bg-white shadow-lg / transition-all--25',
        theme,
        {
          'justify-center': cardUpsellInline,
          'c-card-sticky--top': cardUpsellPosition === 'default',
          'c-card-sticky--bottom': cardUpsellPosition === 'bottom',
          'is-shown': cardNavIsSticky,
          'lg:hidden': cardUpsellHideLg,
        },
      )}
    >
      {cardUpsellName && !cardUpsellInline ? (
        <div className="container-fluid / -mb-2.5 / pt-0.5 / text-center">
          {card &&
            (card._type === 'card' ? (
              <LinkToRedirect
                externalId={externalId}
                linkParams={card.applyNowLinkParameters}
                slug={card.slug}
                orderId={orderId}
              >
                <div
                  className={`c-card-sticky__name / inline-block / leading-tight font-bold text-primary-mid text-sm xs:text-base / hover:text-primary-bright`}
                  dangerouslySetInnerHTML={{ __html: supify(card.name) }}
                />
              </LinkToRedirect>
            ) : (
              <Link href={card.moreInfoLink}>
                <div
                  className={`c-card-sticky__name / inline-block / leading-tight font-bold text-primary-mid text-sm xs:text-base / hover:text-primary-bright`}
                  dangerouslySetInnerHTML={{ __html: supify(card.name) }}
                />
              </Link>
            ))}
        </div>
      ) : (
        ''
      )}
      <div
        className={cn(
          'container-fluid',
          shouldShowAwardsBadge && 'px-2',
          'py-2',
          'md:flex',
          'md:items-center',
          'md:justify-center',
        )}
      >
        <div
          className={cn(
            'flex',
            'flex-row',
            'justify-center',
            shouldShowAwardsBadge ? 'items-start' : 'items-center',
            'w-full',
            'md:max-w-6xl',
          )}
        >
          {shouldShowAwardsBadge && (
            <div className="c-card-sticky__awards-banner w-16 xs:w-17 mr-2 xs:mr-4 md:mr-5">
              <span className="inline-block w-full">
                <Image
                  width={16}
                  height={16}
                  alt="CompareCredit Best Credit Cards 2025"
                  src="/static/awards/cc-awards-ribbon-2025.svg"
                  sizes="100vw"
                  style={nextImageHelper.responsive}
                  loading="lazy"
                />
              </span>
            </div>
          )}
          <div
            className={cn('flex flex-col / pr-1 xs:pr-2 md:pr-3', {
              'w-1/3 xs:w-auto': !cardUpsellInline,
            })}
          >
            {cardUpsellRibbon && (
              <div className="c-card-sticky__ribbon-wrap / inline-block / mb-0.5 sm:mb-1 -mt-1.5 sm:mt-0 / text-center">
                <p className="c-ribbon c-ribbon--slimmer / relative inline-block sm:flex sm:items-center justify-center / px-2 / font-bold text-sm text-primary leading-snug uppercase / bg-tetriary">
                  {cardUpsellRibbon}
                </p>
              </div>
            )}
            {card && (
              <div
                className={cn(
                  'c-card-art mx-auto / text-center flex items-center justify-center',
                  {
                    'w-14 xs:w-20': cardUpsellInline,
                    'w-18': !cardUpsellInline,
                  },
                )}
              >
                <div className="w-full relative / rounded-sm shadow">
                  {card._type === 'card' ? (
                    <LinkToRedirect
                      externalId={externalId}
                      linkParams={card.applyNowLinkParameters}
                      slug={card.slug}
                      orderId={orderId}
                    >
                      <div className="relative flex rounded-sm overflow-hidden / focus:border-blue-800 focus:ring-blue active:bg-blue-800">
                        <span className="c-card-art__content / z-10 absolute left-0 / flex flex-wrap content-center items-center / w-full h-full text-white leading-normal">
                          <span
                            className={cn('w-4 h-4 mx-auto mb-0.5', {
                              'hidden xs:inline-block': cardUpsellInline,
                            })}
                          >
                            <div className="inline-block w-4">
                              <ApplyNowIcon
                                height={32}
                                width={32}
                                aria-hidden={true}
                                style={nextImageHelper.responsive}
                                sizes="100vw"
                              />
                            </div>
                          </span>
                          <span className="w-full font-semibold text-fs12">
                            Apply Now
                          </span>
                        </span>
                        <CardArt
                          _rev={card._rev}
                          cardArt={card.cardArt}
                          categoryId={categoryId}
                          externalId={externalId}
                          customCodeSnippets={card.customCodeSnippets}
                          imgClasses="relative z-0 w-full h-full object-cover top-0"
                          name={card.name}
                          issuer={card.issuer.slug.current}
                          slug={card.slug}
                          orderId={orderId}
                        />
                      </div>
                    </LinkToRedirect>
                  ) : (
                    <Link href={card.moreInfoLink}>
                      <div className="relative flex rounded-sm overflow-hidden / focus:border-blue-800 focus:ring-blue active:bg-blue-800">
                        <span className="c-card-art__content / z-10 absolute left-0 / flex flex-wrap content-center items-center / w-full h-full text-white leading-normal">
                          <span className="w-full font-semibold text-fs10">
                            Learn More
                          </span>
                        </span>
                        <CardArt
                          _rev={card._rev}
                          cardArt={card.cardArt}
                          categoryId={categoryId}
                          externalId={externalId}
                          imgClasses="relative z-0 w-full h-full object-cover top-0"
                          name={card.name}
                          issuer={card.issuer.slug.current}
                          slug={card.slug}
                          orderId={orderId}
                        />
                      </div>
                    </Link>
                  )}
                </div>
              </div>
            )}
          </div>
          {cardUpsellName && cardUpsellInline ? (
            <div className="flex items-center / px-1 md:px-2 / text-left">
              {card &&
                (card._type === 'card' ? (
                  <LinkToRedirect
                    externalId={externalId}
                    linkParams={card.applyNowLinkParameters}
                    slug={card.slug}
                    orderId={orderId}
                  >
                    <div
                      className={`c-card-sticky__name / inline-block / text-fs12 xs:text-sm sm:text-base md:text-lg font-sans font-semibold text-left leading-tight text-primary-mid / hover:text-primary-bright`}
                      dangerouslySetInnerHTML={{ __html: supify(card.name) }}
                    />
                  </LinkToRedirect>
                ) : (
                  <Link href={card.moreInfoLink}>
                    <div
                      className={`c-card-sticky__name / inline-block / text-fs12 xs:text-sm sm:text-base md:text-lg font-sans font-semibold text-left leading-tight text-primary-mid / hover:text-primary-bright`}
                      dangerouslySetInnerHTML={{ __html: supify(card.name) }}
                    />
                  </Link>
                ))}
            </div>
          ) : (
            ''
          )}
          <div
            className={cn(
              'c-card-sticky__col / flex flex-col / pl-1 xs:pl-2 md:pl-3',
              {
                'w-2/3 xs:w-auto min-w-14rem': !cardUpsellInline,
                'c-card-sticky__col--inline': cardUpsellInline,
              },
            )}
          >
            {cardUpsellOfferCopy && (
              <p
                className={`c-card-sticky__offer / w-full / mb-0.5 / text-primary text-fs13 leading-tight font-bold text-center`}
              >
                {cardUpsellOfferCopy}
              </p>
            )}
            {card && (
              <div>
                {card._type === 'card' ? (
                  <LinkToRedirect
                    externalId={externalId}
                    linkParams={card.applyNowLinkParameters}
                    slug={card.slug}
                    orderId={orderId}
                  >
                    <div
                      className={cn(
                        'c-card-sticky__cta / flex flex-row items-center justify-center / py-1 / rounded-sm / border border-secondary font-semibold text-white text-sm text-center leading-tight / bg-secondary hover:bg-secondary-dark',
                        {
                          'mb-1': cardUpsellSecureSite,
                          'w-24 xxs:w-28 xs:w-32 sm:w-40 lg:w-full / px-2 md:px-6 / sm:text-base':
                            cardUpsellInline,
                          'md:w-auto / px-6 lg:px-7': !cardUpsellInline,
                        },
                      )}
                    >
                      Apply Now
                      <span
                        className={cn('w-3 h-3 sm:w-4 sm:h-4 / mt-0.5', {
                          'hidden xxs:inline-block ml-1': cardUpsellInline,
                          'ml-1.5': !cardUpsellInline,
                        })}
                      >
                        <ApplyNowIcon
                          width={16}
                          height={16}
                          aria-hidden={true}
                          style={nextImageHelper.intrinsic}
                        />
                      </span>
                    </div>
                  </LinkToRedirect>
                ) : (
                  <Link href={card.moreInfoLink}>
                    <div
                      className={cn(
                        'c-card-sticky__cta / flex flex-row items-center justify-center / py-1 / rounded-sm / border border-secondary font-semibold text-white text-sm text-center leading-tight / bg-secondary hover:bg-secondary-dark',
                        {
                          'mb-1': cardUpsellSecureSite,
                          'w-24 xxs:w-28 xs:w-32 sm:w-40 lg:w-full / px-2 md:px-6 / sm:text-base':
                            cardUpsellInline,
                          'md:w-auto / px-6 lg:px-7': !cardUpsellInline,
                        },
                      )}
                    >
                      Learn More
                    </div>
                  </Link>
                )}
              </div>
            )}

            {cardUpsellSecureSite && card && card._type === 'card' && (
              <p className="c-card-sticky__issuer / text-fs11 text-gray-600 text-center leading-snug">
                <SecureSite
                  issuerName={card.issuer.name}
                  issuerSecureSite={card.issuerSecureSite}
                />
              </p>
            )}

            {card && (
              <>
                {card.ratesAndFees && (
                  <p className="max-w-12rem text-center -mt-0.5 leading-5">
                    {card.ratesAndFees !== 'N/A' && card._type === 'card' && (
                      <a
                        className="c-card-sticky__rates-fees / text-primary-bright text-xs"
                        onClick={() => {
                          clickRatesAndFees({
                            applyNowLink: card.applyNowLink,
                            component: 'Card Nav V2',
                            name: card.name,
                            url: window.location.href,
                          })
                        }}
                        href={card.ratesAndFees}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Rates &amp; Fees
                      </a>
                    )}
                    {card.issuer.slug.current === 'american-express' && (
                      <p className="text-fs10 text-gray-600 / text-center / mb-3 md:mb-1 / w-full leading-4">
                        Terms Apply
                      </p>
                    )}
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <style jsx>{`
        .c-card-sticky {
          min-height: 4rem;
          opacity: 0;
        }
        .c-card-sticky__cta,
        .c-card-sticky__issuer,
        .c-card-sticky__offer {
          max-width: 12rem;
        }
        .c-card-sticky__col--inline .c-card-sticky__cta,
        .c-card-sticky__col--inline .c-card-sticky__offer {
          max-width: none;
        }

        /* ===== THEME ===== */
        .c-card-sticky--top {
          top: -6rem;
        }
        .c-card-sticky--bottom {
          bottom: -6rem;
        }
        .c-card-sticky.is-shown {
          opacity: 1;
        }
        .c-card-sticky--top.is-shown {
          transform: translateY(6rem);
        }
        .c-card-sticky--bottom.is-shown {
          transform: translateY(-6rem);
        }
        @media (min-width: 480px) {
          .c-card-sticky__col.min-w-14rem {
            min-width: 14rem;
          }
        }
        @media (min-width: 1024px) {
          .c-card-sticky {
            min-height: 5rem;
          }
        }

        /* ===== DARK ===== */
        .t-dark {
          background-color: #052f5e;
        }
        .t-dark .c-card-sticky__name {
          font-size: 16px;
          margin-bottom: 0.25rem;
          color: #fff;
          font-family: 'Inria Serif', serif;
        }
        .t-dark .c-card-sticky__name:hover {
          color: #21a3fc;
        }
        .t-dark .c-card-sticky__rates-fees {
          color: #d0eafd;
          opacity: 0.7;
        }
        .t-dark .c-card-sticky__rates-fees:hover {
          color: #d0eafd;
          opacity: 1;
        }
        @media (min-width: 640px) {
          .t-dark .c-card-sticky__name {
            margin: 0 0.75rem;
            font-size: 20px;
          }
        }
        @media (min-width: 768px) {
          .t-dark .c-card-sticky__name {
            font-size: 24px;
          }
        }
        @media (min-width: 1024px) {
          .t-dark .c-card-sticky__name {
            font-size: 27px;
          }
        }

        /* ===== RIBBON ===== */
        .c-ribbon {
          height: 14px;
          font-size: 11px;
          line-height: 1.2;
        }
        .c-ribbon--slimmer:before {
          left: -7px;
          border-width: 7px 0 7px 7px;
        }
        .c-ribbon--slimmer:after {
          right: -7px;
          border-width: 7px 7px 7px 0;
        }
      `}</style>
    </div>
  )
}
