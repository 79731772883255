import Cookies from 'js-cookie'
import { useContext, useEffect, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'

import { clickRatesAndFees, makeImpression } from 'clients/segment'
import { getExperiment, nextImageHelper } from 'utils/'
import cn from 'utils/classnames'
import { getSubstituteOrderId } from 'utils/orderId/getSubstituteOrderId'
import useNetworkState from 'utils/use-network-status'
import type { CompareCredit } from '../../../types/compare-credit'
import { LinkToRedirect, UserContext } from '../'
import { SecureSite } from '../secure-site'
import { ApplyNowIcon } from '../link-to-redirect/ApplyNowIcon'

export const ButtonRenderer = ({
  cardOrderIds,
  referencedCards,
  externalId: tag,
  categoryId,
}: {
  cardOrderIds: Record<string, string>
  referencedCards: null | Record<string, CompareCredit.Entity>
  externalId: string
  categoryId: string
}) => {
  const ButtonRendererInner: React.FC<{
    value: {
      buttonText: string | undefined
      buttonUrl: string
      emphasis: string
      ctaIcon?: string
      btnColor?: string
      isCardApplyNowLinkButton: boolean
      categoryTag: CompareCredit.CategoryTag
      buttonUrlCard: {
        slug: string
      }
    }
  }> = ({
    value: {
      buttonText,
      buttonUrl,
      buttonUrlCard,
      btnColor,
      isCardApplyNowLinkButton,
      emphasis,
      ctaIcon,
    },
  }) => {
    const externalId = tag ? tag : '80'

    const possibleOrderId = cardOrderIds[buttonUrlCard?.slug]
    const orderId = useMemo(
      () => possibleOrderId || getSubstituteOrderId(),
      [possibleOrderId],
    )

    // 1. Button URL
    const buttonUrlCardApplyNowLink = buttonUrlCard?.slug
    const url =
      isCardApplyNowLinkButton && buttonUrlCardApplyNowLink
        ? buttonUrlCardApplyNowLink
        : buttonUrl

    // 2. Icon image
    const ctaIconImg = useMemo(() => {
      if (ctaIcon === 'chevronRight') {
        return '/static/icons/icon-chevron-r-white.svg'
      }
      if (ctaIcon === 'lock') {
        return '/static/icons/icon-lock-round-w.svg'
      }
      return ''
    }, [])

    // 3. Link Redirectt
    const query = useMemo(
      () => (isCardApplyNowLinkButton ? window.location.search : ''),
      [isCardApplyNowLinkButton],
    )

    return (
      <div
        className={cn('c-btn-cms__container  / leading-snug', {
          [`c-btn-cms--${buttonUrlCard.slug}`]: buttonUrlCard.slug,
        })}
      >
        {emphasis && (
          <div className="c-btn-cms--emphasis / text-center">
            <p className="c-text-pointer / relative / inline-block / mb-2 / font-serif text-primary-mid text-center font-bold leading-none">
              {emphasis}
            </p>
          </div>
        )}

        {isCardApplyNowLinkButton && buttonUrlCard?.slug && referencedCards ? (
          <ButtonCardLink
            buttonText={buttonText}
            ctaIconImg={ctaIconImg}
            orderId={orderId}
            card={referencedCards}
            externalId={externalId}
            categoryId={categoryId}
            query={query}
            slug={buttonUrlCard?.slug}
          />
        ) : url ? (
          <a
            className={`c-btn-cms / c-btn / w-full max-w-sm / p-2 text-center ${
              btnColor == 'btnBlue' ? 'c-btn--secondary' : 'c-btn--primary'
            }
              `}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {buttonText}
            {ctaIcon && ctaIconImg !== '' && (
              <span className="w-4 h-4 / ml-1">
                <ApplyNowIcon
                  width={24}
                  height={24}
                  style={nextImageHelper.intrinsic}
                  aria-hidden="true"
                />
              </span>
            )}
          </a>
        ) : (
          <></>
        )}
        <style jsx>{`
          /* TEXT-POINTER
            ======================================================= */
          .c-text-pointer {
            font-size: 28px;
          }
          .c-text-pointer::before {
            display: inline-block;
            content: '';
            position: absolute;
            top: 1rem;
            width: 33px;
            height: 55px;
            background-size: cover;
            background-position: center center;
            left: -34px;
            background-image: url(/static/images/arrow-sketchy-gold-1.svg);
          }
          @media (min-width: 375px) {
            .c-text-pointer {
              font-size: 32px;
            }
          }
        `}</style>
      </div>
    )
  }

  return ButtonRendererInner
}

const ButtonCardLink: React.FC<{
  buttonText?: string | undefined
  orderId: string
  card: any
  externalId: string
  categoryId?: string
  query?: string
  ctaIconImg?: string
  btnColor?: string
  slug: string
}> = ({
  buttonText,
  card,
  orderId,
  externalId,
  categoryId,
  query,
  ctaIconImg,
  btnColor,
  slug,
}) => {
  const { clientLocation, entryQuery, gaSessionInfo } = useContext(UserContext)

  const networkInfo = useNetworkState()

  const { ref, inView } = useInView({
    threshold: 0.9,
    triggerOnce: true,
  })

  useEffect(() => {
    const experiment = getExperiment()
    const ga_client_id = Cookies.get('_ga') || null
    const entity = card[slug]

    if (inView && orderId)
      makeImpression({
        ga_client_id,
        ga_session_id: gaSessionInfo.ga_session_id,
        ga_session_number: gaSessionInfo.ga_session_number,
        arrangementId: null,
        entryQuery,
        experiment,
        latency: null,
        location: clientLocation,
        params: null,
        userModel: null,
        category: 'credit-card',
        order_id: orderId,
        brand: entity.issuer.slug.current,
        id: entity.slug,
        name: entity.name,
        network: networkInfo,
        product_id: entity.slug,
        sku: entity.slug,
        categoryId: categoryId || null,
        _rev: entity._rev,
      })
  }, [inView])

  return (
    <div ref={ref}>
      <LinkToRedirect
        externalId={externalId}
        linkParams={card[slug].applyNowLinkParameters}
        orderId={orderId}
        query={query}
        slug={card[slug].slug}
      >
        <div
          className={`c-btn-cms / c-btn / w-full / mb-1.5 / p-2 / text-center ${
            btnColor == 'btnBlue' ? 'c-btn--secondary' : 'c-btn--primary'
          }
          `}
        >
          {buttonText}
          {ctaIconImg && (
            <span className="w-4 h-4 / ml-1.5">
              <ApplyNowIcon
                width={24}
                height={24}
                style={nextImageHelper.intrinsic}
                aria-hidden="true"
              />
            </span>
          )}
        </div>
      </LinkToRedirect>
      <p className="mb-1 text-gray-600 text-fs13 text-center">
        <SecureSite
          issuerName={card[slug].issuer.name}
          issuerSecureSite={card[slug].issuerSecureSite}
        />
      </p>
      <div className="leading-none">
        {card[slug].ratesAndFees && (
          <div className="w-full / text-center text-shadow">
            {card[slug].ratesAndFees && card[slug].ratesAndFees !== 'N/A' && (
              <p className="mb-0.5">
                <a
                  className="inline-block text-primary-bright text-fs12 leading-snug / hover:text-primary-mid"
                  href={card[slug].ratesAndFees}
                  onClick={() => {
                    clickRatesAndFees({
                      applyNowLink: card[slug].applyNowLink,
                      component: 'Button Renderer',
                      name: card[slug].name,
                      url: window.location.href,
                    })
                  }}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Rates &amp; Fees
                </a>
              </p>
            )}
            {card[slug].issuer.slug.current === 'american-express' && (
              <p className="text-fs12 text-slate-500 / text-center / w-full leading-snug">
                Terms Apply
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
