import { PortableTextBlockComponent, PortableText } from '@portabletext/react'
import Image from 'next/image'
import Link from 'next/link'
import { useContext, useMemo, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'

import { clickRatesAndFees } from 'clients/segment'
import {
  CallToApply,
  CardArt,
  LinkToRedirect,
  StarRatingEditor,
  UserContext,
} from 'components/'
import { CreditRatingRequirement } from 'components/credit-rating-requirement'
import { ProductDetails } from 'components/entity-panel/sections/components/product-details'
import ErrorBoundary from 'components/error-boundary'
import { SecureSite } from 'components/secure-site'
import { firstIsVowel, nextImageHelper, supify } from 'utils/'
import { getFeaturesToRender } from 'utils/getFeaturesToRender'
import { getSubstituteOrderId } from 'utils/orderId/getSubstituteOrderId'
import type { CompareCredit } from '../../../../types/compare-credit'
import { AttributeRendererWithSlugV2 } from '../attributeRendererV2'
import { CardSingleColSkeleton } from './loading-skeleton-single-card-column'
import { ApplyNowIcon } from 'components/link-to-redirect/ApplyNowIcon'

export const TipSingleCardColumnTile = ({
  cardOrderIds,
  referencedCards,
}: {
  cardOrderIds: Record<string, string>
  referencedCards: null | Record<string, CompareCredit.Entity>
}) => {
  const TipSingleCardColumnTileRender: React.FC<{
    value: {
      card: {
        _id: string
        slug: string
      }
      content: {
        children: any
      }[]
      featuredRibbon: string
      bestBadge: boolean
      hideCallout: boolean
      highlightNumber: boolean
      tag: CompareCredit.CategoryTag
      categoryTag: CompareCredit.CategoryTag
      creditNeeded: string
    }
  }> = ({
    value: {
      creditNeeded,
      featuredRibbon,
      bestBadge,
      hideCallout,
      highlightNumber,
      tag,
      categoryTag,
      card,
      content,
    },
  }) => {
    const { clientLocation } = useContext(UserContext)
    const { slug: cardSlug } = card
    const query = useMemo(() => window.location.search, [cardSlug])

    const productTip = referencedCards?.[card.slug]
    const possibleOrderId = cardOrderIds[card.slug]
    const orderId = useMemo(
      () => possibleOrderId || getSubstituteOrderId(),
      [possibleOrderId],
    )

    const externalId = tag ? tag.id : '80'
    const categoryId = categoryTag ? categoryTag.id : '80'

    return (
      <>
        {productTip &&
        (productTip._type === 'card' || productTip._type === 'nonPaidCard') ? (
          <div
            className={`c-card-single__container c-card-single--${
              productTip.slug
            } / relative z-20 / mb-10 w-full / sm:mb-4 ${
              bestBadge ? 'mt-8' : 'mt-4'
            }`}
          >
            <CardTile
              categoryId={categoryId}
              externalId={externalId}
              creditNeeded={creditNeeded}
              featuredRibbon={featuredRibbon}
              bestBadge={bestBadge}
              hideCallout={hideCallout}
              highlightNumber={highlightNumber}
              card={productTip}
              clientLocation={clientLocation}
              orderId={orderId}
              query={query}
              content={content}
              referencedCards={referencedCards}
            />
          </div>
        ) : (
          <CardSingleColSkeleton n={1} highlightNumber={highlightNumber} />
        )}
      </>
    )
  }
  return TipSingleCardColumnTileRender
}
const Normal: PortableTextBlockComponent = ({ children }) => (
  <p className="mb-0.5">{children}</p>
)
const Heading3: PortableTextBlockComponent = ({ children }) => (
  <h3 className="mb-1 / text-primary text-lg font-semibold">{children}</h3>
)

const ProductHighlights: React.FC<{
  attributeRenderer: ReturnType<typeof AttributeRendererWithSlugV2>
  content: any
}> = ({ attributeRenderer, content }) => {
  return (
    <ErrorBoundary>
      <div className="c-card-single__content / mb-3 / leading-normal text-sm text-primary">
        <PortableText
          value={content}
          components={{
            block: {
              normal: Normal,
              h3: Heading3,
            },
            list: ({ children }) => (
              <ul className="c-list-check / mt-1 mb-2 pl-8 / leading-snug text-sm text-primary">
                {children}
              </ul>
            ),
            types: {
              advertorialAttribute: attributeRenderer,
            },
          }}
        />
      </div>
      <style jsx>{`
        .c-card-single__content {
        }
      `}</style>
    </ErrorBoundary>
  )
}

const CardTile: React.FC<{
  featuredRibbon: string
  bestBadge: boolean
  hideCallout: boolean
  highlightNumber: boolean
  clientLocation: { country?: string; state?: string; status: string }
  orderId: string
  card: CompareCredit.FormattedCard | CompareCredit.NonPaidCard
  categoryId: string
  externalId: string
  query?: string
  content: any
  creditNeeded: string
  referencedCards: null | Record<string, CompareCredit.Entity>
}> = ({
  featuredRibbon,
  bestBadge,
  hideCallout,
  highlightNumber,
  card,
  orderId,
  clientLocation,
  categoryId,
  externalId,
  query,
  content,
  creditNeeded,
  referencedCards,
}) => {
  const featuresToRender = getFeaturesToRender(card, categoryId)

  const [isCollapsed, setIsCollapsed] = useState(true)
  const handleSetCollapsed = () => setIsCollapsed(!isCollapsed)

  return (
    <div
      className={`c-card-single / relative / md:flex md:flex-row md:flex-wrap / mx-auto max-w-lg md:max-w-none lg:-ml-14 lg:-mr-14 / px-6  pb-10 / bg-white shadow-lg rounded-lg
        ${featuredRibbon ? 'pt-2' : 'pt-5'}
      `}
    >
      {featuredRibbon && (
        <div className="c-card-single__ribbon / w-full / mb-1.5 / text-center md:text-left">
          <p className="c-ribbon c-ribbon--slimmer md:c-ribbon--rt / relative inline-block / pt-0.5 px-6 md:px-8 md:-ml-6 / font-bold text-primary leading-snug uppercase text-fs13 tracking-wider / bg-tetriary">
            {featuredRibbon}
          </p>
        </div>
      )}
      {bestBadge && (
        <div className="c-badge-best / absolute top-0 right-0 z-10 / -mr-3 -mt-6 md:-mr-6 md:-mt-8">
          <div className="w-16 h-16 sm:w-20 sm:h-20 / shadow-lg rounded-full / lg:hidden">
            <Image
              alt="best of the best"
              src="/static/icons/badge-best-of-best-v1-sm.svg"
              height={80}
              width={80}
              style={nextImageHelper.responsive}
              sizes="100vw"
            />
          </div>
          <div className="w-24 h-24 / shadow-lg rounded-full / hidden lg:block">
            <Image
              alt="best of the best"
              src="/static/icons/badge-best-of-best-v1.svg"
              height={94}
              width={94}
              style={nextImageHelper.responsive}
              sizes="100vw"
            />
          </div>
        </div>
      )}

      <div className="c-card-single__col-1 / md:w-1/3 / max-w-xs / mx-auto / md:pr-6">
        <div className="c-card-art / text-center / flex items-center justify-center / mx-auto mb-3">
          <div className="relative / w-full / rounded-lg shadow / overflow-hidden">
            {card._type === 'card' ? (
              <LinkToRedirect
                externalId={externalId}
                linkParams={card.applyNowLinkParameters}
                orderId={orderId}
                query={query}
                slug={card.slug}
              >
                <div className="relative flex rounded-sm overflow-hidden / fs_card-tile_card-art">
                  <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white">
                    <span className="w-full">
                      <div className="inline-block w-8 h-8 mb-2">
                        <ApplyNowIcon
                          height={32}
                          width={32}
                          style={nextImageHelper.responsive}
                          sizes="100vw"
                        />
                      </div>
                    </span>
                    <span className="w-full font-bold">Apply Now</span>
                  </span>

                  <CardArt
                    _rev={card._rev}
                    cardArt={card.cardArt}
                    imgClasses="relative / z-0 / w-full h-full object-cover top-0"
                    customCodeSnippets={card.customCodeSnippets}
                    issuer={card.issuer.slug.current}
                    name={card.name}
                    orderId={orderId}
                    slug={card.slug}
                    type={card._type}
                    categoryId={categoryId}
                    externalId={externalId}
                  />
                </div>
              </LinkToRedirect>
            ) : (
              <Link href={card.moreInfoLink}>
                <div className="relative flex rounded-sm overflow-hidden / fs_card-tile_card-art">
                  <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white">
                    <span className="w-full font-bold">Learn More</span>
                  </span>

                  <CardArt
                    _rev={card._rev}
                    cardArt={card.cardArt}
                    imgClasses="relative / z-0 / w-full h-full object-cover top-0"
                    customCodeSnippets={card.customCodeSnippets}
                    issuer={card.issuer.slug.current}
                    name={card.name}
                    orderId={orderId}
                    slug={card.slug}
                    categoryId={categoryId}
                    externalId={externalId}
                  />
                </div>
              </Link>
            )}
          </div>
        </div>
        {card._type === 'card' ? (
          <LinkToRedirect
            externalId={externalId}
            linkParams={card.applyNowLinkParameters}
            orderId={orderId}
            query={undefined}
            slug={card.slug}
          >
            <div className="c-btn c-btn--primary max-w-none text-lg mb-2">
              Apply Now
              <span className="ml-2">
                <div className="w-4 h-4 / ">
                  <ApplyNowIcon
                    width={16}
                    height={16}
                    style={nextImageHelper.responsive}
                    sizes="100vw"
                  />
                </div>
              </span>
            </div>
          </LinkToRedirect>
        ) : (
          <Link href={card.moreInfoLink}>
            <div className="c-btn c-btn--primary max-w-none text-lg mb-2">
              Learn More
            </div>
          </Link>
        )}

        {card._type === 'card' && (
          <p className="text-fs13 text-gray-600 / text-center leading-tight / mb-1">
            <SecureSite
              issuerName={card.issuer.name}
              issuerSecureSite={card.issuerSecureSite}
            />
          </p>
        )}

        <div className="c-card-single__call / lg:hidden">
          {card._type === 'card' &&
            card.phoneNumber &&
            isMobileOnly &&
            clientLocation?.country === 'US' && (
              <div className="w-full -mt-4">
                <p className="mb-3">
                  <p className="mb-3">
                    <CallToApply classes="max-w-xs" entity={card} />
                  </p>
                </p>

                <p className="text-xs text-gray-600 / text-center / -mt-1 mb-4 / md:self-start w-full max-w-xs leading-4">
                  with {firstIsVowel(card.issuer.name) ? 'an' : 'a'}{' '}
                  {card.issuer.name} representative
                </p>
              </div>
            )}
        </div>

        {card._type === 'card' &&
          card.ratesAndFees &&
          card.ratesAndFees !== 'N/A' && (
            <p className="c-card-single__rates / text-primary-bright text-xs w-full text-center pt-1 mb-2">
              <a
                href={card.ratesAndFees}
                onClick={() => {
                  clickRatesAndFees({
                    applyNowLink: card.applyNowLink,
                    component: 'Tip Single Card Column tile',
                    name: card.name,
                    url: window.location.href,
                  })
                }}
                rel="noopener noreferrer"
                target="_blank"
              >
                Rates &amp; Fees
              </a>
            </p>
          )}
      </div>
      <div className="c-card-single__col-2 / md:w-2/3">
        <h3>
          {card._type === 'card' ? (
            <LinkToRedirect
              externalId={externalId}
              linkParams={card.applyNowLinkParameters}
              orderId={orderId}
              query={undefined}
              slug={card.slug}
            >
              <div
                className="inline-block / w-full mb-1 / text-primary-mid leading-snug font-bold text-lg md:text-xl text-center md:text-left / hover:text-primary-bright cursor-pointer / transition-all / focus:outline-none"
                dangerouslySetInnerHTML={{
                  __html: supify(card.name),
                }}
              />
            </LinkToRedirect>
          ) : (
            <Link href={card.moreInfoLink}>
              <div
                className="inline-block / w-full mb-1 / text-primary-mid leading-snug font-bold text-lg md:text-xl text-center md:text-left / hover:text-primary-bright cursor-pointer / transition-all / focus:outline-none"
                dangerouslySetInnerHTML={{
                  __html: supify(card.name),
                }}
              />
            </Link>
          )}
        </h3>

        <div
          className={`c-card-single__rating / mb-1 lg:mb-2.5 / flex items-start justify-center md:justify-start w-full`}
        >
          <StarRatingEditor
            productSlug={card.slug}
            stars={card.expertReviewRating}
          />
        </div>
        {!hideCallout && (
          <div className="c-card-single__summary / mb-3">
            <ProductHighlights
              attributeRenderer={AttributeRendererWithSlugV2({
                referencedCards,
              })}
              content={content}
            />
          </div>
        )}
        {creditNeeded && (
          <CreditRatingRequirement
            creditNeeded={card.creditNeeded.toLowerCase()}
          />
        )}
      </div>

      <div className="c-card-single__attributes / w-full / mb-4">
        <ul className="c-card-single__attributes-list / md:flex md:flex-row md:items-stretch / leading-5 text-sm md:text-fs13 lg:text-sm">
          {featuresToRender.map((feature, i) => {
            const listFirst = i === 2 ? true : false

            return listFirst && highlightNumber ? null : (
              <li
                className={`c-card-single__attributes-item / flex items-stretch / w-full / mb-1.5 md:mb-0 / md:px-1 ${
                  highlightNumber ? 'md:w-1/3' : 'md:w-1/4'
                }`}
                key={i}
              >
                <span className="block w-full px-4 pt-3 pb-1 bg-gray-100 rounded">
                  <span
                    className="block / mb-1 / text-primary font-bold"
                    dangerouslySetInnerHTML={{ __html: feature.Title }}
                  />
                  {feature._type === 'custom' ? (
                    <span>{feature.value}</span>
                  ) : (
                    <span>
                      <PortableText
                        value={feature.Description}
                        components={{
                          block: {
                            span: ({ children }) => <span>{children}</span>,
                          },
                          types: {
                            cardAttribute: ({
                              value: { attribute },
                            }: {
                              value: { attribute: keyof typeof card }
                            }) => {
                              return (
                                /**
                                 * @todo fix the legit type error this type
                                 * assertion is masking
                                 */
                                <span>
                                  {card[attribute] as React.ReactNode}
                                </span>
                              )
                            },
                          },
                        }}
                      />
                    </span>
                  )}
                </span>
              </li>
            )
          })}
          {card.feeAnnual && (
            <li
              className={`c-card-single__attributes-item / flex items-stretch / w-full / md:px-1 ${
                highlightNumber ? 'md:w-1/3' : 'md:w-1/4'
              }`}
            >
              <span className="block w-full px-4 py-3 bg-gray-100 rounded">
                <span className="block / text-primary font-bold">
                  Annual Fee
                </span>
                <span>{card.feeAnnual}</span>
              </span>
            </li>
          )}
        </ul>
      </div>

      <div
        className={`c-card-single__details c-slidedown / w-full ${
          isCollapsed ? 'c-slidedown--hidden' : ''
        }`}
      >
        <p className="text-sm lg:text-base font-bold tracking-wider uppercase text-primary">
          Product Details
        </p>
        <div className="pb-3">
          <ProductDetails
            orderId={orderId}
            externalId={externalId}
            entity={card}
          />
        </div>
      </div>

      <div
        className="c-card-single__expand-btn / absolute left-0 bottom-0 / block w-full / border-t border-gray-200 / focus:outline-none"
        onClick={() => handleSetCollapsed()}
        onKeyDown={() => handleSetCollapsed()}
        role="button"
        tabIndex={0}
      >
        <button className="focus:outline-none py-3 md:py-2 w-full / text-center text-gray-500 text-sm font-bold / rounded-lg rounded-t-none / hover:text-primary-bright hover:bg-gray-100 / transition-all">
          {isCollapsed ? 'Expand Details' : 'Show Less'}
          <span
            className={`c-icon-arrow / w-3 h-3 opacity-50 ml-2 ${
              isCollapsed ? '' : 'rotate-180'
            }`}
          />
        </button>
      </div>

      <style jsx>{`
        .c-list-check li {
          margin-bottom: 0.5rem;
        }
        .c-ribbon--slim {
          height: 24px;
        }
        .c-ribbon--slim:before {
          left: -12px;
          border-width: 12px 0 12px 12px;
        }
        .c-ribbon--slim:after {
          right: -10px;
          border-width: 12px 12px 12px 0;
        }
      `}</style>
    </div>
  )
}
