import { useMemo } from 'react'

import { formatPinnedProducts } from 'ssg/utils/format-pinned-products'
import { autoScroll, supify } from 'utils/'
import mapEntityPositions from 'utils/mapEntityPositions'
import { pinEntities } from 'utils/pin-entities'
import { getSortedAndFilteredBySortPosition } from 'utils/sort/sortPosition'
import { useIsPinningActive } from 'utils/useIsPinningActive'
import type { CompareCredit } from '../../../../types/compare-credit'
import { SkeletonPreview } from './skeleton'

export const PreviewCards: React.FC<{
  value: {
    cardTips: CompareCredit.CardTip[]
    tag: CompareCredit.CategoryTag
    title?: string
    pinnedCardTips?: CompareCredit.PinnedProduct[]
    sort: string
    referencedCards: null | Record<string, CompareCredit.Entity>
  }
}> = ({
  value: { cardTips, title, pinnedCardTips, sort, referencedCards },
}) => {
  const numberOfCards = cardTips.length

  const isPinningActive = useIsPinningActive(sort)

  const cardsData = useMemo(() => {
    const augmentedCardTips = cardTips.flatMap((cardTip) => {
      const referencedCard = referencedCards?.[cardTip.card.slug]

      const cardName = referencedCard?.name

      return cardName
        ? [
            {
              ...cardTip,
              cardSlug: cardTip.card.slug,
              sortPosition:
                'sortPosition' in referencedCard
                  ? referencedCard.sortPosition
                  : undefined,
              cardName,
            },
          ]
        : []
    })

    const cardTipsSorted = getSortedAndFilteredBySortPosition(augmentedCardTips)

    const cardTipsWithPinsAppliedIfActive = isPinningActive
      ? pinEntities(
          cardTipsSorted,
          formatPinnedProducts(
            pinnedCardTips?.map((pinnedCard) => {
              return {
                card: {
                  _id: pinnedCard.card._id,
                  slug: pinnedCard.card.slug,
                },
                frequencyPercent: pinnedCard.frequencyPercent,
              }
            }) ?? [],
          ),
          mapEntityPositions(cardTips.map(({ card: { slug } }) => slug)),
        )
      : cardTipsSorted

    return cardTipsWithPinsAppliedIfActive.map(
      ({ labelCard: cardLabel, cardSlug, cardName }) => ({
        cardLabel,
        cardSlug,
        cardName,
      }),
    )
  }, [cardTips, referencedCards, sort, isPinningActive, pinnedCardTips])

  const handleClickCardSection = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const dataId = event.currentTarget.getAttribute('data-id')
    if (dataId) {
      const productSection = document.getElementById(`card-${dataId}`)
      if (productSection) {
        autoScroll(productSection, -88)
      }
    }
  }

  return (
    <div className="c-preview-cards / mb-6 mt-4 / leading-snug text-base">
      <p className="c-preview-cards__title / mb-1 / text-primary-mid font-bold leading-tight">
        {title}
      </p>
      {cardsData.length > 0 ? (
        <ul className={`c-preview-cards__list / text-sm`}>
          {cardsData.map(({ cardLabel, cardSlug, cardName }, i) => (
            <li
              key={i}
              className="c-preview-cards__item / flex flex-col sm:block / py-2 / border-b border-gray-300"
            >
              <span className="c-preview-cards__label / shrink-0 / font-semibold">
                {cardLabel}
              </span>
              {` `}
              <span className="c-preview-cards__card-name">
                <button
                  onClick={handleClickCardSection}
                  data-id={cardSlug}
                  className="text-left text-primary-bright / hover:text-primary-mid font-semibold"
                  dangerouslySetInnerHTML={{
                    __html: supify(cardName),
                  }}
                />
              </span>
            </li>
          ))}
        </ul>
      ) : (
        <SkeletonPreview n={numberOfCards} />
      )}
    </div>
  )
}
