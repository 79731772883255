import { useFetchCardsSlug } from 'utils/'
import { buildSecureHref } from 'utils/buildSecureHref'
import { getSubstituteOrderId } from 'utils/orderId/getSubstituteOrderId'
import type { CompareCredit } from '../../../types'

/**
 * @deprecated use ApplyNowLinkV2 instead.
 */
export const ApplyNowLink = (
  externalId?: string,
  possibleOrderId?: string,
  orderIdsBySlug?: { [key: string]: string },
) => {
  const ApplyNowLinkRender: React.FC<
    React.PropsWithChildren<{
      value: { slug: string }
    }>
  > = ({ children, value }) => {
    const cardsSlug = value.slug ? [value.slug] : []
    const {
      cards: [productTip],
    } = useFetchCardsSlug(cardsSlug)

    const externalTagId = externalId ? externalId : '00'

    const slug = productTip ? productTip.slug : ''

    let applyNowLinkParameters: CompareCredit.Card['applyNowLinkParameters'] =
      []
    if (
      productTip &&
      productTip._type !== 'product' &&
      productTip._type !== 'nonPaidCard'
    ) {
      applyNowLinkParameters = productTip?.applyNowLinkParameters
    }

    const orderId =
      possibleOrderId || orderIdsBySlug?.[slug] || getSubstituteOrderId()

    const href = buildSecureHref({
      orderId,
      slug,
      externalId: externalTagId,
      pathName: typeof window !== 'undefined' ? window?.location?.pathname : '',
      linkParams: applyNowLinkParameters,
      expGroup: null,
    })

    return (
      <>
        {slug ? (
          <a
            className="mt-8 mb-2 / text-primary-bright / hover:text-primary-mid"
            href={href}
            target="_blank"
            rel="noreferrer"
          >
            {children}
          </a>
        ) : (
          <span>{children}</span>
        )}
      </>
    )
  }
  return ApplyNowLinkRender
}
