import { useShouldShowAwardsBadgeForExp364 } from 'utils/experimentation/useShouldShowAwardsBadgeForExp364'
import { useShouldShowAwardsBadgeForExp368 } from 'utils/experimentation/useShouldShowAwardsBadgeForExp368'
import { CompareCredit } from '../../../../types/compare-credit'
import { CardPanel } from '../../entity-panel/card'
import { Modal } from '../../modal'
import { MobileViewContainer } from './mobile-view-container'
import { StickyAside } from './sticky-aside'

export const ProductTipCard: React.FC<{
  cardTileAsideRef: React.RefObject<HTMLDivElement>
  cardTileIsSticky: boolean
  asideCardTileMargin: string
  isSticky: boolean
  asideOverflow: boolean
  unstickCardTile: boolean
  featuredRibbon: boolean
  featuredRibbonText: string
  floatRight: boolean
  productTip: any
  categoryId: string
  externalId: string
  orderId: string
  query: string
  displayName: boolean
  recommendedCredit: boolean
  clientLocation: {
    city: string
    country: string
    state: string
    status: string
  }
  referencedCards: null | Record<string, CompareCredit.Entities>
  cardOrderIds: Record<string, string>
  singleCardTip: {
    card: {
      _id: string
      slug: string
    }
    content: {
      children: any
    }[]
  }
  setModalOpen: (value: React.SetStateAction<boolean>) => void
  modalOpen: boolean
  hideRatingCount: boolean
  hideMoreDetails: boolean
  hideTrustLabel: boolean
  hideLeadingBadge: boolean
  layoutSlim: boolean
}> = ({
  cardTileAsideRef,
  cardTileIsSticky,
  asideCardTileMargin,
  isSticky,
  asideOverflow,
  unstickCardTile,
  featuredRibbon,
  featuredRibbonText,
  floatRight,
  productTip,
  categoryId,
  externalId,
  orderId,
  query,
  displayName,
  recommendedCredit,
  clientLocation,
  referencedCards,
  cardOrderIds,
  singleCardTip,
  setModalOpen,
  modalOpen,
  hideRatingCount,
  hideMoreDetails,
  hideTrustLabel,
  hideLeadingBadge,
  layoutSlim,
}) => {
  const shouldShowAwardsBadgeForExp364 = useShouldShowAwardsBadgeForExp364()
  const shouldShowAwardsBadgeForExp368 = useShouldShowAwardsBadgeForExp368()

  const shouldShowAwardsBadge =
    shouldShowAwardsBadgeForExp364 || shouldShowAwardsBadgeForExp368

  return (
    <div
      className={`c-card-tile-article / lg:flex lg:w-full lg:h-full / flex items-stretch / h-full / sm:mb-4 / mt-12 lg:mt-0 sm:mt-10 ${
        floatRight ? 'sm:float-right sm:ml-8' : 'sm:float-left sm:mr-8'
      }
      `}
    >
      <div className="c-card-tile__modal-container / relative z-80">
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <CardPanel
            categoryId={categoryId}
            externalId={externalId}
            onClose={() => setModalOpen(false)}
            view="modal"
            featured={false}
            entity={productTip}
            propsOrderId={orderId}
          />
        </Modal>
      </div>

      {/* MOBILE VIEW START */}
      <MobileViewContainer
        layoutSlim={layoutSlim}
        featuredRibbon={featuredRibbon}
        featuredRibbonText={featuredRibbonText}
        productTip={productTip}
        categoryId={categoryId}
        externalId={externalId}
        orderId={orderId}
        query={query}
        displayName={displayName}
        hideRatingCount={hideRatingCount}
        recommendedCredit={recommendedCredit}
        clientLocation={clientLocation}
        referencedCards={referencedCards}
        cardOrderIds={cardOrderIds}
        singleCardTip={singleCardTip}
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        hideMoreDetails={hideMoreDetails}
        shouldShowAwardsBadge={shouldShowAwardsBadge}
      />
      <StickyAside
        cardTileAsideRef={cardTileAsideRef}
        cardTileIsSticky={cardTileIsSticky}
        asideCardTileMargin={asideCardTileMargin}
        isSticky={isSticky}
        asideOverflow={asideOverflow}
        unstickCardTile={unstickCardTile}
        featuredRibbon={featuredRibbon}
        featuredRibbonText={featuredRibbonText}
        productTip={productTip}
        categoryId={categoryId}
        externalId={externalId}
        orderId={orderId}
        query={query}
        displayName={displayName}
        recommendedCredit={recommendedCredit}
        clientLocation={clientLocation}
        referencedCards={referencedCards}
        cardOrderIds={cardOrderIds}
        singleCardTip={singleCardTip}
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        hideMoreDetails={hideMoreDetails}
        hideTrustLabel={hideTrustLabel}
        hideLeadingBadge={hideLeadingBadge}
        shouldShowAwardsBadge={shouldShowAwardsBadge}
      />
    </div>
  )
}
