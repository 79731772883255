import { useStatsigClient } from '@statsig/react-bindings'
import { usePathname } from 'next/navigation'
import { useMemo } from 'react'

export const useShouldShowCfuCategoryBanner = () => {
  const statsigClient = useStatsigClient()
  const pathname = usePathname()

  const isValidPath = Boolean(pathname === '/credit-cards/best/cash-back/')

  const shouldShowCfuCategoryBanner = useMemo(() => {
    return isValidPath
      ? statsigClient
          .getExperiment('exp_373_category_banner_cfu')
          .get('should-show-cfu-banner', false)
      : false
  }, [statsigClient, isValidPath])

  return shouldShowCfuCategoryBanner
}
