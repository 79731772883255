import { useStatsigClient } from '@statsig/react-bindings'
import { usePathname } from 'next/navigation'
import { useMemo } from 'react'

export const useShouldShowAwardsBadgeForExp368 = () => {
  const currentPathname = usePathname()
  const statsigClient = useStatsigClient()

  const urlSearchParams = new URLSearchParams(window.location.search)

  const utmSourceIsPmax =
    urlSearchParams.get('utm_source')?.toLowerCase() === 'adwords_pmax'

  const isDicbNearlyPage =
    currentPathname === '/credit-cards/tips/discover-it-cash-back-nearly/'

  const shouldShowAwardsBadge = useMemo(() => {
    const shouldUserBeExposedToExperiment = utmSourceIsPmax && isDicbNearlyPage

    return shouldUserBeExposedToExperiment
      ? statsigClient
          .getExperiment('exp_368_disco_single_card_awards_badge_nearly')
          .get('should_show_awards_badge', false)
      : false
  }, [utmSourceIsPmax, isDicbNearlyPage, statsigClient])

  return shouldShowAwardsBadge
}
