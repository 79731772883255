import { generateNewOrderId } from './generateNewOrderId'

/**
 * Prefix to indicate that an order ID is a substitute order ID that was
 * generated in the cc-www package.
 *
 * We have a different prefix ('ext') to indicate an order ID substitution that
 * occurred in our ETL pipelines when pulling events from our affiliate
 * marketing networks.
 */
const CC_WWW_SUBSTITUTE_ORDER_ID_PREFIX = 'sub'

/**
 * Creates a substitute order ID to be used in cases where we expect an order ID
 * to already exist in the cc-www package, but our code is using a pattern which
 * prevents us from being able to guarantee (at runtime and/or at the type
 * level) that an order ID exists.
 *
 * Substitute order IDs are prefixed so that we can identify them in our data,
 * to help us find and eliminate cases where substitutions are happening, since
 * they break our ability to trace connections between events.
 *
 * It should in principle be possible to replace all patterns that prevent us
 * from being able to guarantee that an order ID exists with code that can
 * guarantee (both at the type level and at runtime) that order IDs will exist
 * when we need them, and we should do this wherever feasible.
 */
export const getSubstituteOrderId = (): string =>
  `${CC_WWW_SUBSTITUTE_ORDER_ID_PREFIX}--${generateNewOrderId()}`
