import type { CompareCredit } from '../../types/compare-credit'
import {
  separateEntities,
  insertPinnedIntoOriginalIdx,
} from './sort/client/sortCardsByRpi'

export const pinEntities = <
  _Entity extends CompareCredit.Entity | CompareCredit.CardTip,
>(
  entities: _Entity[],
  pinnedProducts: CompareCredit.PinnedProductFormatted,
  pinnedPositions: Record<string, number>,
) => {
  const { nonPinned, pinned } = separateEntities(
    entities,
    pinnedProducts,
    pinnedPositions,
  )

  const allEntitiesSorted = insertPinnedIntoOriginalIdx(pinned, nonPinned)

  return allEntitiesSorted
}
