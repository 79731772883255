import { useStatsigClient } from '@statsig/react-bindings'
import { usePathname } from 'next/navigation'
import { useMemo } from 'react'
import { isDesktop } from 'react-device-detect'

/**
 * Hook for exp_369_disco_cash_back_headline_highlight experiment.
 * This hook determines whether to show the headline highlight based on the experiment
 * and the current pathname.
 * @returns {boolean} - Whether to show the highlight or not.
 */
export const useShouldShowHighlight = () => {
  const statsigClient = useStatsigClient()
  const pathname = usePathname()

  const isValidPath =
    pathname === '/credit-cards/tips/discover-it-cash-back-nearly-v2/'
  const shouldShowHighlight = useMemo(() => {
    const shouldBeInExperiment = isValidPath && isDesktop

    return shouldBeInExperiment
      ? statsigClient
          .getExperiment('exp_369_disco_cash_back_headline_highlight')
          .get('should-highlight', false)
      : false
  }, [statsigClient, isValidPath])

  return shouldShowHighlight
}
