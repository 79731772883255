import React from 'react'
import { CompareCredit } from '../../../types/compare-credit'
import BlockContent from './block-content'

const Grid = ({
  data = {},
  updatedDate,
  author,
  reviewer,
  location,
  tag,
  categoryTag,
  cardOrderIds,
  referencedCards,
  exitModalRef,
  exitModalMultiCardTipPosition,
  sort,
}: {
  data: any
  updatedDate?: Date
  author?: CompareCredit.TipV2['author']
  reviewer?: CompareCredit.TipV2['author']
  location?: string
  tag?: CompareCredit.CategoryTag
  categoryTag?: CompareCredit.CategoryTag
  cardOrderIds: Record<string, string>
  referencedCards: null | Record<string, CompareCredit.Entities>
  exitModalRef: any
  exitModalMultiCardTipPosition?: number
  sort?: string
}) => {
  const { size, columns } = data

  const getGridSize = (
    breakpoint: any,
    size: any,
    justify = false,
    align = false,
    start = false,
  ) => {
    const hasBreakpoint = breakpoint && breakpoint.trim()
    const colSpan = hasBreakpoint
      ? `${breakpoint}:col-span-${size}`
      : `col-span-${size}`

    const colStart = hasBreakpoint
      ? `${breakpoint}:col-start-${start}`
      : `col-start-${start}`

    const colJustify = hasBreakpoint ? `${breakpoint}:${justify}` : justify
    const colAlign = hasBreakpoint ? `${breakpoint}:${align}` : align

    return `${colSpan} ${start && colStart} ${justify && colJustify} ${
      align && colAlign
    }`
  }

  return (
    <section className={`section`}>
      <div className="section--content">
        <div
          className={`grid grid-cols-${size} gap-x-4 gap-y-4 sm:gap-x-8 lg:gap-x-12 lg:gap-y-6`}
        >
          {columns.map((col: any, key: any) => {
            const { sizes, blocks } = col

            const classNames = `${sizes
              .map((size: any) => {
                return getGridSize(
                  size.breakpoint,
                  size.width,
                  size.justify,
                  size.align,
                  size.start,
                )
              })
              .join(' ')}
                `

            return (
              <div key={key} className={classNames}>
                {blocks.map((block: any, key: any) => {
                  const type = block._type
                  const display = block.display
                  const breakpoint = block.breakpoint

                  switch (type) {
                    case 'freeform':
                      return (
                        <BlockContent
                          key={key}
                          blocks={block.content}
                          updatedDate={updatedDate}
                          author={author}
                          reviewer={reviewer}
                          location={location}
                          tag={tag}
                          categoryTag={categoryTag}
                          cardOrderIds={cardOrderIds}
                          referencedCards={referencedCards}
                          displayElement={{ display, breakpoint }}
                          exitModalRef={exitModalRef}
                          exitModalMultiCardTipPosition={
                            exitModalMultiCardTipPosition
                          }
                          sort={sort}
                        />
                      )
                    default:
                      return null
                  }
                })}
              </div>
            )
          })}
        </div>
      </div>

      <style jsx>{`
        @media (min-width: 1024px) {
          .section--content--sticky-card > .grid > .col-span-12:nth-child(1) {
            grid-column-start: 3;
          }
          .section--content--sticky-card > .grid > .col-span-12:nth-child(2) {
            display: none;
          }
        }
      `}</style>
    </section>
  )
}

export default Grid
