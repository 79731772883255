import React from 'react'

/*
    Use this modal when you have a modal that should not be closed
    CC-2121-Blocks Meta Browser users from continuing to issuer sites
*/
export function PersistentModal(props: {
  open: boolean
  children: React.ReactElement
}) {
  return props.open ? (
    <div className="c-modal / modal modal-active / fixed w-full h-full top-0 left-0 / flex items-center justify-center z-70">
      <div className="modal-overlay fixed w-full h-full bg-gray-900 opacity-50 / top-0 left-0" />

      <div className="c-modal__content container-fluid / absolute top-0 left-0 right-0 / w-full mb-6 mt-4 / z-50">
        <div className="modal-container max-w-5xl mx-auto rounded z-50">
          {props.children}
        </div>
      </div>
    </div>
  ) : null
}
