export type SortableBySortPosition = { sortPosition: number }

export const getHasSortPosition = <_PossiblySortable>(
  possiblySortable: _PossiblySortable,
): possiblySortable is _PossiblySortable & SortableBySortPosition =>
  !!possiblySortable &&
  typeof possiblySortable === 'object' &&
  'sortPosition' in possiblySortable &&
  typeof possiblySortable.sortPosition === 'number'

export const sortPositionComparator = <
  _SortableA extends SortableBySortPosition,
  _SortableB extends SortableBySortPosition,
>(
  a: _SortableA,
  b: _SortableB,
): number => a.sortPosition - b.sortPosition

/**
 * Sorts an array by sortPosition.
 *
 * Any elements which don't have a valid sortPosition property are filtered out
 * prior to the sort.
 */
export const getSortedAndFilteredBySortPosition = <_PossiblySortable>(
  arrayToSort: _PossiblySortable[],
): (_PossiblySortable & SortableBySortPosition)[] =>
  arrayToSort
    .flatMap((possiblySortable) =>
      getHasSortPosition(possiblySortable) ? [possiblySortable] : [],
    )
    .sort(sortPositionComparator)
