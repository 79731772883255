import React from 'react'
import { useExperiment } from 'utils/use-experiment-wrapper'
import { isStorageAccessible } from 'utils/'
import { useStatsigClient } from '@statsig/react-bindings'

export interface IModalLeaveBehindContext {
  modalLeaveBehindOrderId?: string
  setModalLeaveBehindOrderId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >
  openModalLeaveBehind?: boolean
  setOpenModalLeaveBehind: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >
  openModalLeaveBehindExtension?: boolean
  setOpenModalLeaveBehindExtension: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >
  modalLeaveBehindId?: string
  setModalLeaveBehindId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >
  modalLeaveBehindSlug?: string
  setModalLeaveBehindSlug: React.Dispatch<
    React.SetStateAction<string | undefined>
  >
  isModalLeaveBehindCard?: boolean
  setIsModalLeaveBehindCard: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >
  modalLeaveBehindTipSlug?: string
  setModalLeaveBehindTipSlug: React.Dispatch<
    React.SetStateAction<string | undefined>
  >
  shouldOpenModalLeaveBehindCapOneShopping?: boolean
  handleSetOpenModalLeaveBehind: () => void
  onCloseModalLeaveBehind: () => void
  showOpenLeaveBehindModalExtension?: boolean
}

export const ModalLeaveBehindContext =
  React.createContext<IModalLeaveBehindContext>({
    modalLeaveBehindOrderId: undefined,
    setModalLeaveBehindOrderId: () => null,
    openModalLeaveBehind: false,
    setOpenModalLeaveBehind: () => null,
    openModalLeaveBehindExtension: false,
    setOpenModalLeaveBehindExtension: () => null,
    modalLeaveBehindId: '',
    setModalLeaveBehindId: () => null,
    modalLeaveBehindSlug: '',
    setModalLeaveBehindSlug: () => null,
    isModalLeaveBehindCard: false,
    setIsModalLeaveBehindCard: () => null,
    modalLeaveBehindTipSlug: '',
    setModalLeaveBehindTipSlug: () => null,
    handleSetOpenModalLeaveBehind: () => null,
    onCloseModalLeaveBehind: () => null,
    showOpenLeaveBehindModalExtension: false,
  })

export const ModalLeaveBehindContextProvider: React.FC<
  React.PropsWithChildren
> = ({ children }) => {
  const [modalLeaveBehindOrderId, setModalLeaveBehindOrderId] =
    React.useState<string>()
  const [openModalLeaveBehind, setOpenModalLeaveBehind] =
    React.useState<boolean>()
  const [openModalLeaveBehindExtension, setOpenModalLeaveBehindExtension] =
    React.useState<boolean>()
  const [modalLeaveBehindId, setModalLeaveBehindId] = React.useState<string>()
  const [modalLeaveBehindSlug, setModalLeaveBehindSlug] =
    React.useState<string>()
  const [isModalLeaveBehindCard, setIsModalLeaveBehindCard] =
    React.useState<boolean>()
  const [modalLeaveBehindTipSlug, setModalLeaveBehindTipSlug] =
    React.useState<string>()

  // SETTING THIS AS FALSE BECAUSE WE PAUSED THE LBM CAP ONE SHOPPING FOR NOW
  const shouldOpenModalLeaveBehindCapOneShopping = false
  // const [
  //   shouldOpenModalLeaveBehindCapOneShopping,
  //   setShouldOpenModalLeaveBehindCapOneShopping,
  // ] = React.useState<boolean>()

  /* ===== LBM ===== */
  const { logEvent } = useStatsigClient()

  /* ===== 2024.09: EXP-305 LBM Card on /credit-cards/tips/wells-fargo-reflect-fmc-wig-sp/ ===== */
  const { experiment: configExp285, isLoading: isLoadingExp285 } =
    useExperiment('exp_285_extension_leave_behind')
  const variationExp285 = configExp285?.value?.name || 'v1-lbm-none'
  const showOpenLeaveBehindModalExtension =
    !isLoadingExp285 && variationExp285 === 'control'

  const sessionStorageExist = isStorageAccessible()

  const handleSetOpenModalLeaveBehind = React.useCallback(() => {
    if (
      sessionStorageExist &&
      // shouldOpenModalLeaveBehindCapOneShopping &&
      modalLeaveBehindSlug === 'cap-one-shopping'
    ) {
      sessionStorage.setItem('modalLeaveBehindCapOne', 'true')
    }
    if (
      sessionStorageExist &&
      // !shouldOpenModalLeaveBehindCapOneShopping &&
      modalLeaveBehindSlug &&
      modalLeaveBehindTipSlug
    ) {
      sessionStorage.setItem(
        'modalLeaveBehind-' + modalLeaveBehindTipSlug,
        'true',
      )
    }

    if (showOpenLeaveBehindModalExtension && sessionStorageExist) {
      sessionStorage.setItem('modalLeaveBehindExtension', 'true')
    }

    if (!showOpenLeaveBehindModalExtension) {
      setOpenModalLeaveBehind(false)
    } else {
      setOpenModalLeaveBehind(true)
    }

    if (showOpenLeaveBehindModalExtension) {
      setOpenModalLeaveBehindExtension(true)
    }
  }, [
    sessionStorageExist,
    setOpenModalLeaveBehind,
    // shouldOpenModalLeaveBehindCapOneShopping,
    modalLeaveBehindSlug,
    modalLeaveBehindTipSlug,
    setOpenModalLeaveBehindExtension,
    showOpenLeaveBehindModalExtension,
    isLoadingExp285,
    variationExp285,
  ])

  const onCloseModalLeaveBehind = React.useCallback(() => {
    setOpenModalLeaveBehind(false)
    logEvent('modal_leave_behind_closed')
  }, [setOpenModalLeaveBehind])

  const value = React.useMemo<IModalLeaveBehindContext>(
    () => ({
      modalLeaveBehindOrderId,
      setModalLeaveBehindOrderId,
      openModalLeaveBehind,
      setOpenModalLeaveBehind,
      openModalLeaveBehindExtension,
      setOpenModalLeaveBehindExtension,
      modalLeaveBehindId,
      setModalLeaveBehindId,
      modalLeaveBehindSlug,
      setModalLeaveBehindSlug,
      isModalLeaveBehindCard,
      setIsModalLeaveBehindCard,
      modalLeaveBehindTipSlug,
      setModalLeaveBehindTipSlug,
      shouldOpenModalLeaveBehindCapOneShopping,
      handleSetOpenModalLeaveBehind,
      onCloseModalLeaveBehind,
      showOpenLeaveBehindModalExtension,
    }),
    [
      modalLeaveBehindOrderId,
      openModalLeaveBehind,
      modalLeaveBehindId,
      modalLeaveBehindSlug,
      openModalLeaveBehindExtension,
      setOpenModalLeaveBehindExtension,
      showOpenLeaveBehindModalExtension,
      isModalLeaveBehindCard,
      modalLeaveBehindTipSlug,
      shouldOpenModalLeaveBehindCapOneShopping,
    ],
  )

  return (
    <ModalLeaveBehindContext.Provider value={{ ...value }}>
      {children}
    </ModalLeaveBehindContext.Provider>
  )
}
