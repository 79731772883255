import * as React from 'react'
import Link from 'next/link'
import { NavItem } from './types'

export default function MenuItem(props: NavItem) {
  const { icon, text, href } = props
  return (
    <Link href={href || '#'}>
      <div className="flex items-center w-full mb-1 px-2 py-1 text-gray-700 / rounded-sm / hover:bg-primary-light-bg hover:text-primary-bright / transition-all / cursor-pointer">
        <div className="flex w-full items-center">
          {icon && (
            <span
              className={`c-cate-icon ${icon} / inline-block / w-7 h-7 mr-3`}
            ></span>
          )}
          <span className="font-extralight cursor-pointer">{text}</span>
        </div>
      </div>
    </Link>
  )
}
