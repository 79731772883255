import React from 'react'
import { PortableText } from '@portabletext/react'
import { CompareCredit } from '../../../types/compare-credit'
import { blockSerializers } from '../sanity-serializers'

const Content = ({
  blocks,
  updatedDate,
  author,
  reviewer,
  location,
  tag,
  categoryTag,
  cardOrderIds,
  referencedCards,
  displayElement,
  exitModalRef,
  exitModalMultiCardTipPosition,
  sort,
}: {
  blocks: any
  updatedDate?: Date
  author?: CompareCredit.TipV2['author']
  reviewer?: CompareCredit.TipV2['author']
  location?: string
  tag?: CompareCredit.CategoryTag
  categoryTag?: CompareCredit.CategoryTag
  cardOrderIds: Record<string, string>
  referencedCards: null | Record<string, CompareCredit.Entities>
  displayElement?: { display?: string; breakpoint?: string }
  exitModalRef: React.RefObject<any>
  exitModalMultiCardTipPosition?: number
  sort?: string
}) => {
  const [style, setStyle] = React.useState('')

  const blocksFormatted = blocks.map((block: any) => {
    if (block._type === 'tipHeader') {
      return {
        ...block,
        updatedDate,
        author,
        reviewer,
        location,
      }
    } else if (block._type === 'authorHeader') {
      return {
        ...block,
        author,
      }
    } else if (
      block._type === 'tipCardTileList' ||
      block._type === 'tipCardTileListSlim' ||
      block._type === 'tipSingleCardTile'
    ) {
      return {
        ...block,
        displayElement,
        tag,
        categoryTag,
        sort,
        referencedCards,
      }
    } else if (
      block._type === 'previewCards' ||
      block._type === 'cardSummaryBlock'
    ) {
      return {
        ...block,
        sort,
        referencedCards,
      }
    }

    return block
  })

  React.useEffect(() => {
    const styles = []
    blocks.forEach((block: any) => {
      if (block._type === 'tipSingleCardTile') {
        styles.push('relative z-2 ')
      }
      if (block._type === 'tipSingleCardTile' && block.isSticky === true) {
        styles.push('relative z-1 h-full ')
      }
    })

    if (displayElement?.display && displayElement?.breakpoint) {
      const { display, breakpoint } = displayElement

      const breakpointStyle = `${breakpoint}:${
        display === 'hidden' ? 'hidden' : 'block'
      } ${display === 'hidden' ? 'block' : 'hidden'} `

      styles.push(breakpointStyle)
    }
    setStyle(styles.join(' '))
  }, [blocks, displayElement])

  return blocks ? (
    <div className={`c-article-content ` + style}>
      <PortableText
        value={blocksFormatted}
        components={blockSerializers({
          cardOrderIds,
          categoryId: categoryTag?.id,
          externalId: tag?.id,
          referencedCards,
          exitModalRef,
          exitModalMultiCardTipPosition,
        })}
      />
    </div>
  ) : null
}
export default Content
