import React from 'react'
import propOr from 'lodash/fp/propOr'

import { supify } from '../../utils'

export const AttributeRendererWithSlug = (referencedCards: {
  [key: string]: any
}) => {
  const AttributeRendererWithSlugRender = (attrProps: {
    value: {
      attribute: string
      slug: string
      bonusRewards: string
    }
  }) => {
    const cardSlug = attrProps.value.slug
    const card = referencedCards.find(
      ({ slug }: { slug: string }) => slug === cardSlug,
    )
    const attributeToRender =
      attrProps.value.attribute === 'bonusRewards'
        ? attrProps.value.bonusRewards
        : propOr('', attrProps.value.attribute, card)
    return (
      card &&
      React.createElement('span', {
        dangerouslySetInnerHTML: {
          __html: supify(attributeToRender),
        },
      })
    )
  }
  return AttributeRendererWithSlugRender
}
