import type { CompareCredit } from '../../../../types'
import { getIsPinned } from '../../getIsPinned'

export interface Entity {
  rpiUsed?: number
  isPinned?: boolean
  rpi?: number
  rpiData?: {
    six: Record<string, { rpi: number }>
    thirty: Record<string, { rpi: number }>
  }
  _type?: string
  sixDayRpiLookBack?: boolean
  slug?: any
  cardSlug?: string
  position?: number
  pinnedPosition?: number
}

/**
 * It takes an array of entities and a map of pinned products, and returns an object with two arrays:
 * one for pinned products and one for non-pinned products
 * @param {_Entity[]} entities - The array of entities that we want to separate into pinned and
 * non-pinned
 * @param pinnedProducts - The list of pinned products.
 * @param pinnedPositions - original positions of cards
 * @returns An object with two properties:
 * - pinned: An array of objects that are pinned
 * - nonPinned: An array of objects that are not pinned
 */
export const separateEntities = <_Entity extends Entity>(
  entities: _Entity[],
  pinnedProducts: CompareCredit.PinnedProductFormatted,
  pinnedPositions: Record<string, number>,
) => {
  const initialValue: {
    pinned: Array<[_Entity, number]>
    nonPinned: _Entity[]
  } = { pinned: [], nonPinned: [] }

  return entities.reduce((acc, val) => {
    const slug = val.slug || val.cardSlug || ''
    if (
      (val._type === 'card' || val._type === 'cardTip') &&
      getIsPinned(pinnedProducts[slug])
    ) {
      // keep track of idx so we can insert it into place later
      acc.pinned.push([val, pinnedPositions[slug]])
    } else if (val._type !== 'card' && val._type !== 'cardTip') {
      acc.pinned.push([val, pinnedPositions[slug]])
    } else {
      acc.nonPinned.push(val)
    }
    return acc
  }, initialValue)
}

/**
 * Insert the pinned entities into the original index
 * @param pinnedEntities - an array of [Entity, index] pairs.
 * @param {_Entity[]} sortedNonPinned - The list of entities that are not pinned.
 * @returns An array of entities, sorted by rpi with pinned entities.
 */
export const insertPinnedIntoOriginalIdx = <_Entity extends Entity>(
  pinnedEntities: Array<[_Entity, number]>,
  sortedNonPinned: _Entity[],
) => {
  return (
    pinnedEntities
      // insert from the smallest idx up, otherwise it could get out of order
      .sort((a, b) => {
        return a[1] - b[1]
      })
      .reduce((acc, val) => {
        const [value, i] = val
        // insert the pinned card in it's original spot
        acc.splice(i, 0, value)
        return acc
      }, sortedNonPinned)
  )
}
