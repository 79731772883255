import { CallToApply, LinkToRedirect } from '../../..'
import {
  PortableText,
  PortableTextListComponent,
  PortableTextListItemComponent,
} from '@portabletext/react'

import { CompareCredit } from '../../../../../types/compare-credit'
import React from 'react'
import propOr from 'lodash/fp/propOr'
import { supify } from '../../../../utils'

function ApplyNowLink({
  entity,
  orderId,
  externalId = '00',
}: {
  externalId?: string
  entity: CompareCredit.FormattedCard
  orderId: string
}) {
  return (
    <>
      Select{' '}
      <LinkToRedirect
        externalId={externalId}
        linkParams={entity.applyNowLinkParameters}
        orderId={orderId}
        slug={entity.slug}
      >
        <span
          className={`text-primary-bright font-semibold / hover:text-primary-mid hover:underline / trk_card-detail_list-cta trk_card-detail_list-cta_${entity.slug}`}
        >
          &quot;APPLY NOW&quot;
        </span>
      </LinkToRedirect>{' '}
      to apply online
      {entity.phoneNumber && <CallToApply entity={entity} numOnly />}
    </>
  )
}

export function ProductDetails(props: {
  externalId?: string
  orderId: string
  entity: CompareCredit.Entity
  splitIt?: boolean
  view?: string
}) {
  const { externalId, entity, orderId } = props

  const ListItem = ({ children }: { children: any }) => {
    return children.length === 1 ? (
      React.createElement('li', {
        className: 'c-list-disc__item mb-2',
        dangerouslySetInnerHTML: {
          __html: supify(children[0]),
        },
      })
    ) : (
      <li className="c-list-disc__item mb-2">{children}</li>
    )
  }

  function AttributeRenderer<K extends keyof CompareCredit.Entity>(attrProps: {
    value: { attribute: K }
  }) {
    return React.createElement('span', {
      dangerouslySetInnerHTML: {
        __html: supify(
          propOr('', attrProps.value.attribute, props.entity)?.toString(),
        ),
      },
    })
  }

  function divideBulletsConsideringLinesHeight(
    marketingBullets: CompareCredit.EditorialBlock[],
    width: number,
    fontSize: number,
    fontFamily: string,
  ) {
    const measureTextHeight = (text: string) => {
      const div = document.createElement('div')
      document.body.appendChild(div)
      div.style.position = 'absolute'
      div.style.visibility = 'hidden'
      div.style.width = `${width}px`
      div.style.fontSize = `${fontSize}px`
      div.style.fontFamily = fontFamily
      div.style.whiteSpace = 'pre-wrap'
      div.textContent = text
      const height = div.offsetHeight
      div.remove()
      return height
    }

    const MARGIN_BOT_HEIGHT = 8
    const applyNowText = `Select "APPLY NOW" to apply online.`
    const applyNowBullet = {
      children: [{ text: applyNowText }],
    } as CompareCredit.EditorialBlock

    const applyNowHeight = measureTextHeight(applyNowText)

    const totalHeight = marketingBullets.reduce(
      (acc, bullet) =>
        acc +
        measureTextHeight(bullet.children[0].text || '') +
        MARGIN_BOT_HEIGHT,
      applyNowHeight,
    )

    const targetHeightFirstColumn = totalHeight / 2
    let currentHeight = applyNowHeight
    let cutIndex = -1

    for (let i = 0; i < marketingBullets.length; i++) {
      const bulletHeight = measureTextHeight(
        marketingBullets[i].children[0].text || '',
      )

      if (currentHeight + bulletHeight >= targetHeightFirstColumn && i >= 2) {
        cutIndex = i
        break
      } else {
        currentHeight += bulletHeight + MARGIN_BOT_HEIGHT
      }
    }

    if (cutIndex === -1) {
      cutIndex = marketingBullets.length
    }

    const arr1 = [applyNowBullet].concat(marketingBullets.slice(0, cutIndex))
    const arr2 = marketingBullets.slice(cutIndex)

    return { arr1, arr2 }
  }

  const COLUMN_FONT_SIZE = 14
  const COLUMN_FONT_FAMILY = 'Open Sans'
  const COLUMN_WIDTH = 448

  const { arr1, arr2 } = divideBulletsConsideringLinesHeight(
    entity.marketingBullets,
    COLUMN_WIDTH,
    COLUMN_FONT_SIZE,
    COLUMN_FONT_FAMILY,
  )

  const arr1List = ({ children }: { children: any }) => (
    <ul className="c-list-disc c-list-disc--blue / w-full md:w-1/2 / pl-4 / md:px-6">
      {entity._type === 'card' && (
        <ListItem>
          <ApplyNowLink
            orderId={orderId}
            externalId={externalId}
            entity={entity}
          />
        </ListItem>
      )}
      {children}
    </ul>
  )

  const arr2List = ({ children }: { children: any }) => (
    <ul className="c-list-disc c-list-disc--blue / w-full md:w-1/2 / pl-4 / md:px-6">
      {children}
    </ul>
  )

  const marketingBulletsList = ({ children }: { children: any }) => (
    <ul className="c-list-disc c-list-disc--blue / pl-5 py-1 text-sm">
      {entity._type === 'card' && (
        <ListItem>
          <ApplyNowLink
            orderId={orderId}
            externalId={externalId}
            entity={entity}
          />
        </ListItem>
      )}
      {children}
    </ul>
  )

  return (
    <div className="md:flex / text-sm text-card-gray">
      {props.splitIt ? (
        <>
          <PortableText
            value={arr1 as any}
            components={{
              list: arr1List as PortableTextListComponent,
              listItem: ListItem as PortableTextListItemComponent,
              types: {
                cardAttribute: AttributeRenderer,
              },
            }}
          />

          <PortableText
            value={arr2 as any}
            components={{
              list: arr2List as PortableTextListComponent,
              listItem: ListItem as PortableTextListItemComponent,
              types: {
                cardAttribute: AttributeRenderer,
              },
            }}
          />
        </>
      ) : (
        <PortableText
          value={entity.marketingBullets as any}
          components={{
            list: marketingBulletsList as PortableTextListComponent,
            listItem: ListItem as PortableTextListItemComponent,
            types: {
              cardAttribute: AttributeRenderer,
            },
          }}
        />
      )}
    </div>
  )
}
