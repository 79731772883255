import { SORT_CALL_DATA } from '../types'
import * as Sentry from '@sentry/nextjs'

export async function fetchSorts(data: SORT_CALL_DATA) {
  try {
    const response = await fetch(
      `/api/entities/sort/${data.id}/${data.version}/${data.encodedParams}/`,
    )
    const responseJson = await response.json()

    if (response.ok) {
      return responseJson
    } else {
      if (responseJson && Object.keys(responseJson).length === 0) {
        console.log(`Error fetch-sorts response is empty`)
      } else {
        console.log(
          `Error fetch-sorts ${JSON.stringify(
            responseJson ? responseJson : '',
          )}`,
        )
      }

      const errStatusTextResponse =
        response.statusText || 'Error in fetchSorts function'
      throw new Error(errStatusTextResponse)
    }
  } catch (err: any) {
    Sentry.captureException(err, {
      contexts: {
        data: {
          fileName: __filename,
        },
      },
    })
    throw err
  }
}
