import { createContext, useContext, useMemo } from 'react'
import { usePathname } from 'next/navigation'

export interface IAwardsBadgeContext {
  awardsCardsSlugList?: Record<string, string>
  getBadgeData: (
    entitySlug: string,
    position?: number,
  ) => {
    showBadge: boolean
    badgeTitle?: string
  }
}

export const AwardsBadgeContext = createContext<IAwardsBadgeContext>({
  awardsCardsSlugList: undefined,
  getBadgeData: () => ({ showBadge: false }),
})

export const AwardsBadgeContextProvider: React.FC<
  React.PropsWithChildren & {
    value: Pick<IAwardsBadgeContext, 'awardsCardsSlugList'>
  }
> = ({ value, children }) => {
  const pathname = usePathname()
  const { awardsCardsSlugList } = value

  const getBadgeData = (entitySlug: string, position?: number) => {
    const hasAwardsCard =
      awardsCardsSlugList &&
      Object.keys(awardsCardsSlugList).includes(entitySlug) &&
      position === 1 &&
      pathname === '/credit-cards/best/balance-transfer/'

    return {
      showBadge: !!hasAwardsCard,
      badgeTitle: hasAwardsCard ? awardsCardsSlugList[entitySlug] : undefined,
    }
  }

  const enhancedValue = useMemo(
    () => ({ ...value, getBadgeData }),
    [value, awardsCardsSlugList, pathname],
  )

  return (
    <AwardsBadgeContext.Provider value={enhancedValue}>
      {children}
    </AwardsBadgeContext.Provider>
  )
}

export const useAwardsBadgeContext = (): IAwardsBadgeContext => {
  const awardsBadgeData = useContext(AwardsBadgeContext)

  return awardsBadgeData
}
