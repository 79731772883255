import * as React from 'react'
import { CompareCredit } from '../../../../../types/compare-credit'
import { CardArt, LinkToRedirect } from 'components/'
import { nextImageHelper } from 'utils/'
import { clickRatesAndFees } from '../../../../clients/segment'
import Link from 'next/link'
import { ApplyNowIcon } from 'components/link-to-redirect/ApplyNowIcon'

export function DetailCardArt({
  card,
  cardArt,
  categoryId,
  externalId,
  customCodeSnippets,
  issuer,
  name,
  linkParams,
  orderId,
  ratesAndFees,
  slug,
  _rev,
}: {
  card: CompareCredit.FormattedCard | CompareCredit.NonPaidCard
  cardArt: CompareCredit.FormattedCard['cardArt']
  categoryId: string
  externalId: string
  customCodeSnippets?:
    | CompareCredit.FormattedCard['customCodeSnippets']
    | undefined
  issuer: string | undefined
  name: string
  linkParams?: CompareCredit.FormattedCard['applyNowLinkParameters']
  orderId: string
  ratesAndFees: CompareCredit.FormattedCard['ratesAndFees']
  slug: string
  _rev: string | null
}) {
  const isCard = card._type === 'card'
  return (
    <div
      className={`flex flex-col items-center justify-center lg:justify-start / text-center / ${
        ratesAndFees && ratesAndFees !== 'N/A' ? 'mb-1 md:mb-4' : 'mb-5 md:mb-4'
      } mx-auto / md:pr-6 lg:pr-4 / w-4/5 max-w-xs md:max-w-auto md:w-4/12`}
    >
      <div className="c-card-art w-full relative">
        <div className="c-card-art__container / relative / max-w-13rem xs:w-full xs:max-w-xs mx-auto xs:mx-0 / rounded-lg shadow">
          {isCard ? (
            <LinkToRedirect
              externalId={externalId}
              linkParams={linkParams}
              orderId={orderId}
              slug={slug}
            >
              <div
                className={`relative flex rounded-lg overflow-hidden shadow z-0`}
              >
                <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white text-sm xs:text-base lg:text-lg">
                  <span className="w-full xs:mb-1">
                    <span className="inline-block w-4 h-4 xs:w-8 xs:h-8 lg:w-10 lg:h-10">
                      <ApplyNowIcon
                        height={16}
                        width={16}
                        style={nextImageHelper.responsive}
                        sizes="100vw"
                      />
                    </span>
                  </span>
                  <span className="w-full font-semibold">Apply Now</span>
                </span>

                <div className="relative w-full h-full top-0">
                  <CardArt
                    cardArt={cardArt}
                    imgClasses="relative z-0 w-full h-full object-cover top-0"
                    customCodeSnippets={customCodeSnippets}
                    issuer={issuer}
                    name={name}
                    orderId={orderId}
                    slug={slug}
                    categoryId={categoryId}
                    externalId={externalId}
                    _rev={_rev}
                  />
                </div>
              </div>
            </LinkToRedirect>
          ) : (
            <Link href={card.moreInfoLink}>
              <div
                className={`relative flex rounded-lg overflow-hidden shadow z-0`}
              >
                <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white text-sm xs:text-base lg:text-lg">
                  <span className="w-full xs:mb-1 lg:mb-2">
                    <span className="inline-block w-4 h-4 xs:w-8 xs:h-8 lg:w-10 lg:h-10"></span>
                  </span>
                  <span className="w-full font-semibold">Learn More</span>
                </span>

                <div className="relative w-full h-full top-0">
                  <CardArt
                    cardArt={cardArt}
                    imgClasses="relative z-0 w-full h-full object-cover top-0"
                    customCodeSnippets={customCodeSnippets}
                    issuer={issuer}
                    name={name}
                    orderId={orderId}
                    slug={slug}
                    categoryId={categoryId}
                    externalId={externalId}
                    _rev={_rev}
                  />
                </div>
              </div>
            </Link>
          )}
        </div>
      </div>

      {ratesAndFees && ratesAndFees !== 'N/A' && (
        <p className="mt-2 text-center w-full">
          <a
            className="text-primary-bright text-xs"
            onClick={() => {
              isCard &&
                clickRatesAndFees({
                  applyNowLink: card.applyNowLink,
                  component: 'Detail Card Art',
                  name: card.name,
                  url: window.location.href,
                })
            }}
            href={ratesAndFees}
            rel="noopener noreferrer"
            target="_blank"
          >
            Rates &amp; Fees
          </a>
        </p>
      )}
      {issuer === 'american-express' && (
        <p className="text-fs10 text-gray-600 / text-center / mb-3 md:mb-1 / w-full leading-4">
          Terms Apply
        </p>
      )}
    </div>
  )
}
