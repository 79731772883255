import React from 'react'
import { CardArt, LinkToRedirect } from '../..'
import { CompareCredit } from '../../../../types/compare-credit'
import { ApplyNowIcon } from 'components/link-to-redirect/ApplyNowIcon'

interface ProductArtProps {
  categoryId: string
  externalId: string
  layoutSlim?: boolean
  orderId: string
  productTip: CompareCredit.FormattedCard
  query: string
  width: 'w-full' | 'w-56'
}

export default function ProductArt(props: ProductArtProps) {
  const {
    categoryId,
    externalId,
    layoutSlim,
    orderId,
    productTip,
    query,
    width,
  } = props
  return (
    <>
      <div
        className={`c-card-art / ${width} / text-center / flex items-center justify-center / mx-auto ${
          productTip._type === 'card' ? 'mb-2' : ''
        }`}
      >
        <div
          className={`relative / w-full ${
            productTip._type === 'card' ? 'rounded shadow' : 'mt-6 mb-2'
          } ${layoutSlim ? '' : '-mt-6'}`}
        >
          <LinkToRedirect
            externalId={externalId}
            linkParams={
              productTip._type === 'card'
                ? productTip.applyNowLinkParameters
                : []
            }
            orderId={orderId}
            query={query !== '' ? query : undefined}
            slug={productTip.slug}
          >
            <div className="relative flex rounded-sm overflow-hidden / trk_card-tile_card-art">
              {productTip._type === 'card' && (
                <span
                  className={`c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white ${
                    layoutSlim ? 'text-fs12 leading-tight' : ''
                  }`}
                >
                  <span
                    className={`relative / w-full / mb-1 mx-auto ${
                      layoutSlim ? 'hidden xxs:block w-6 h-5' : 'w-8 h-8'
                    }`}
                  >
                    <ApplyNowIcon aria-hidden={true} fill sizes="100vw" />
                  </span>
                  <span className="w-full font-bold">Apply Now</span>
                </span>
              )}
              <CardArt
                _rev={productTip._rev}
                cardArt={productTip.cardArt}
                imgClasses="relative / z-0 / w-full h-full object-cover top-0"
                customCodeSnippets={
                  productTip._type === 'card'
                    ? productTip.customCodeSnippets
                    : undefined
                }
                issuer={productTip.issuer.slug.current}
                name={productTip.name}
                orderId={orderId}
                slug={productTip.slug}
                type={productTip._type}
                categoryId={categoryId}
                externalId={externalId}
              />
            </div>
          </LinkToRedirect>
        </div>
      </div>
    </>
  )
}
