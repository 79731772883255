import { useStatsigClient } from '@statsig/react-bindings'
import { usePathname } from 'next/navigation'
import { useMemo } from 'react'

export const useShouldShowAwardsBadgeForExp364 = () => {
  const currentPathname = usePathname()
  const statsigClient = useStatsigClient()

  const urlSearchParams = new URLSearchParams(window.location.search)

  const utmMediumIsSocial =
    urlSearchParams.get('utm_medium')?.toLowerCase() === 'social'

  const isDicbNearlyPage =
    currentPathname === '/credit-cards/tips/discover-cash-back-ms/'

  const shouldShowAwardsBadge = useMemo(() => {
    const shouldUserBeExposedToExperiment =
      utmMediumIsSocial && isDicbNearlyPage

    return shouldUserBeExposedToExperiment
      ? statsigClient
          .getExperiment('exp_364_disco_single_card_awards_badge_ms')
          .get('should_show_awards_badge', false)
      : false
  }, [utmMediumIsSocial, isDicbNearlyPage, statsigClient])

  return shouldShowAwardsBadge
}
