const isOrderIdInvalid = (orderId: unknown): boolean => {
  const orderIdString = String(orderId).toLowerCase()
  const invalidValues = new Set(['1', 'null', 'undefined'])
  const pattern = /^[\w-]+_(null|undefined|)$/i

  return (
    invalidValues.has(orderIdString) ||
    pattern.test(orderIdString) ||
    orderIdString.startsWith('ext--') ||
    orderIdString.length < 36
  )
}

export { isOrderIdInvalid }
