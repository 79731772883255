import { useStatsigClient } from '@statsig/react-bindings'

export const useApplyNowIcon = (): 'lock' | 'redirect' => {
  const { getExperiment } = useStatsigClient()

  const applyNowIcon =
    getExperiment('exp_367_cta_button_icon_redirect').get(
      'apply_button_icon',
      'lock',
    ) === 'redirect'
      ? 'redirect'
      : 'lock'

  return applyNowIcon
}
