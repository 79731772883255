import * as React from 'react'
import { v4 as uuid } from 'uuid'
import { useRouter } from 'next/router'
import * as Sentry from '@sentry/nextjs'
import { CompareCredit } from '../../../types'
import { viewProductList } from '../../clients/segment'
import { BannerCategoryListCard, CardPanel } from '..'
import ErrorBoundary from 'components/error-boundary'
import { getCardArrangement } from 'utils/get-card-arrangement'
import { useIsUserOnBrave } from 'utils/useIsUserOnBrave'
import { useShouldShowCfuCategoryBanner } from 'utils/experimentation/useShouldShowCfuCategoryBanner'

export function CardList({
  category,
  categoryId, // Now the new categoryTag id
  externalId, // The old categoryId
  comparisonDisabled,
  entities: primaryArrangement,
  slugs,
  categoryListBanner,
}: {
  category: string
  categoryId: string
  externalId: string
  comparisonDisabled?: boolean
  entities: CompareCredit.Entity[]
  categoryListBanner?: CompareCredit.CategoryListBanner
  slugs: string[]
}) {
  const [arrangement, setArrangement] = React.useState<
    CompareCredit.FormattedCard[]
  >([])
  const [arrangementId, setArrangementId] = React.useState('')
  const [categoryList, setCategoryList] = React.useState(categoryListBanner)
  const router = useRouter()
  const isBofAIssuerPage = category === 'bank-of-america'

  const userIsOnBrave = useIsUserOnBrave()

  React.useEffect(() => {
    if (isBofAIssuerPage && userIsOnBrave) {
      router.push('/')
    }
  }, [isBofAIssuerPage, userIsOnBrave])

  React.useEffect(() => {
    setArrangementId(uuid())
  }, [category])

  React.useEffect(() => {
    if (primaryArrangement && primaryArrangement.length === 0) return
    if (arrangementId === 'primary' || arrangementId === null) {
      // Send to Sentry
      Sentry.captureMessage('arrangementId is primary or null', {
        contexts: {
          context: {
            arrangementId,
            file: '/components/card-list/index.tsx',
            path: window.location.pathname,
          },
        },
      })
    }

    const arrangement = getCardArrangement({
      default: () => primaryArrangement,
    }) as CompareCredit.FormattedCard[]

    let slugsMatch = true

    for (const cardItem of arrangement) {
      const includesSlug = slugs.includes(cardItem.slug)
      if (!includesSlug) {
        slugsMatch = false
      }
    }

    // If the slugs in the entities do not match the slug props,
    // then do not render the card list
    if (!slugsMatch) {
      return
    }

    // CC-2052: Remove Bank of America cards from the card list if the user is on Brave
    const removeBofACards = (product: CompareCredit.FormattedCard) => {
      return product.issuer.slug.current !== 'bank-of-america'
    }
    setArrangement(
      userIsOnBrave ? arrangement.filter(removeBofACards) : arrangement,
    )
    viewProductList({
      arrangementId: arrangementId,
      entities: arrangement,
      category: category,
    })

    if (categoryListBanner?.cardSortMatch) {
      const updatedCategoryListBanner = { ...categoryListBanner }
      const firstCardInSortList =
        updatedCategoryListBanner.cardSortMatchNumber &&
        updatedCategoryListBanner.cardSortMatchNumber >= 1
          ? [arrangement[0]]
          : []
      const secondCardInSortList =
        updatedCategoryListBanner.cardSortMatchNumber &&
        updatedCategoryListBanner.cardSortMatchNumber >= 2
          ? [arrangement[1]]
          : []
      const thirdCardInSortList =
        updatedCategoryListBanner.cardSortMatchNumber === 3
          ? [arrangement[2]]
          : []

      if (
        !categoryListBanner?.bannerCards ||
        categoryListBanner?.bannerCards.length === 0
      ) {
        updatedCategoryListBanner.bannerCards = [
          ...firstCardInSortList,
          ...secondCardInSortList,
          ...thirdCardInSortList,
        ]
        setCategoryList(updatedCategoryListBanner)
      } else if (categoryListBanner?.bannerCards.length > 0) {
        updatedCategoryListBanner.bannerCards = [
          ...categoryListBanner.bannerCards.filter((bannerCard) => {
            return secondCardInSortList.length > 0
              ? bannerCard._id !==
                  firstCardInSortList[0]?._id.replace('drafts.', '') &&
                  secondCardInSortList[0]?._id.replace('drafts.', '') !==
                    bannerCard._id
              : bannerCard._id !==
                  firstCardInSortList[0]?._id.replace('drafts.', '')
          }),
          ...firstCardInSortList,
          ...secondCardInSortList,
        ]
        setCategoryList(updatedCategoryListBanner)
      }
    }
  }, [
    JSON.stringify(primaryArrangement.map(({ _id }: any) => _id)),
    JSON.stringify(slugs),
    arrangementId,
  ])

  // Banner Position
  const bannerPositionCard =
    categoryListBanner && categoryListBanner.bannerPosition
      ? Number(categoryListBanner.bannerPosition) - 1
      : 5

  // 2025.04: EXP-373 Category Banner CFU
  const shouldShowCfuCategoryBanner = useShouldShowCfuCategoryBanner()

  return (
    <>
      {arrangement.map((product: CompareCredit.FormattedCard, idx: number) => {
        const collapsed = true

        return (
          <ErrorBoundary key={product.name}>
            {shouldShowCfuCategoryBanner &&
              categoryListBanner?.includeCategoryListBanner &&
              categoryList && (
                <>
                  {idx == bannerPositionCard && (
                    <div key={idx} className={`mb-5`}>
                      <BannerCategoryListCard
                        categoryId={categoryId}
                        externalId={externalId}
                        categoryListBanner={categoryList}
                      />
                    </div>
                  )}
                </>
              )}

            <CardPanel
              key={product.name}
              index={idx}
              position={idx + 1}
              featured={idx === 0}
              entity={product as CompareCredit.FormattedCard}
              view="list"
              category={category}
              categoryId={categoryId} // new category tag id -> send to impression events
              externalId={externalId} // old category id -> send to url
              comparisonDisabled={comparisonDisabled}
              arrangementId={arrangementId}
              collapsed={collapsed}
            />

            {shouldShowCfuCategoryBanner &&
              categoryListBanner &&
              categoryList &&
              bannerPositionCard >= arrangement.length &&
              arrangement.length - 1 === idx && (
                <div key={arrangement.length} className={`mb-5`}>
                  <BannerCategoryListCard
                    categoryId={categoryId}
                    externalId={externalId}
                    categoryListBanner={categoryList}
                  />
                </div>
              )}
          </ErrorBoundary>
        )
      })}
    </>
  )
}
