import { useContext, useEffect, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import { v4 as createUuid } from 'uuid'

import { getDate, useFetchCardsSlug } from 'utils/'
import cn from 'utils/classnames'
import { generateNewOrderId } from 'utils/orderId/generateNewOrderId'
import { useFetchCardsSlugComplexReturn } from 'utils/use-fetch-cards-helper'
import type { CompareCredit } from '../../../types/compare-credit/index.d'
import {
  AdvertiserDisclaimer,
  CardNavV2,
  ExitModalCard,
  ModalLeaveBehindContext,
  LayoutMain,
} from '../'
import ErrorBoundary from '../error-boundary'
import { ExitIntent } from '../exit-intent'
import Grid from '../grid-modules/grid'

export const TipsTemplate: React.FC<{
  _updatedAt: string
  modules: any[]
  slug: { current: string }
  nav?: string
  cardUpsellDisplay?: boolean
  cardUpsellHideLg?: boolean
  cardUpsellInline?: boolean
  cardUpsellSecureSite?: boolean
  cardUpsellName?: boolean
  cardUpsellPosition?: string
  cardUpsellRibbon?: string
  cardUpsellOfferCopy?: string
  cardUpsellProduct?: CompareCredit.FormattedCard
  exitModalCardDisplay?: boolean
  exitModalCardTitle?: string
  exitModalCardTitleBrightBlue?: string
  exitModalCardSubTitle?: string
  exitModalCardRatingsCount?: boolean
  exitModalCardProduct?: CompareCredit.FormattedCard
  exitModalContent?: { children: any | null }[]

  modalLeaveBehindCardDisplay?: boolean
  modalLeaveBehindCardProduct?: CompareCredit.FormattedCard
  modalLeaveBehindCardTitle?: string
  modalLeaveBehindCardContent?: { children: any | null }[]
  modalLeaveBehindCardDeclineText?: string

  hideBBBIcon?: boolean
  author?: CompareCredit.TipV2['author']
  reviewer?: CompareCredit.TipV2['author']
  largeText?: boolean
  location?: string
  tag?: CompareCredit.CategoryTag
  categoryTag?: CompareCredit.CategoryTag
  footerType?: string
  disclaimerAlignment?: 'left' | 'right' | 'top' | string
  customCSS?: { code: string }
  referencedCards: string[]
  exitModalMobileScrollPosition?: number
  exitModalMultiCardTipPosition?: number
}> = ({
  _updatedAt,
  modules,
  slug,
  nav,
  cardUpsellDisplay,
  cardUpsellHideLg,
  cardUpsellInline,
  cardUpsellSecureSite,
  cardUpsellName,
  cardUpsellPosition,
  cardUpsellRibbon,
  cardUpsellOfferCopy,
  cardUpsellProduct,
  exitModalCardDisplay,
  exitModalCardTitle,
  exitModalCardTitleBrightBlue,
  exitModalCardSubTitle,
  exitModalCardRatingsCount,
  exitModalCardProduct,
  exitModalContent,
  modalLeaveBehindCardDisplay,
  modalLeaveBehindCardProduct,
  modalLeaveBehindCardTitle,
  modalLeaveBehindCardContent,
  modalLeaveBehindCardDeclineText,
  hideBBBIcon,
  author,
  reviewer,
  largeText,
  location,
  tag,
  categoryTag,
  footerType,
  disclaimerAlignment,
  customCSS,
  referencedCards: referencedCardsProp,
  exitModalMobileScrollPosition,
  exitModalMultiCardTipPosition,
}) => {
  const cardOrderIds = useMemo(
    () =>
      referencedCardsProp.reduce((acc: Record<string, string>, val: string) => {
        const orderId = generateNewOrderId()
        acc[val] = orderId
        return acc
      }, {}),
    [],
  )

  const updatedDate = getDate(String(_updatedAt))

  const externalId = tag ? tag.id : '00'
  const categoryId = categoryTag ? categoryTag.id : '00'

  const sortParams = {
    categoryId: externalId,
  }

  const { cards, sort } = useFetchCardsSlug(
    referencedCardsProp,
    sortParams,
  ) as useFetchCardsSlugComplexReturn

  const referencedCards: null | Record<string, CompareCredit.Entity> = useMemo(
    () =>
      cards.length > 0
        ? cards.reduce((acc: Record<string, CompareCredit.Entity>, val) => {
            acc[val.slug] = val
            return acc
          }, {})
        : null,
    [cards.length, sort],
  )

  const { ref, inView } = useInView({
    triggerOnce: true,
  })

  const {
    setModalLeaveBehindId,
    setModalLeaveBehindSlug,
    setModalLeaveBehindOrderId,
    setIsModalLeaveBehindCard,
    setModalLeaveBehindTipSlug,
  } = useContext(ModalLeaveBehindContext)

  const isModalLeaveBehindCard =
    !!modalLeaveBehindCardDisplay &&
    modalLeaveBehindCardProduct &&
    !!modalLeaveBehindCardTitle &&
    modalLeaveBehindCardContent &&
    modalLeaveBehindCardContent?.length > 0

  useEffect(() => {
    if (isModalLeaveBehindCard && cardOrderIds) {
      setModalLeaveBehindId(createUuid())
      setModalLeaveBehindSlug(modalLeaveBehindCardProduct.slug)
      setModalLeaveBehindOrderId(cardOrderIds[modalLeaveBehindCardProduct.slug])
      setIsModalLeaveBehindCard(isModalLeaveBehindCard)
      setModalLeaveBehindTipSlug(slug.current)
    }
  }, [isModalLeaveBehindCard, cardOrderIds])

  return (
    <LayoutMain
      key={slug.current}
      navType={nav}
      hideBBBIcon={hideBBBIcon}
      mobileFooterTileHeight={0}
      footerType={footerType}
      disclaimerAlignment={disclaimerAlignment}
      isModalLeaveBehindCard={isModalLeaveBehindCard}
      modalLeaveBehindCard={
        isModalLeaveBehindCard &&
        modalLeaveBehindCardDisplay &&
        modalLeaveBehindCardProduct &&
        modalLeaveBehindCardTitle &&
        modalLeaveBehindCardContent &&
        referencedCards &&
        referencedCards[modalLeaveBehindCardProduct.slug] &&
        cardOrderIds
          ? ({
              modalLeaveBehindCardDisplay,
              modalLeaveBehindCardProduct:
                referencedCards[modalLeaveBehindCardProduct.slug],
              modalLeaveBehindCardTitle,
              modalLeaveBehindCardContent,
              modalLeaveBehindCardDeclineText,
              cardOrderIds: cardOrderIds,
              referencedCards: referencedCards,
            } as unknown as CompareCredit.ModalLeaveBehindCard)
          : undefined
      }
    >
      <>
        {disclaimerAlignment === 'top' ? (
          ''
        ) : (
          <div className="c-disclosure-wrap / mt-4">
            <>
              {disclaimerAlignment && (
                <AdvertiserDisclaimer
                  alignPopup={
                    disclaimerAlignment === 'left'
                      ? 't-disclosure-left'
                      : 't-disclosure-right'
                  }
                  disclosureTextAlign={
                    disclaimerAlignment === 'left' ? 'left' : 'right'
                  }
                />
              )}
            </>
          </div>
        )}
      </>

      <div
        className={cn(`c-text-style / text-gray-700`, {
          'c-text-style--lg text-lg leading-relaxed-2': largeText,
          'leading-relaxed': !largeText,
        })}
      >
        <ErrorBoundary>
          {/* <!-- Added this main div to helped in the side card tile component to be fixed on screen --> */}
          <div id="main" className="pb-12 lg:pb-16">
            {modules &&
              modules.map((module: any) => {
                const type = module._type
                switch (type) {
                  case 'grid':
                    return (
                      <Grid
                        key={module._key}
                        data={module}
                        updatedDate={updatedDate}
                        author={author}
                        reviewer={reviewer}
                        location={location}
                        tag={tag}
                        categoryTag={categoryTag}
                        cardOrderIds={cardOrderIds}
                        referencedCards={referencedCards}
                        exitModalRef={
                          exitModalMultiCardTipPosition ? ref : null
                        }
                        exitModalMultiCardTipPosition={
                          exitModalMultiCardTipPosition
                        }
                        sort={sort}
                      />
                    )
                  default:
                    return null
                }
              })}
          </div>
        </ErrorBoundary>

        {cardUpsellDisplay &&
          cardUpsellProduct &&
          referencedCards &&
          referencedCards[cardUpsellProduct.slug] &&
          cardOrderIds && (
            <CardNavV2
              cardUpsellHideLg={cardUpsellHideLg}
              cardUpsellInline={cardUpsellInline}
              cardUpsellPosition={cardUpsellPosition}
              cardUpsellSecureSite={cardUpsellSecureSite}
              cardUpsellName={cardUpsellName}
              cardUpsellRibbon={cardUpsellRibbon}
              cardUpsellOfferCopy={cardUpsellOfferCopy}
              cardUpsellProduct={
                referencedCards[
                  cardUpsellProduct.slug
                ] as CompareCredit.FormattedCard
              }
              orderId={cardOrderIds[cardUpsellProduct.slug]}
              categoryId={categoryId}
              externalId={externalId}
            />
          )}

        {!isModalLeaveBehindCard &&
          exitModalCardDisplay &&
          exitModalCardTitle &&
          exitModalCardProduct &&
          referencedCards &&
          referencedCards[exitModalCardProduct.slug] &&
          cardOrderIds && (
            <ExitIntent
              show={inView}
              triggerMobileScrollPosition={exitModalMobileScrollPosition}
            >
              <ExitModalCard
                exitModalCardTitle={exitModalCardTitle}
                exitModalCardTitleBrightBlue={exitModalCardTitleBrightBlue}
                exitModalCardSubTitle={exitModalCardSubTitle}
                exitModalCardRatingsCount={exitModalCardRatingsCount}
                exitModalContent={exitModalContent}
                exitModalCardProduct={
                  referencedCards[
                    exitModalCardProduct.slug
                  ] as CompareCredit.FormattedCard
                }
                orderId={cardOrderIds[exitModalCardProduct.slug]}
                categoryId={categoryId}
                externalId={externalId}
                cardOrderIds={cardOrderIds}
                referencedCards={referencedCards}
              />
            </ExitIntent>
          )}

        {customCSS && customCSS.code ? (
          <style
            dangerouslySetInnerHTML={{
              __html: `${customCSS.code}`,
            }}
          />
        ) : null}
      </div>
    </LayoutMain>
  )
}
