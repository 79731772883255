import { checkIfEmptyCardsList } from './checkEmptyCards'
import { fetchSorts } from './sort/client/fetch-sorts'
import getAllEntitiesSlugs from './get-all-entities-slugs'
import * as Sentry from '@sentry/nextjs'
import { SORT_DATA_MAP, SORT_MAP } from './sort/sort-map'
import { buildSortFetchData } from './sort/client/build-sort-fetch-data'
import { SORT_DATA } from './sort/types/experimentation-types'
import { SortParams, sortResponseInterface } from './sort/types'
import { CompareCredit } from '../../types'

export async function fetchEntities(slugs: string[]) {
  const entities = await getAllEntitiesSlugs(slugs)
  if (entities && !entities.error) {
    checkIfEmptyCardsList(entities.cards)
  }
  return entities
}

export async function handleFetchs(
  slugs: string[],
  sortData: SortParams,
  sortConfig: SORT_DATA | null,
) {
  const sortFetchData = buildSortFetchData(
    sortData,
    slugs,
    SORT_MAP,
    SORT_DATA_MAP,
    sortConfig,
  )

  return await Promise.allSettled([
    fetchEntities(slugs).catch((err) => {
      Sentry.captureException(err)
      return Promise.reject(err)
    }),
    fetchSorts(sortFetchData).catch((err) => {
      Sentry.captureException(err)
      return Promise.reject(err)
    }),
  ])
}

export function handleFetchResponses(
  entities: PromiseSettledResult<any>,
  sortedEntities: PromiseSettledResult<sortResponseInterface>,
) {
  let error = false
  let fetchedEntities = []
  if (sortedEntities.status === 'rejected' && entities.status === 'rejected') {
    // call to both sort and entities failed
    Sentry.captureException(new Error('sort and entities call failed'))
    error = true
  } else if (
    sortedEntities.status === 'rejected' &&
    entities.status === 'fulfilled'
  ) {
    // call to sort failed, but entities succeeded
    fetchedEntities = entities.value.cards
  } else if (
    sortedEntities.status === 'fulfilled' &&
    entities.status === 'rejected'
  ) {
    // call to sort suceeded but entities failed
    Sentry.captureException(
      new Error('entities call failed, but sort call succeeded'),
    )
    error = true
  } else {
    // this shouldnt execute, but just in case
    Sentry.captureException(
      new Error('fetch card error - this should never execute!'),
    )
    error = true
  }
  return { fetchError: error, fetchedEntities }
}

export interface useFetchCardsSlugSimpleReturn {
  cards: CompareCredit.Entity[]
  isFetching: boolean
  error: boolean
}
export interface useFetchCardsSlugComplexReturn {
  cards: CompareCredit.Entity[]
  isFetching: boolean
  error: boolean
  sort?: string
}
