import * as React from 'react'
import { useStatsigClient } from '@statsig/react-bindings'

import { CompareCredit } from '../../../../../types/compare-credit'
import { expandProductCardClicked } from '../../../../clients/segment'

export function CollapseDetails(props: {
  orderId: string
  entity: CompareCredit.Entity
  isCollapsed: boolean
  setCollapsed: (collapsed: boolean) => void
}) {
  const { entity, orderId } = props
  const { logEvent } = useStatsigClient()

  return (
    <button
      className={`py-3 md:py-2 w-full / text-center text-gray-500 text-sm font-bold / rounded-lg rounded-t-none / hover:text-primary-bright hover:bg-gray-100 / transition-all ${
        props.isCollapsed
          ? 'md:border-t border-gray-200'
          : 'border-t border-gray-200'
      }
      `}
      onClick={() => {
        props.setCollapsed(!props.isCollapsed)
        logEvent('entity_panel_details_toggle')
        if (props.isCollapsed) {
          expandProductCardClicked({
            order_id: orderId,
            sku: entity.slug,
            action: 'clicked',
          })
        }
      }}
      onKeyDown={() => {
        props.setCollapsed(!props.isCollapsed)
        logEvent('entity_panel_details_toggle')
      }}
      type="button"
      tabIndex={0}
      aria-expanded={!props.isCollapsed}
      aria-controls={`panel-collapse-${
        props.entity.slug
      } ${`card-highlight1-${props.entity.slug}`} ${`card-highlight2-${props.entity.slug}`} ${`card-highlight3-${props.entity.slug}`}`}
    >
      {props.isCollapsed ? 'Expand Details' : 'Show Less'}
      <span
        className={`c-icon-arrow / w-3 h-3 opacity-50 ml-2 ${
          props.isCollapsed ? '' : 'rotate-180'
        }`}
      />
    </button>
  )
}
