import Link from 'next/link'
import { useMemo } from 'react'

import { clickRatesAndFees } from 'clients/segment'
import { CardArt, StarRatingEditor } from 'components/'
import { supify } from 'utils/'
import { generateNewOrderId } from 'utils/orderId/generateNewOrderId'
import type { CompareCredit } from '../../../../types/compare-credit'

export const CardTileList: React.FC<{
  card: CompareCredit.FormattedCard
  categoryId: string
  externalId: string
}> = ({ card, categoryId, externalId }) => {
  const orderId = useMemo(() => generateNewOrderId(), [])

  return (
    <div
      className={`c-related-card-list / relative / flex flex-row items-start sm:items-center`}
    >
      <div className="c-card-art / shrink-0 / w-1/3 sm:w-1/4 md:w-1/5 / text-center flex items-center justify-center / mb-2 / pr-4 md:pr-6">
        <div className="relative / w-full / rounded-sm md:rounded shadow">
          <Link href={`/credit-cards/${card.issuer.slug.current}/${card.slug}`}>
            <div className="relative flex rounded-sm overflow-hidden">
              <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white">
                <span className="w-full font-bold text-sm">Learn More</span>
              </span>
              <CardArt
                _rev={card._rev}
                cardArt={card.cardArt}
                imgClasses="relative z-0 w-full h-full object-cover top-0"
                customCodeSnippets={card.customCodeSnippets}
                issuer={card.issuer.slug.current}
                name={card.name}
                orderId={orderId}
                slug={card.slug}
                categoryId={categoryId}
                externalId={externalId}
              />
            </div>
          </Link>
        </div>
      </div>

      <div className="c-related-card-list__inner / flex flex-col sm:flex-row / w-full">
        <div className="c-related-card-list__col-1 / w-full / sm:pr-4">
          <h4 className="c-related-card-list__name / mb-0.5 / font-bold leading-tight text-sm md:text-base">
            <Link
              href={`/credit-cards/${card.issuer.slug.current}/${card.slug}`}
              className="text-primary-mid hover:text-primary-bright"
              dangerouslySetInnerHTML={{
                __html: supify(card.name),
              }}
            />
          </h4>
          <div className="c-related-card-list__rating / flex justify-start mb-2">
            <StarRatingEditor
              productSlug={card.slug}
              stars={card.expertReviewRating}
            />
          </div>
        </div>

        <div className="c-related-card-list__col-2 / shrink-0 / w-48 sm:w-1/3">
          <Link
            href={`/credit-cards/${card.issuer.slug.current}/${card.slug}`}
            className="c-related-card-list__btn / c-btn c-btn--ghost / w-full / py-1.5 / focus:outline-none"
          >
            Learn More <span className="sr-only">about the {card.name}</span>
          </Link>
          {card.ratesAndFees && card.ratesAndFees !== 'N/A' && (
            <span className="c-related-card-list__rates-fees / block mt-0.5">
              <a
                className="inline-block / w-full / text-xs text-center text-primary-bright leading-snug / hover:underline hover:text-primary-mid"
                href={card.ratesAndFees}
                onClick={() => {
                  clickRatesAndFees({
                    applyNowLink: card.applyNowLink,
                    component: 'Card Tile List',
                    name: card.name,
                    url: window.location.href,
                  })
                }}
                rel="noopener noreferrer"
                target="_blank"
              >
                Rates &amp; Fees
              </a>
              <>
                {card.issuer.slug.current === 'american-express' && (
                  <p className="mb-1 text-fs10 text-gray-600 / text-center / w-full leading-4">
                    Terms Apply
                  </p>
                )}
              </>
            </span>
          )}
        </div>
      </div>
    </div>
  )
}
