import { useMemo } from 'react'
import Image, { type ImageProps } from 'next/image'
import { useApplyNowIcon } from 'utils/experimentation/useApplyNowIcon'
import { Except } from 'type-fest'

type ApplyNowIconProps = {
  specificLockIcon?: string
  specificRedirectIcon?: string
} & Except<ImageProps, 'src' | 'alt'>

export const ApplyNowIcon: React.FC<ApplyNowIconProps> = ({
  specificLockIcon,
  specificRedirectIcon,
  ...rest
}) => {
  /* ===== 2025.04: EXP-367 - CTA Button Icon Redirect ===== */
  const applyNowIcon = useApplyNowIcon()

  const { src, alt } = useMemo(() => {
    if (applyNowIcon === 'lock') {
      return {
        src: specificLockIcon
          ? `/static/icons/icon-${specificLockIcon}.svg`
          : `/static/icons/icon-lock-round-w.svg`,
        alt: 'Lock icon',
      }
    }

    if (applyNowIcon === 'redirect') {
      return {
        src: specificRedirectIcon
          ? `/static/icons/icon-${specificRedirectIcon}.svg`
          : `/static/icons/icon-redirect-w.svg`,
        alt: 'Redirect icon',
      }
    }

    return { src: '', alt: '' }
  }, [applyNowIcon, specificLockIcon, specificRedirectIcon])

  return <Image {...rest} src={src} alt={alt} />
}
