import { useMemo } from 'react'
import range from 'lodash/range'
import Link from 'next/link'
import {
  PortableTextBlockComponent,
  PortableTextListComponent,
  PortableText,
} from '@portabletext/react'

import { CardArt } from 'components/'
import { supify } from 'utils/'
import { generateNewOrderId } from 'utils/orderId/generateNewOrderId'
import type { CompareCredit } from '../../../types/compare-credit'

export const CardListCallout = ({
  referencedCards,
}: {
  referencedCards: null | Record<string, CompareCredit.Entity>
}) => {
  const CardListCalloutRender: React.FC<{
    value: {
      cardTips: CompareCredit.CardTip[]
      tag: CompareCredit.CategoryTag
      categoryTag: CompareCredit.CategoryTag
      title: string
      stack?: boolean
    }
  }> = ({ value: { cardTips, tag, categoryTag, title, stack } }) => {
    const externalId = tag ? tag.id : '00'
    const categoryId = categoryTag ? categoryTag.id : '00'

    const cards = cardTips.flatMap(({ card: { slug: cardSlug } }) => {
      const referencedCard = referencedCards?.[cardSlug]

      /**
       * @todo fix the legit type errors that this type assertion is masking
       */
      return referencedCard
        ? [referencedCard as CompareCredit.FormattedCard]
        : []
    })

    const cardTipsMapping = cardTips.reduce(
      (
        acc: Record<string, CompareCredit.CardTip>,
        val: CompareCredit.CardTip,
      ) => {
        acc[val.card.slug] = val
        return acc
      },
      {},
    )

    return (
      <section
        className={`c-card-list / flex flex-col items-center justify-center / mb-6 / pt-4 pb-1 px-4 sm:px-0 sm:pb-4 ${
          stack ? 'lg:pb-1 lg:px-4' : ''
        } / rounded bg-primary-light-bg-2`}
      >
        <p
          className={`c-card-list__title / mb-1 sm:mb-2 ${
            stack ? 'lg:mb-0' : ''
          } / text-center text-fs13 font-bold text-primary leading-tight`}
        >
          {title}
        </p>
        <div className="c-card-list__content / w-full">
          <ul
            className={`c-card-list__list / sm:flex sm:flex-row sm:flex-wrap sm:items-start sm:justify-center ${
              stack ? 'lg:flex-col' : ''
            }`}
          >
            {cards && cards.length > 0 ? (
              cards.map((card, index) => (
                <li
                  key={index}
                  className={`c-card-list__item / sm:w-1/3  / sm:px-2 ${
                    stack ? 'c-card-list__item--stack lg:w-full' : null
                  }
                ${
                  cards.length > 3 ? 'c-card-list__item-4 sm:mb-4 lg:w-1/4' : ''
                }
                `}
                >
                  <CardTile
                    categoryId={categoryId}
                    externalId={externalId}
                    card={card}
                    title={`${card.name}`}
                    description={cardTipsMapping[card.slug].description}
                    stack={stack}
                  />
                  <style jsx>{`
                    .c-card-list__item {
                      border-bottom: 1px solid rgb(226 232 240);
                    }
                    .c-card-list__item:last-child {
                      border-bottom: none;
                    }
                    @media (min-width: 640px) {
                      .c-card-list__item {
                        border-bottom: none;
                        border-right: 1px solid rgb(226 232 240);
                      }
                      .c-card-list__item:last-child {
                        border-right: none;
                      }
                    }
                    @media (min-width: 640px) and (max-width: 767px) {
                      .c-card-list__item-4:nth-of-type(3n + 3) {
                        border-right: none;
                      }
                    }
                    @media (min-width: 1024px) {
                      .c-card-list__item-4:nth-of-type(4n + 4) {
                        border-right: none;
                      }
                      .c-card-list__item--stack {
                        width: 100%;
                        border-bottom: 1px solid rgb(226 232 240);
                        border-right: none;
                      }
                    }
                  `}</style>
                </li>
              ))
            ) : (
              <CardSkeleton n={3} stack={stack} />
            )}
          </ul>
        </div>
      </section>
    )
  }
  return CardListCalloutRender
}

const CardSkeleton: React.FC<{ n: number; stack?: boolean }> = ({
  n,
  stack,
}) => {
  return (
    <li
      className={`sm:flex sm:flex-row / w-full ${
        stack ? 'lg:flex-col' : 'no-stack'
      }`}
    >
      {range(n).map((idx: number) => (
        <div
          key={idx}
          className={`sm:w-1/3 / mb-1  / sm:px-2 ${
            stack ? 'lg:w-full' : 'sm:mb-0'
          }`}
        >
          <div
            className={`flex flex-row items-center sm:flex-col sm:justify-center / w-full ${
              stack ? 'lg:flex-row lg:justify-start' : ''
            }`}
          >
            <div className="c-content-placeholder-bg / flex items-stretch / shrink-0 / w-20 md:w-24 / sm:mb-1 / rounded-sm overflow-hidden transition-all">
              <div className="c-content-placeholder-bg c-card-item__art / h-12 md:w-22"></div>
            </div>
            <div
              className={`flex flex-col items-start justify-center / w-full / pl-2 sm:pl-0 ${
                stack ? 'lg:pl-2' : ''
              }`}
            >
              <div className="c-content-placeholder-bg c-tile__title / w-full h-3 / mb-1"></div>
              <div className="c-content-placeholder-bg c-tile__title / w-full h-5"></div>
            </div>
          </div>
        </div>
      ))}
      <style jsx>{`
        .c-card-item__art {
          width: 95%;
        }
        .c-content-placeholder-bg {
          position: relative;
          overflow: hidden;
        }
        .c-content-placeholder-bg:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          display: block;
          width: 70%;
          height: 100%;
          will-change: transform;
          background: -moz-linear-gradient(
            left,
            rgba(26, 32, 44, 0) 0%,
            rgba(26, 32, 44, 1) 50%,
            rgba(255, 255, 255, 0.3) 85%,
            rgba(255, 255, 255, 0) 100%
          );
          background: -webkit-linear-gradient(
            left,
            rgba(26, 32, 44, 0) 0%,
            rgba(26, 32, 44, 1) 50%,
            rgba(255, 255, 255, 0.3) 85%,
            rgba(255, 255, 255, 0) 100%
          );
          background: linear-gradient(
            to right,
            rgba(26, 32, 44, 0) 0%,
            rgba(26, 32, 44, 1) 50%,
            rgba(255, 255, 255, 0.3) 85%,
            rgba(255, 255, 255, 0) 100%
          );
          filter: unquote(
            "progid:DXImageTransform.Microsoft.gradient( startColorstr='#001a202c', endColorstr='#00ffffff',GradientType=1 )"
          );
          opacity: 0.04;
          animation: content-placeholder-animation 1.35s linear infinite;
        }
        @keyframes content-placeholder-animation {
          0% {
            transform: translateX(-80%);
          }
          100% {
            transform: translateX(200%);
          }
        }
      `}</style>
    </li>
  )
}

const Normal: PortableTextBlockComponent = ({ children }) => (
  <span>{children}</span>
)

const Bullet: PortableTextListComponent = ({ children }) => (
  <ul className="c-card-item__list / mb-1 pl-5 / list-disc">{children}</ul>
)

const getAttributeRenderer = (card: CompareCredit.FormattedCard) => {
  const AttributeRenderer: React.FC<{
    value: { attribute: string; bonusRewards: string }
  }> = ({ value: { attribute, bonusRewards } }) => {
    const attributeToRender =
      attribute === 'bonusRewards' ? bonusRewards : card[attribute]
    return <span> {attributeToRender}</span>
  }
  return AttributeRenderer
}

const CardTile: React.FC<{
  card: CompareCredit.FormattedCard
  title: string
  description: any
  categoryId: string
  externalId: string
  stack?: boolean
}> = ({ card, title, description, categoryId, externalId, stack }) => {
  const orderId = useMemo(() => generateNewOrderId(), [])

  return (
    <div className="c-card-item__container / flex items-stretch / w-full">
      <Link
        href={`/credit-cards/${card.issuer.slug.current}/${card.slug}`}
        className={`c-card-item / flex flex-row justify-between items-stretch / w-full / mx-auto / py-2.5 sm:py-0 ${
          stack ? 'lg:py-2.5' : ''
        }`}
      >
        <div
          className={`c-card-item__content / flex flex-row items-center sm:flex-col sm:justify-center / w-full ${
            stack ? 'lg:flex-row lg:justify-start' : ''
          }`}
        >
          <div className="c-card-item__art-wrap / flex items-stretch / shrink-0 / w-20 / sm:mb-1 / rounded-sm overflow-hidden transition-all">
            <div className="flex items-center justify-center / w-full">
              <span className="c-card-item__art transition-all / rounded-sm overflow-hidden">
                <CardArt
                  _rev={card._rev}
                  cardArt={card.cardArt}
                  customCodeSnippets={card.customCodeSnippets}
                  issuer={card.issuer.slug.current}
                  name={card.name}
                  slug={card.slug}
                  categoryId={categoryId}
                  externalId={externalId}
                  orderId={orderId}
                />
              </span>
            </div>
          </div>
          <div
            className={`c-card-item__content / flex flex-col items-start justify-center / pl-2 sm:pl-0 ${
              stack ? 'lg:pl-2' : ''
            }`}
          >
            <h3
              className={`c-card-item__title / w-full / sm:mb-0.5 / text-fs13 text-primary leading-tight sm:text-center / transition-all ${
                stack ? 'lg:text-left' : ''
              }`}
              dangerouslySetInnerHTML={{
                __html: supify(`${title}`),
              }}
            />
            <div
              className={`c-card-item__description / w-full / text-fs13 text-secondary-dark leading-snug sm:text-center ${
                stack ? 'lg:text-left' : ''
              }`}
            >
              <PortableText
                value={description}
                components={{
                  block: { normal: Normal },
                  list: { bullet: Bullet },
                  types: { advertorialAttribute: getAttributeRenderer(card) },
                }}
              />
            </div>
          </div>
        </div>
      </Link>
      <style jsx>{`
        .c-card-item__art {
          width: 95%;
        }
        .c-card-item:hover .c-card-item__art {
          width: 100%;
        }
      `}</style>
    </div>
  )
}
