import * as React from 'react'
import propOr from 'lodash/fp/propOr'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { isMobileOnly } from 'react-device-detect'
import { useStatsigClient } from '@statsig/react-bindings'

import { CompareCredit } from '../../../../types/compare-credit'
import { expandProductCardClicked } from '../../../clients/segment'
import { supify } from '../../../utils'
import { ProsAndCons } from '../../proscons'
import { ProductDetails } from './components/product-details'
import cn from 'utils/classnames'

const MoreInfoBar = ({
  name,
  href,
  handleProductClicked,
  isExternalHref,
  slug,
}: {
  name: string
  href: string
  handleProductClicked?: () => void
  isExternalHref?: boolean
  slug?: string
}) => {
  const router = useRouter()

  const handleClick = (e: any) => {
    e.preventDefault()
    if (isMobileOnly) {
      router.push(href)
    } else {
      window.open(href, '_ blank')
    }
  }

  return (
    <div className="md:flex md:items-center sm:justify-between / text-sm / mb-8 md:mb-4 lg:mb-0 / text-card-gray / border-t border-gray-400 / pt-8">
      <div className="mb-2 text-center / md:mb-0 md:text-left md:pr-16">
        <p>
          Review additional details for{` `}
          {isExternalHref ? (
            <a
              href={href}
              className={`c-card-title / text-primary-bright font-semibold / hover:text-primary-mid hover:underline / js-card-title / trk_card-detail_more-link trk_card-detail_more-link_${slug}`}
              dangerouslySetInnerHTML={{ __html: supify(name) }}
              onClick={(e) => {
                handleClick(e)
                if (handleProductClicked) handleProductClicked()
              }}
            />
          ) : (
            <Link
              href={href}
              className={`c-card-title / text-primary-bright font-semibold / hover:text-primary-mid hover:underline / js-card-title / trk_card-detail_more-link trk_card-detail_more-link_${slug}`}
              dangerouslySetInnerHTML={{ __html: supify(name) }}
              onClick={(e) => {
                handleClick(e)
                if (handleProductClicked) handleProductClicked()
              }}
            />
          )}
        </p>
      </div>
      <div className="w-56 lg:w-48 xl:w-56 / mx-auto md:mr-0">
        {isExternalHref ? (
          <a
            onClick={(e) => {
              handleClick(e)
              if (handleProductClicked) handleProductClicked()
            }}
            className={`c-btn c-btn--ghost-gray / trk_card-detail_more-btn trk_card-detail_more-btn_${slug}`}
            href={href}
            rel="noreferrer"
          >
            More Info
          </a>
        ) : (
          <Link
            href={href}
            className={`c-btn c-btn--ghost-gray / trk_card-detail_more-btn trk_card-detail_more-btn_${slug}`}
            onClick={(e) => {
              handleClick(e)
              if (handleProductClicked) handleProductClicked()
            }}
          >
            More Info
          </Link>
        )}
      </div>
    </div>
  )
}

export function BottomSection(props: {
  entity: CompareCredit.Entity
  isCollapsed?: boolean
  setCollapsed?: (ag1: boolean) => void
  view: 'list' | 'details' | 'modal'
  handleProductClicked?: () => void
  orderId: string
}) {
  const { entity, orderId } = props
  const isModal = props.view === 'modal'
  const isDetail = props.view === 'details'
  const isList = props.view === 'list'

  // Tab details and pros/cons
  const TAB_1 = 'productDetails'
  const TAB_2 = 'prosAndCons'

  const [currentTab, setCurrentTab] = React.useState(TAB_1)
  const { logEvent } = useStatsigClient()

  const getTabColor = (tab: string) =>
    tab === currentTab
      ? `text-primary-mid font-bold border-primary-mid border-b-2 ${
          props.isCollapsed && 'cursor-pointer'
        }`
      : 'text-gray-600 font-bold hover:border-primary-mid cursor-pointer'
  const TabItem = (tabProps: {
    text: string
    tab: string
    isProsAndCons?: boolean
  }) => (
    <li
      className={`inline-block ${
        tabProps.isProsAndCons ? 'pros-and-cons' : ''
      }`}
    >
      <button
        className={cn(
          'inline-block / mr-6 / hover:text-primary-mid pb-2 / focus:outline-none',
          getTabColor(tabProps.tab),
        )}
        onClick={() => {
          if (props.isCollapsed && props.setCollapsed) {
            props.setCollapsed(false)
            setCurrentTab(tabProps.tab)
            logEvent('entity_panel_details_toggle')
            expandProductCardClicked({
              order_id: orderId,
              sku: entity.slug,
              action: 'clicked',
            })
          } else {
            if (tabProps.tab !== currentTab) {
              setCurrentTab(tabProps.tab)
            }
          }
          if (tabProps.isProsAndCons) {
            logEvent('entity_panel_pros_cons_tab_toggle')
          } else {
            logEvent('entity_panel_product_details_tab_toggle')
          }
        }}
      >
        {tabProps.text}
      </button>
    </li>
  )

  return (
    <div
      id={`panel-collapse-${props.entity.slug}`}
      className={`${props.view === 'list' && 'md:w-full'}`}
    >
      <div
        className={cn('c-product-details / relative', {
          'c-product-details--teaser-open / pt-7 md:pt-0 lg:pb-8':
            !props.isCollapsed,
          'c-product-details--teaser-collapse': props.isCollapsed,
        })}
      >
        <div
          className={cn('c-panel text-card-gray', {
            'mb-2 lg:mb-0': props.view !== 'list',
            'mb-4': isModal,
          })}
        >
          <div
            className={cn('c-panel__tabs', {
              hidden: isDetail || (isModal && !isMobileOnly),
            })}
          >
            <ul className="flex font-bold text-sm / border-gray-400 border-b">
              <TabItem text="Product Details" tab={TAB_1} />
              <TabItem text="Pros &amp; Cons" isProsAndCons tab={TAB_2} />
            </ul>
          </div>

          <div
            className={cn({
              'w-full md:inline-block': isDetail,
              hidden: (isModal && isMobileOnly) || isList,
              'w-full md:inline-block lg:pb-3': isModal && !isMobileOnly,
            })}
          >
            <p className="font-bold w-full text-primary text-sm / pb-2">
              Product Details
            </p>
            <ProductDetails
              orderId={orderId}
              entity={entity}
              splitIt={props.view === 'details'}
              view={props.view}
            />
          </div>
          <div
            className={cn('c-panel__content / -mt-px', {
              'pt-4 pb-3': props.view === 'list' || (isModal && isMobileOnly),
              'hidden pt-6 md:pt-0 md:flex':
                isDetail || (isModal && !isMobileOnly && !isDetail),
            })}
          >
            <div
              className={cn({
                'md:hidden': isDetail || (isModal && !isMobileOnly),
              })}
            >
              {currentTab === 'productDetails' && (
                <ProductDetails
                  orderId={orderId}
                  entity={entity}
                  splitIt={props.view === 'details'}
                  view={props.view}
                />
              )}
            </div>
            <div
              className={cn({
                'md:hidden': isDetail || (isModal && !isMobileOnly),
              })}
            >
              {currentTab == 'prosAndCons' && props.entity._type == 'card' && (
                <ProsAndCons card={entity as CompareCredit.FormattedCard} />
              )}
            </div>
          </div>
        </div>

        {(props.view === 'list' || props.view === 'modal') && (
          <>
            {props.entity._type === 'card' ||
            props.entity._type === 'nonPaidCard' ? (
              <MoreInfoBar
                href={`/credit-cards/${props.entity.issuer.slug.current}/${props.entity.slug}`}
                name={props.entity.name}
                slug={props.entity.slug}
              />
            ) : (
              <MoreInfoBar
                href={propOr('#', 'getOffersLink', entity)}
                name={props.entity.name}
                handleProductClicked={props.handleProductClicked}
                isExternalHref
              />
            )}
          </>
        )}
      </div>

      <style jsx>{`
        .c-collapse-list__button:hover .c-collapse-list__icon {
          opacity: 1;
        }
      `}</style>
    </div>
  )
}
