import {
  SORT_DATA_ITEMS,
  SORT_DATA_VERSIONS,
  SORT_MAP as SORTS_MAP,
} from './types'

const DEFAULT: SORTS_MAP['default'] = {
  sort: ['athena-v1', '1'],
  data: '18',
}

export const SORT_MAP: SORTS_MAP = Object.freeze({
  default: DEFAULT,
  'random-sort': {
    sort: ['random-sort', '1'],
    data: '1',
  },
  'athena-v1': DEFAULT,
  'athena-v1-test': {
    sort: ['athena-v1-test', '1'],
    data: '19',
  },
  'athena-v2': {
    sort: ['athena-v2', '1'],
    data: '20',
  },
})

export const SORT_DATA_MAP: Record<SORT_DATA_VERSIONS, SORT_DATA_ITEMS[]> =
  Object.freeze({
    '1': ['categoryId', 'slugs'],
    '2': ['categoryId', 'page_path', 'slugs', 'proximity_to_target_adjustment'],
    '18': [
      // This is what we use for AthenaV1
      'sku',
      'slugs',
      'brand',
      'categoryId',
      'page_path',
      'campaign_medium',
      'campaign_source',
      'campaign_name',
      'user_agent_os_name',
      'user_agent_browser_name',
      'location_state',
    ],
    '19': [
      // This is what we use for AthenaV1Test
      'sku',
      'slugs',
      'brand',
      'categoryId',
      'page_path',
      'campaign_medium',
      'campaign_source',
      'campaign_name',
      'user_agent_os_name',
      'user_agent_browser_name',
      'location_state',
    ],
    '20': [
      'sku',
      'slugs',
      'brand',
      'categoryId',
      'page_path',
      'campaign_medium',
      'campaign_source',
      'campaign_name',
      'user_agent_os_name',
      'user_agent_browser_name',
    ],
  })
