import React from 'react'
import { CompareCredit } from '../../../../types/compare-credit'
import { CardArt, LinkToRedirect } from '../../../components'
import { supify, nextImageHelper } from '../../../utils'
import { clickRatesAndFees } from '../../../clients/segment'
import Link from 'next/link'
import { ApplyNowIcon } from 'components/link-to-redirect/ApplyNowIcon'

export function CardNav(props: {
  card: CompareCredit.FormattedCard | CompareCredit.NonPaidCard
  categoryId?: string
  externalId?: string
  orderId: string
}) {
  const { categoryId = '00', externalId = '00', orderId } = props
  const [cardNavIsSticky, setCardNavIsSticky] = React.useState(false)
  const [browserSize, setBrowserSize] = React.useState({
    width: 0,
  })
  const [card, setCard] = React.useState(props.card)

  React.useEffect(() => {
    setCard(props.card)
  }, [props.card])

  // Display card nav on scroll
  React.useEffect(() => {
    let pgOffset = 250

    // Capture window width/height on resize
    const handleResize = () =>
      setBrowserSize({
        width: window.innerWidth,
      })
    window.addEventListener('resize', handleResize)

    // Reset pgOffset cutoff
    if (browserSize.width > 767) {
      pgOffset = 580
    }

    const handleCardNavStick = () =>
      setCardNavIsSticky(window.pageYOffset > pgOffset)
    window.addEventListener('scroll', handleCardNavStick)

    return () => {
      window.removeEventListener('scroll', handleCardNavStick)
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const isCard = card._type === 'card'

  return (
    <div
      className={`c-card-sticky / fixed left-0 z-70 / flex items-center w-full / bg-white / transition-all--25 shadow-lg ${
        cardNavIsSticky ? `is-shown` : ''
      }`}
    >
      <div className="container-fluid py-2 xs:py-3 / md:flex md:items-center md:justify-center">
        <div className="flex items-center justify-center / md:max-w-6xl">
          <div className="flex pr-2 md:pr-3">
            <div className="c-card-art w-14 xs:w-20 mx-auto / text-center flex items-center justify-center">
              <div className="w-full relative / rounded-sm shadow">
                {isCard ? (
                  <LinkToRedirect
                    externalId={externalId}
                    linkParams={card.applyNowLinkParameters}
                    slug={card.slug}
                    orderId={orderId}
                  >
                    <div className="relative flex rounded-sm overflow-hidden">
                      <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white">
                        <span className="w-full font-semibold text-xs">
                          Apply <br />
                          Now
                        </span>
                      </span>
                      <CardArt
                        cardArt={card.cardArt}
                        categoryId={categoryId || null}
                        externalId={externalId || null}
                        imgClasses="relative z-0 w-full h-full object-cover top-0"
                        name={card.name}
                        preventImpressionEvent
                        _rev={card._rev}
                        orderId={orderId}
                      />
                    </div>
                  </LinkToRedirect>
                ) : (
                  <Link href={card.moreInfoLink}>
                    <div className="relative flex rounded-sm overflow-hidden">
                      <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white">
                        <span className="w-full font-semibold text-xs">
                          Learn <br />
                          More
                        </span>
                      </span>
                      <CardArt
                        cardArt={card.cardArt}
                        categoryId={categoryId || null}
                        externalId={externalId || null}
                        imgClasses="relative z-0 w-full h-full object-cover top-0"
                        name={card.name}
                        preventImpressionEvent
                        _rev={card._rev}
                        orderId={orderId}
                      />
                    </div>
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col pl-2 sm:pr-2 md:px-3">
            <div className="mb-1 / text-fs13 xxs:text-sm xs:text-base sm:text-lg font-sans font-semibold text-left leading-tight">
              {isCard ? (
                <LinkToRedirect
                  externalId={externalId}
                  linkParams={card.applyNowLinkParameters}
                  slug={card.slug}
                  orderId={orderId}
                >
                  <div
                    className="c-card-title / text-primary-mid hover:text-primary-bright cursor-pointer / transition-all"
                    dangerouslySetInnerHTML={{ __html: supify(card.name) }}
                  />
                </LinkToRedirect>
              ) : (
                <Link href={card.moreInfoLink}>
                  <div
                    className="c-card-title / text-primary-mid hover:text-primary-bright cursor-pointer / transition-all"
                    dangerouslySetInnerHTML={{ __html: supify(card.name) }}
                  />
                </Link>
              )}
            </div>
          </div>
          <div className="flex flex-col shrink-0 pl-2 md:pl-3">
            {isCard ? (
              <LinkToRedirect
                externalId={externalId}
                linkParams={card.applyNowLinkParameters}
                slug={card.slug}
                orderId={orderId}
              >
                <div className="flex flex-row items-center justify-center w-24 xxs:w-28 xs:w-32 md:w-auto sm:w-40 / px-1 py-1 sm:px-5 md:px-6 rounded-sm / border border-secondary font-semibold text-white text-fs13 xs:text-sm sm:text-base / bg-secondary hover:bg-secondary-dark">
                  Apply Now
                  <span className="ml-1 xs:ml-2">
                    <span className="w-3 h-3 sm:w-4 sm:h-4 / hidden xxs:inline-block">
                      <ApplyNowIcon
                        height={20}
                        width={20}
                        style={nextImageHelper.responsive}
                        sizes="100vw"
                      />
                    </span>
                  </span>
                </div>
              </LinkToRedirect>
            ) : (
              <Link href={card.moreInfoLink}>
                <div className="c-btn c-btn--secondary / flex flex-row items-center justify-center w-24 xxs:w-28 xs:w-32 md:w-auto sm:w-40 / px-1 py-1 sm:px-5 md:px-6 rounded-sm / border border-secondary font-semibold text-white text-fs13 xs:text-sm sm:text-base">
                  Learn More
                </div>
              </Link>
            )}
            {card.ratesAndFees && card.ratesAndFees !== 'N/A' && (
              <div className="w-full / text-center text-shadow leading-tight">
                <p>
                  <a
                    className="inline-block / w-full / text-fs11 text-center text-primary-bright leading-snug / hover:underline hover:text-primary-mid"
                    href={card.ratesAndFees}
                    onClick={() => {
                      isCard &&
                        clickRatesAndFees({
                          applyNowLink: card.applyNowLink,
                          component: 'Card Navigation',
                          name: card.name,
                          url: window.location.href,
                        })
                    }}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Rates &amp; Fees
                  </a>
                </p>
                {card.issuer.slug.current === 'american-express' && (
                  <p className="text-fs10 text-gray-600 / text-center">
                    Terms Apply
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <style jsx>{`
        .c-card-sticky {
          top: -7rem;
          min-height: 4rem;
          opacity: 0;
        }
        .c-card-sticky.is-shown {
          opacity: 1;
          transform: translateY(7rem);
        }
        @media (min-width: 1024px) {
          .c-card-sticky {
            min-height: 5rem;
          }
        }
      `}</style>
    </div>
  )
}
