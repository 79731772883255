import {
  PortableText,
  PortableTextBlockComponent,
  PortableTextListComponent,
  PortableTextListItemComponent,
} from '@portabletext/react'
import Cookies from 'js-cookie'
import Image from 'next/image'
import Link from 'next/link'
import {
  createElement,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useInView } from 'react-intersection-observer'

import { clickRatesAndFees, makeImpression } from 'clients/segment'
import {
  CallToApply,
  CardArt,
  CardRecommendedCredit,
  LinkToRedirect,
  StarRatingEditor,
  UserContext,
} from 'components/'
import { SecureSite } from 'components/secure-site'
import { getExperiment, nextImageHelper, supify } from 'utils/'
import { generateNewOrderId } from 'utils/orderId/generateNewOrderId'
import { getSubstituteOrderId } from 'utils/orderId/getSubstituteOrderId'
import useNetworkState from 'utils/use-network-status'
import type { CompareCredit } from '../../../types/compare-credit'
import { ApplyNowIcon } from '../link-to-redirect/ApplyNowIcon'

const List: PortableTextListComponent = ({ children }) => (
  <ul className="c-list-disc c-list-disc--blue / pl-5 py-1 text-sm">
    {children}
  </ul>
)

const ListItemDisplay: React.FC<React.PropsWithChildren> = ({ children }) => (
  <li className="c-list-disc__item / mb-2">{children}</li>
)

const ListItem: PortableTextListItemComponent = ({ children }) => (
  <ListItemDisplay>{children}</ListItemDisplay>
)

const getCardListRenderer = ({
  card,
  externalId,
  orderId,
}: {
  card: CompareCredit.FormattedCard
  externalId: string
  orderId: string
}) => {
  const CardListRenderer: PortableTextListComponent = ({ children }) => (
    <ul className="c-list-disc c-list-disc--blue / pl-2.5 py-1 / text-sm">
      <ListItemDisplay>
        <>
          Select{' '}
          <LinkToRedirect
            externalId={externalId}
            linkParams={card.applyNowLinkParameters}
            orderId={orderId}
            slug={card.slug}
          >
            <div className="text-primary-bright font-semibold / hover:text-primary-mid hover:underline">
              &quot;APPLY NOW&quot;
            </div>
          </LinkToRedirect>{' '}
          to apply online
          {card.phoneNumber && <CallToApply entity={card} numOnly />}
        </>
      </ListItemDisplay>
      {children}
      <ListItemDisplay>
        <>
          <Link
            href={`/credit-cards/${card.issuer.slug.current}/${card.slug}`}
            className="text-primary-bright font-semibold hover:text-primary-mid"
          >
            More Details
          </Link>
        </>
      </ListItemDisplay>
    </ul>
  )
  return CardListRenderer
}

const CardListItemRenderer: PortableTextListItemComponent = ({ children }) => (
  <li className="c-list-disc__item / mb-2">{children}</li>
)

const getAttributeRenderer = (card: CompareCredit.FormattedCard) => {
  const AttributeRenderer: React.FC<{ value: { attribute: string } }> = ({
    value: { attribute },
  }) => {
    return createElement('span', {}, card[attribute])
  }
  return AttributeRenderer
}

const Card: React.FC<{
  card: CompareCredit.FormattedCard
  orderId: string
  externalId: string
  categoryId: string
  networkInfo: ReturnType<typeof useNetworkState>
  addlInfoIsCollapsed: boolean
}> = ({
  card,
  orderId: possibleOrderId,
  externalId,
  categoryId,
  networkInfo,
  addlInfoIsCollapsed,
}) => {
  const { clientLocation, entryQuery, gaSessionInfo } = useContext(UserContext)

  const orderId = useMemo(
    () => possibleOrderId || getSubstituteOrderId(),
    [possibleOrderId],
  )

  const { ref, inView } = useInView({
    triggerOnce: true,
  })

  const CardListRenderer = useMemo(
    () => getCardListRenderer({ card, externalId, orderId }),
    [card, externalId, orderId],
  )

  useEffect(() => {
    // TODO: do we even want to send an impression event here? We already send one for the
    // card art further up the page. Will need a larger refactor though to achieve.

    const experiment = getExperiment()
    const ga_client_id = Cookies.get('_ga') || null

    if (inView)
      makeImpression({
        ga_client_id,
        ga_session_id: gaSessionInfo.ga_session_id,
        ga_session_number: gaSessionInfo.ga_session_number,
        arrangementId: null,
        entryQuery,
        experiment,
        latency: null,
        location: clientLocation,
        params: null,
        userModel: null,
        category: 'credit-card',
        order_id: orderId,
        brand: card.issuer.slug.current,
        id: card.slug,
        name: card.name,
        network: networkInfo,
        product_id: card.slug,
        sku: card.slug,
        categoryId: categoryId,
        _rev: card._rev,
      })
  }, [inView])

  return (
    <div
      key={card.slug}
      className={`text-sm text-gray-700 text-left ${
        addlInfoIsCollapsed && 'hidden'
      }`}
      ref={ref}
    >
      <PortableText
        value={card.marketingBullets}
        components={{
          list: CardListRenderer,
          listItem: CardListItemRenderer,
          types: {
            cardAttribute: getAttributeRenderer(card),
          },
        }}
      />
    </div>
  )
}

export const CardComparison = ({
  cardOrderIds,
  referencedCards,
}: {
  cardOrderIds: Record<string, string>
  referencedCards: null | Record<string, CompareCredit.Entities>
}) => {
  const CardComparisonInner: React.FC<{
    value: {
      cardTips: CompareCredit.CardTip[]
      tag: CompareCredit.CategoryTag
      categoryTag: CompareCredit.CategoryTag
      title: string
      subtitle: string
      pinnedCardTips?: CompareCredit.PinnedProduct[]
    }
  }> = ({ value: { cardTips, tag, categoryTag, title, subtitle } }) => {
    const externalId = tag ? tag.id : '00'
    const categoryId = categoryTag ? categoryTag.id : '00'

    // 1. CARDS
    const cardsWithOrderIds = useMemo(
      () =>
        cardTips.flatMap(({ card: { slug: cardSlug } }) => {
          const referencedCard = referencedCards?.[cardSlug]

          const orderId = cardOrderIds[cardSlug] || getSubstituteOrderId()

          return referencedCard
            ? [
                {
                  /**
                   * @todo fix the legit type errors this type assertion is masking
                   */
                  card: referencedCard as CompareCredit.FormattedCard,
                  orderId,
                },
              ]
            : []
        }),
      [cardTips, referencedCards],
    )

    // 2. RIBBON TEXT AND COLOR
    const cardRibbonText = cardTips.map((cardTip) => cardTip.ribbonText)
    const cardRibbonColor = cardTips.map((cardTip) => cardTip.ribbonColor)

    // 3. COLLAPSE SECTIONS
    const [prosConsIsCollapsed, setProsConsIsCollapsed] = useState(false)
    const [signUpOffersIsCollapsed, setSignUpOffersIsCollapsed] =
      useState(false)
    const [ongoingInfoIsCollapsed, setOngoingInfoIsCollapsed] = useState(false)
    const [cardFeesIsCollapsed, setCardFeesIsCollapsed] = useState(false)
    const [addlInfoIsCollapsed, setAddlInfoIsCollapsed] = useState(false)
    const handleCollapse = (
      state: boolean,
      setter: React.Dispatch<React.SetStateAction<boolean>>,
    ) => setter(!state)

    const networkInfo = useNetworkState()

    // 5. EXPAND TABLE
    const [comparisonExpand, setComparisonExpand] = useState(false)
    const handleComparisonExpand = () => setComparisonExpand(!comparisonExpand)

    // 7. NAV SCROLL
    const scrollPos = useRef<HTMLDivElement>(null)
    const [leftPos, setLeftPos] = useState<number | undefined>()
    const compareRowMainHandleScroll = () => {
      if (scrollPos && cardTips.length > 2) {
        const position = scrollPos?.current?.getBoundingClientRect().left
        setLeftPos(position)
      }
    }

    // NAV SCROLL TRIGGER
    const [displayNav, setDisplayNav] = useState<boolean | null>(false)
    const [displayNavPosition, setDisplayNavPosition] = useState(0)
    const ref = useRef<HTMLDivElement>(null)

    if (typeof window !== 'undefined') {
      useEffect(() => {
        const handleScroll = () => {
          const position = window.pageYOffset

          const elementTrigger = document.getElementById('scrollTrigger')

          if (elementTrigger) {
            const refPosition = elementTrigger.offsetTop + 500
            setDisplayNavPosition(refPosition)
          }

          if (position > displayNavPosition && comparisonExpand) {
            setDisplayNav(true)
          } else {
            setDisplayNav(false)
          }
        }
        window.addEventListener('scroll', handleScroll)
        return () => {
          window.removeEventListener('scroll', handleScroll)
        }
      }, [window.pageYOffset])
    }

    return (
      <div className="-mx-4 xl:mx-0">
        <div className="c-compare__main-container / mb-6">
          <div className="flex flex-col justify-center / px-4 xs:px-0">
            <h3
              className={`c-compare__title / text-center font-serif text-2xl md:text-3xl font-bold text-primary leading-tight ${
                subtitle ? 'mb-0.5' : 'mb-3'
              }`}
            >
              {title}
            </h3>
            {subtitle && (
              <p className="c-compare__sub-title / mt-2 mb-3 / leading-snug text-center text-base md:text-lg">
                {subtitle}
              </p>
            )}
            <p className="w-full sm:order-1 font-bold text-sm text-gray-700 text-center / sm:hidden">
              Viewing {cardsWithOrderIds.length} card
              {cardsWithOrderIds.length > 1 ? 's' : ''}
              {cardsWithOrderIds.length > 2 ? (
                <span className="inline-block / ml-1.5 / font-normal text-xs text-gray-600 tracking-wider uppercase">
                  (Scroll right to view)
                </span>
              ) : (
                ''
              )}
            </p>
          </div>

          <section
            className={`c-compare / relative z-1 / ${
              cardsWithOrderIds.length === 1
                ? 't-compare-1-col'
                : cardsWithOrderIds.length === 2
                ? 't-compare-2-col'
                : cardsWithOrderIds.length === 3
                ? 't-compare-3-col'
                : 't-compare-4-col'
            } `}
          >
            {cardsWithOrderIds && (
              <div className={`c-compare__container`}>
                <div
                  id="compare-inner"
                  className={`c-compare__inner / leading-relaxed ${
                    comparisonExpand
                      ? 'c-compare__inner--open'
                      : 'c-compare__inner--collapse'
                  }`}
                  onScroll={compareRowMainHandleScroll}
                >
                  {/* HEADER (card art, card name, apply now button) */}
                  <div
                    id="compare-row-main"
                    className="c-compare__row z-20 / flex / w-full / mb-4 sm:mb-6 / border-t-3 border-primary-mid shadow-lg"
                    ref={scrollPos}
                  >
                    {cardsWithOrderIds &&
                      cardsWithOrderIds.length > 0 &&
                      cardsWithOrderIds.map(({ card }, index) => (
                        <CompareCardTile
                          key={index}
                          card={card}
                          categoryId={categoryId}
                          externalId={externalId}
                          ribbonText={cardRibbonText[index]}
                          ribbonColor={cardRibbonColor[index]}
                        />
                      ))}
                  </div>

                  {/* HIGHLIGHTS / SIGN UP OFFERS */}
                  <div className="c-compare__row / mb-4">
                    <div className="absolute left-0 / w-full">
                      <button
                        className={`c-compare__collapse-btn / h-10 / pl-8`}
                        onClick={() =>
                          handleCollapse(
                            signUpOffersIsCollapsed,
                            setSignUpOffersIsCollapsed,
                          )
                        }
                        aria-expanded={
                          signUpOffersIsCollapsed ? 'false' : 'true'
                        }
                        aria-controls="section-highlights"
                      >
                        Highlights
                        <span className="absolute left-0 mt-1 ml-2">
                          <span className={`inline-block w-4 h-4`}>
                            {signUpOffersIsCollapsed ? (
                              <Image
                                alt="expand"
                                src="/static/icons/icon-plus-primary.svg"
                                height={16}
                                width={16}
                                aria-hidden={true}
                                style={nextImageHelper.responsive}
                                sizes="100vw"
                              />
                            ) : (
                              <Image
                                alt="hide"
                                src="/static/icons/icon-minus-primary.svg"
                                height={16}
                                width={16}
                                aria-hidden={true}
                                style={nextImageHelper.responsive}
                                sizes="100vw"
                              />
                            )}
                          </span>
                        </span>
                      </button>
                    </div>

                    <div
                      id="section-highlights"
                      className={`c-compare__cell-wrap / pt-12 ${
                        signUpOffersIsCollapsed ? 'hidden' : ''
                      }`}
                    >
                      {cardsWithOrderIds.map(({ card }) => {
                        const defaultListItems = [
                          { label: 'Regular APR', value: card.aprPurchase },
                          { label: 'Intro APR', value: card.aprIntroPurchase },
                          {
                            label: 'Intro BT APR',
                            value: card.aprIntroBalanceTransfer,
                          },
                        ]

                        const overrideItem = card.featureOverrides.filter(
                          (item) => item.categoryOverride === 'best',
                        )

                        const featuresToRender =
                          overrideItem.length > 0
                            ? overrideItem[0].features
                            : (card?.defaultFeatures ?? []).length > 0
                            ? card.defaultFeatures
                            : defaultListItems

                        return (
                          <CardHighlights
                            key={card.slug}
                            card={card}
                            /**
                             * @todo fix the legit type errors this type
                             * assertion is masking
                             */
                            listItems={
                              featuresToRender as CompareCredit.FeatureBlockEditorial[]
                            }
                          />
                        )
                      })}
                    </div>
                  </div>

                  {/* MAIN BENEFITS / ADDITIONAL DETAILS */}
                  <div
                    className={`c-compare__row / mb-4 ${
                      comparisonExpand ? '' : 'hidden'
                    }`}
                    ref={ref}
                    id="scrollTrigger"
                  >
                    <div className="absolute left-0 / w-full">
                      <button
                        className={`c-compare__collapse-btn / h-10 / pl-8`}
                        onClick={() =>
                          handleCollapse(
                            addlInfoIsCollapsed,
                            setAddlInfoIsCollapsed,
                          )
                        }
                        aria-expanded={addlInfoIsCollapsed ? 'false' : 'true'}
                        aria-controls="section-main-benefits"
                      >
                        Main Benefits
                        <span className="absolute left-0 mt-1 ml-2">
                          <span className={`inline-block w-4 h-4`}>
                            {addlInfoIsCollapsed ? (
                              <Image
                                alt="expand"
                                src="/static/icons/icon-plus-primary.svg"
                                height={16}
                                width={16}
                                aria-hidden={true}
                                style={nextImageHelper.responsive}
                                sizes="100vw"
                              />
                            ) : (
                              <Image
                                alt="hide"
                                src="/static/icons/icon-minus-primary.svg"
                                height={16}
                                width={16}
                                aria-hidden={true}
                                style={nextImageHelper.responsive}
                                sizes="100vw"
                              />
                            )}
                          </span>
                        </span>
                      </button>
                    </div>

                    <div
                      id="section-main-benefits"
                      className={`c-compare__cell-wrap / pt-12 ${
                        addlInfoIsCollapsed ? 'hidden' : ''
                      }`}
                    >
                      {cardsWithOrderIds.map(({ card, orderId }, index) => (
                        <div
                          key={card.slug}
                          className={`c-compare__cell / text-left`}
                          id="section-additional-details"
                        >
                          <Card
                            key={index}
                            card={card}
                            orderId={orderId}
                            externalId={externalId}
                            categoryId={categoryId}
                            networkInfo={networkInfo}
                            addlInfoIsCollapsed={addlInfoIsCollapsed}
                          />
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* PROS and CONS */}
                  <div
                    className={`c-compare__row / mb-4 ${
                      comparisonExpand ? '' : 'hidden'
                    }`}
                  >
                    <div className="absolute left-0 / w-full">
                      <button
                        className={`c-compare__collapse-btn / h-10 / pl-8`}
                        onClick={() =>
                          handleCollapse(
                            prosConsIsCollapsed,
                            setProsConsIsCollapsed,
                          )
                        }
                        aria-expanded={prosConsIsCollapsed ? 'false' : 'true'}
                        aria-controls="section-pro-cons"
                      >
                        Pros &amp; Cons
                        <span className="absolute left-0 mt-1 ml-2">
                          <span className={`inline-block w-4 h-4`}>
                            {prosConsIsCollapsed ? (
                              <Image
                                alt="expand"
                                src="/static/icons/icon-plus-primary.svg"
                                height={16}
                                width={16}
                                aria-hidden={true}
                                style={nextImageHelper.responsive}
                                sizes="100vw"
                              />
                            ) : (
                              <Image
                                alt="hide"
                                src="/static/icons/icon-minus-primary.svg"
                                height={16}
                                width={16}
                                aria-hidden={true}
                                style={nextImageHelper.responsive}
                                sizes="100vw"
                              />
                            )}
                          </span>
                        </span>
                      </button>
                    </div>
                    <div
                      id="section-pro-cons"
                      className={`c-compare__cell-wrap / pt-12 ${
                        prosConsIsCollapsed ? 'hidden' : ''
                      }`}
                    >
                      {cardsWithOrderIds.map(({ card }) => (
                        <div
                          key={card.slug}
                          className={`c-compare__cell / text-left`}
                        >
                          <p className="text-sm uppercase text-secondary font-bold flex">
                            <span className="inline-block mr-2 w-5">
                              <Image
                                alt="icon thumbs up green"
                                src="/static/icons/icon-thumbs-up-green.svg"
                                height={22}
                                width={20}
                                aria-hidden={true}
                                style={nextImageHelper.responsive}
                                sizes="100vw"
                              />
                            </span>
                            Pros
                          </p>
                          <div className="mb-3 / pl-2.5 py-1 / text-sm">
                            <PortableText
                              value={card.expertReviewPros}
                              components={{
                                list: List,
                                listItem: ListItem,
                                types: {
                                  cardAttribute: getAttributeRenderer(card),
                                },
                              }}
                            />
                          </div>
                          <p className="text-sm uppercase text-primary font-bold flex">
                            <span className="inline-block mr-2 w-5">
                              <Image
                                alt="icon thumbs down blue"
                                src="/static/icons/icon-thumbs-down-blue.svg"
                                height={22}
                                width={20}
                                aria-hidden={true}
                                style={nextImageHelper.responsive}
                                sizes="100vw"
                              />
                            </span>
                            Cons
                          </p>
                          <div className="pl-2.5 py-1 / text-sm">
                            <PortableText
                              value={card.expertReviewCons}
                              components={{
                                list: List,
                                listItem: ListItem,
                                types: {
                                  cardAttribute: getAttributeRenderer(card),
                                },
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* ONGOING INFORMATION */}
                  <div
                    className={`c-compare__row / mb-4 ${
                      comparisonExpand ? '' : 'hidden'
                    }`}
                  >
                    <div className="absolute left-0 / w-full">
                      <button
                        className={`c-compare__collapse-btn / h-10 / pl-8`}
                        onClick={() =>
                          handleCollapse(
                            ongoingInfoIsCollapsed,
                            setOngoingInfoIsCollapsed,
                          )
                        }
                        aria-expanded={
                          ongoingInfoIsCollapsed ? 'false' : 'true'
                        }
                        aria-controls="section-ongoing-information"
                      >
                        Ongoing Rates
                        <span className="absolute left-0 mt-1 ml-2">
                          <span className={`inline-block w-4 h-4`}>
                            {ongoingInfoIsCollapsed ? (
                              <Image
                                alt="expand"
                                src="/static/icons/icon-plus-primary.svg"
                                height={16}
                                width={16}
                                aria-hidden={true}
                                style={nextImageHelper.responsive}
                                sizes="100vw"
                              />
                            ) : (
                              <Image
                                alt="hide"
                                src="/static/icons/icon-minus-primary.svg"
                                height={16}
                                width={16}
                                aria-hidden={true}
                                style={nextImageHelper.responsive}
                                sizes="100vw"
                              />
                            )}
                          </span>
                        </span>
                      </button>
                    </div>

                    <div
                      id="section-ongoing-information"
                      className={`c-compare__cell-wrap / pt-12 ${
                        ongoingInfoIsCollapsed ? 'hidden' : ''
                      }`}
                    >
                      {cardsWithOrderIds.map(({ card }) => (
                        <div
                          key={card.slug}
                          className={`c-compare__cell / text-left`}
                        >
                          <div className="py-1.5">
                            <p className="font-bold text-primary">
                              Rewards Rate
                            </p>
                            <p>{card.rewardsRate || 'N/A'}</p>
                          </div>
                          <div className="py-1.5">
                            <p className="font-bold text-primary">
                              APR for purchases
                            </p>
                            <p>{card.aprPurchase || 'N/A'}</p>
                          </div>
                          <div className="py-1.5">
                            <p className="font-bold text-primary">
                              APR for balance transfer
                            </p>
                            <p>{card.aprBalanceTransfer || 'N/A'}</p>
                          </div>
                          <div className="py-1.5">
                            <p className="font-bold text-primary">
                              APR for cash advance
                            </p>
                            <p>{card.aprCashAdvance || 'N/A'}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* CARD FEES */}
                  <div
                    className={`c-compare__row / mb-4 ${
                      comparisonExpand ? '' : 'hidden'
                    }`}
                  >
                    <div className="absolute left-0 / w-full">
                      <button
                        className={`c-compare__collapse-btn / h-10 / pl-8`}
                        onClick={() =>
                          handleCollapse(
                            cardFeesIsCollapsed,
                            setCardFeesIsCollapsed,
                          )
                        }
                        aria-expanded={cardFeesIsCollapsed ? 'false' : 'true'}
                        aria-controls="section-card-fees"
                      >
                        Card Fees
                        <span className="absolute left-0 mt-1 ml-2">
                          <span className={`inline-block w-4 h-4`}>
                            {cardFeesIsCollapsed ? (
                              <Image
                                alt="expand"
                                src="/static/icons/icon-plus-primary.svg"
                                height={16}
                                width={16}
                                aria-hidden={true}
                                style={nextImageHelper.responsive}
                                sizes="100vw"
                              />
                            ) : (
                              <Image
                                alt="hide"
                                src="/static/icons/icon-minus-primary.svg"
                                height={16}
                                width={16}
                                aria-hidden={true}
                                style={nextImageHelper.responsive}
                                sizes="100vw"
                              />
                            )}
                          </span>
                        </span>
                      </button>
                    </div>

                    <div
                      id="section-card-fees"
                      className={`c-compare__cell-wrap / pt-12 ${
                        cardFeesIsCollapsed && 'hidden'
                      }`}
                    >
                      {cardsWithOrderIds.map(({ card }) => (
                        <div
                          key={card.slug}
                          className={`c-compare__cell / text-left`}
                        >
                          <div className="py-1.5">
                            <p className="font-bold text-primary">Annual Fee</p>
                            <p>{card.feeAnnual || 'N/A'}</p>
                          </div>
                          <div className="py-1.5">
                            <p className="font-bold text-primary">
                              Foreign Transaction Fee
                            </p>
                            <p>{card.feeForeignTransaction || 'N/A'}</p>
                          </div>
                          <div className="py-1.5">
                            <p className="font-bold text-primary">
                              Balance Transfer Fee
                            </p>
                            <p>{card.feeBalanceTransfer || 'N/A'}</p>
                          </div>
                          <div className="py-1.5">
                            <p className="font-bold text-primary">
                              Cash Advance Fee
                            </p>
                            <p>{card.feeCashAdvance || 'N/A'}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {comparisonExpand ? null : (
                    <div className="absolute bottom-0 left-0 / w-full h-24 / mb-8 / bg-gradient-to-t from-white">
                      &nbsp;
                    </div>
                  )}
                </div>

                <div className="relative z-2 / px-4 xs:px-12 lg:px-0">
                  <button
                    onClick={handleComparisonExpand}
                    className="flex justify-center items-center flex-row / w-full / py-1 / text-center text-primary-bright text-sm font-semibold / border border-primary-bright rounded-sm / hover:text-primary-mid hover:border-primary-mid / transition-all"
                    aria-expanded={comparisonExpand ? 'true' : 'false'}
                    aria-controls="compare-inner"
                  >
                    {comparisonExpand ? 'Collapse' : 'Expand'} Comparisons{' '}
                    <img
                      className={`w-4 ml-2`}
                      alt="expand icon"
                      src={`/static/icons/${
                        comparisonExpand
                          ? 'icon-minus-primary-bright'
                          : 'icon-plus-primary-bright'
                      }.svg`}
                      aria-hidden={true}
                    />
                  </button>
                </div>
              </div>
            )}
          </section>

          <CardNavMain
            cardsWithOrderIds={cardsWithOrderIds}
            leftPos={leftPos}
            categoryId={categoryId}
            externalId={externalId}
            displayNav={displayNav}
            ribbonText={cardRibbonText}
            ribbonColor={cardRibbonColor}
          />
        </div>
        <style jsx>{`
          .c-compare__inner--collapse {
            max-height: 40rem;
            overflow-x: auto;
            overflow-y: hidden;
          }
        `}</style>
      </div>
    )
  }
  return CardComparisonInner
}

const CompareCardTile: React.FC<{
  card: CompareCredit.FormattedCard | CompareCredit.NonPaidCard
  categoryId: string
  externalId: string
  ribbonText?: string | null
  ribbonColor?: string | null
}> = ({ card, categoryId, externalId, ribbonText, ribbonColor }) => {
  const orderId = useMemo(() => generateNewOrderId(), [])
  const { clientLocation } = useContext(UserContext)

  return (
    <div
      className={`c-compare__cell c-compare__cell--card-tile / relative / px-3 pb-1 pt-4 sm:pb-3 / bg-white ${
        ribbonColor === 'gold' ? 'bg-primary-light-bg-2' : 'bg-white'
      }`}
    >
      <div
        className={`relative / flex flex-col items-center justify-between / w-full h-full`}
      >
        <div className="flex flex-col items-center justify-center / w-full">
          {ribbonText ? (
            <div className="mb-1.5 text-center">
              <p
                className={`c-ribbon c-ribbon--slimmer /
                relative inline-block /
                w-auto px-2 py-1 /
                font-bold text-fs11 tracking-wide uppercase /
                ${
                  ribbonColor === 'gold'
                    ? 'bg-tetriary text-primary'
                    : 'c-ribbon--primary-bright bg-primary-bright text-white'
                }`}
              >
                {ribbonText}
              </p>
            </div>
          ) : (
            <div className="mb-1.5">&nbsp;</div>
          )}

          <div className={`w-full`}>
            <div className="c-card-art / text-center flex items-center justify-center / mb-4 lg:mb-5">
              <div className="c-card-art__container / relative / w-full xs:w-10/12 md:w-3/5 lg:w-1/2 max-w-xs / rounded shadow">
                {card._type === 'card' ? (
                  <LinkToRedirect
                    externalId={externalId}
                    linkParams={card.applyNowLinkParameters}
                    orderId={orderId}
                    slug={card.slug}
                  >
                    <div className="relative flex w-full rounded overflow-hidden">
                      <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white text-fs13 xs:text-sm md:text-base">
                        <span className="w-full mb-0">
                          <span className="inline-block w-6 h-6 md:w-7 md:h-7 lg:w-10 lg:h-10">
                            <ApplyNowIcon
                              height={32}
                              width={32}
                              style={nextImageHelper.responsive}
                              sizes="100vw"
                            />
                          </span>
                        </span>
                        <span className="w-full font-bold">Apply Now</span>
                      </span>
                      <CardArt
                        _rev={card._rev}
                        cardArt={card.cardArt}
                        imgClasses="relative z-0 w-full h-full object-cover top-0"
                        issuer={card.issuer.slug.current}
                        name={card.name}
                        orderId={orderId}
                        slug={card.slug}
                        categoryId={categoryId}
                        externalId={externalId}
                      />
                    </div>
                  </LinkToRedirect>
                ) : (
                  <Link href={card.moreInfoLink}>
                    <div className="relative flex w-full rounded overflow-hidden">
                      <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white text-fs13 xs:text-sm md:text-base">
                        <span className="w-full font-bold">Learn More</span>
                      </span>
                      <CardArt
                        _rev={card._rev}
                        cardArt={card.cardArt}
                        imgClasses="relative z-0 w-full h-full object-cover top-0"
                        issuer={card.issuer.slug.current}
                        name={card.name}
                        orderId={orderId}
                        slug={card.slug}
                        categoryId={categoryId}
                        externalId={externalId}
                      />
                    </div>
                  </Link>
                )}
              </div>
            </div>
            <h2 className="c-compare__card-title / max-w-xs / mx-auto / font-sans font-bold text-center leading-tight / mb-1.5">
              {card._type === 'card' ? (
                <LinkToRedirect
                  externalId={externalId}
                  linkParams={card.applyNowLinkParameters}
                  orderId={orderId}
                  slug={card.slug}
                >
                  <div
                    className="text-primary-mid hover:text-primary-bright / js-card-title"
                    dangerouslySetInnerHTML={{ __html: supify(card.name) }}
                  />
                </LinkToRedirect>
              ) : (
                <Link href={card.moreInfoLink}>
                  <div
                    className="text-primary-mid hover:text-primary-bright / js-card-title"
                    dangerouslySetInnerHTML={{ __html: supify(card.name) }}
                  />
                </Link>
              )}
            </h2>
          </div>
          <div className="flex justify-center lg:mb-2 w-full">
            <div className="lg:hidden">
              <StarRatingEditor
                productSlug={card.slug}
                stars={card.expertReviewRating}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center / w-full">
          <div className="w-full">
            <div
              className={
                card._type === 'card' &&
                card.phoneNumber &&
                isMobileOnly &&
                clientLocation.country === 'US'
                  ? 'mb-2'
                  : 'mb-3'
              }
            >
              {card._type === 'card' ? (
                <LinkToRedirect
                  externalId={externalId}
                  linkParams={card.applyNowLinkParameters}
                  orderId={orderId}
                  slug={card.slug}
                >
                  <div className="c-btn c-btn--primary / w-full max-w-xs sm:w-3/4 md:w-3/5 lg:w-1/2 / md:text-lg / rounded-sm">
                    Apply Now
                    <span className="w-4 h-4 / ml-1 hidden xs:inline-block">
                      <ApplyNowIcon width={16} height={16} />
                    </span>
                  </div>
                </LinkToRedirect>
              ) : (
                <Link href={card.moreInfoLink}>
                  <div className="c-btn c-btn--secondary / w-full max-w-xs sm:w-3/4 md:w-3/5 lg:w-1/2 / md:text-lg / rounded-sm">
                    Learn More
                  </div>
                </Link>
              )}
            </div>
          </div>
          <div className={`w-full`}>
            <div className="w-full max-w-xs / mb-2 mx-auto">
              <CardRecommendedCredit card={card} iconHide={true} />
            </div>
          </div>
          {card.ratesAndFees && card.ratesAndFees && (
            <div className="w-full / text-center h-6">
              {card._type === 'card' &&
                card.ratesAndFees &&
                card.ratesAndFees !== 'N/A' && (
                  <a
                    className="text-primary-bright text-xs"
                    href={card.ratesAndFees}
                    onClick={() => {
                      clickRatesAndFees({
                        applyNowLink: card.applyNowLink,
                        component: 'Card Comparison Tile',
                        name: card.name,
                        url: window.location.href,
                      })
                    }}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Rates &amp; Fees
                  </a>
                )}
              {card.issuer.slug.current === 'american-express' && (
                <p className="text-fs10 text-gray-600 / text-center / mb-3 md:mb-1 / w-full leading-4">
                  Terms Apply
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const Normal: PortableTextBlockComponent = ({ children }) => (
  <span>{children}</span>
)

const CardHighlights: React.FC<{
  card: CompareCredit.FormattedCard
  listItems: CompareCredit.FeatureBlockEditorial[]
}> = ({ card, listItems }) => {
  return (
    <div className={`c-compare__cell / text-left`}>
      {listItems.map((listItem, index) => {
        return (
          <div className="py-1.5" key={index}>
            <p className="font-bold text-primary">{listItem.Title}</p>
            <p>
              <PortableText
                value={listItem.Description}
                components={{
                  block: { normal: Normal },
                  types: {
                    cardAttribute: getAttributeRenderer(card),
                  },
                }}
              />
            </p>
          </div>
        )
      })}
    </div>
  )
}

const CardNavMain: React.FC<{
  cardsWithOrderIds: { card: CompareCredit.FormattedCard; orderId: string }[]
  categoryId: string
  externalId: string
  leftPos: number | undefined
  displayNav: boolean | null
  ribbonText?: string[]
  ribbonColor?: string[]
}> = ({
  cardsWithOrderIds,
  categoryId,
  externalId,
  leftPos,
  displayNav,
  ribbonText,
  ribbonColor,
}) => {
  const [hasScrolledNav, setHasScrolledNav] = useState(false)
  const handleScrollNav = () => setHasScrolledNav(window.pageYOffset > 500)

  useEffect(() => {
    handleScrollNav()
    window.addEventListener('scroll', handleScrollNav)
    return () => {
      window.removeEventListener('scroll', handleScrollNav)
    }
  }, [])

  return (
    <div
      className={`c-card-nav / fixed left-0 top-0 z-80 / w-full / leading-snug / bg-white shadow / transition ease-in-out duration-500
    ${displayNav ? `is-shown` : ''}
    ${
      cardsWithOrderIds.length === 1
        ? 't-card-nav-1-col'
        : cardsWithOrderIds.length === 2
        ? 't-card-nav-2-col'
        : cardsWithOrderIds.length === 3
        ? 't-card-nav-3-col'
        : 't-card-nav-4-col'
    }
    `}
      aria-hidden={hasScrolledNav ? false : true}
    >
      <div className="c-card-nav__container / max-w-6xl / mx-auto / mx-auto">
        <div className="c-card-nav__inner / flex items-center justify-center / w-full">
          <div className="c-card-nav__row / w-full">
            <div
              className="c-card-nav__wrap relative sm:static / w-full"
              style={{
                left: `${leftPos}px`,
              }}
            >
              {ribbonText &&
                ribbonColor &&
                cardsWithOrderIds.map(({ card, orderId }, index) => (
                  <CardNavTile
                    key={card.slug}
                    card={card}
                    categoryId={categoryId}
                    externalId={externalId}
                    orderId={orderId}
                    ribbonText={ribbonText[index]}
                    ribbonColor={ribbonColor[index]}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const CardNavTile: React.FC<{
  card: CompareCredit.FormattedCard
  categoryId: string
  externalId: string
  orderId: string
  hideRemove?: boolean
  ribbonText?: string | null
  ribbonColor?: string | null
}> = ({ card, externalId, orderId, ribbonText, ribbonColor }) => {
  return (
    <div
      className={`c-card-nav__cell / relative / flex items-start justify-center / w-full ${
        ribbonColor === 'gold' ? 'bg-primary-light-bg-2' : 'bg-white'
      }`}
    >
      <div className="flex flex-col / w-full">
        {ribbonText ? (
          <div className="mb-0.5 text-center">
            <p
              className={`c-ribbon c-ribbon--slimmer /
            relative inline-block /
            w-auto px-2 py-0.5 /
            font-bold text-fs10 tracking-wide uppercase /
            ${
              ribbonColor === 'gold'
                ? 'bg-tetriary text-primary'
                : 'c-ribbon--primary-bright bg-primary-bright text-white'
            }
            `}
            >
              {ribbonText}
            </p>
          </div>
        ) : (
          <div className="mb-1.5">&nbsp;</div>
        )}

        <div className="c-card-art / flex-shrink-0 / w-16 sm:w-20 / mx-auto mb-1.5 / text-center flex items-center justify-center">
          <div className="relative / w-full / rounded-sm shadow">
            <LinkToRedirect
              externalId={externalId}
              linkParams={card.applyNowLinkParameters}
              orderId={orderId}
              slug={card.slug}
            >
              <div className="relative flex / w-full / overflow-hidden">
                <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white text-fs11 leading-snug">
                  <span className="w-full">
                    <span className="inline-block w-4 h-4">
                      <ApplyNowIcon
                        height={32}
                        width={32}
                        style={nextImageHelper.responsive}
                        sizes="100vw"
                      />
                    </span>
                  </span>
                  <span className="w-full font-semibold">Apply Now</span>
                </span>
                <CardArt
                  _rev={card._rev}
                  cardArt={card.cardArt}
                  customCodeSnippets={card.customCodeSnippets}
                  imgClasses="relative z-0 w-full h-full object-cover top-0"
                  issuer={card.issuer.slug.current}
                  name={card.name}
                  orderId={orderId}
                  slug={card.slug}
                  categoryId="00"
                  externalId="00"
                />
              </div>
            </LinkToRedirect>
          </div>
        </div>
        <div className="w-full mb-1">
          <LinkToRedirect
            externalId={externalId}
            linkParams={card.applyNowLinkParameters}
            orderId={orderId}
            slug={card.slug}
          >
            <div className="c-card-nav__cta / flex items-center justify-center / mx-auto px-1 xs:py-0.5 / rounded-sm / border border-secondary font-semibold text-white text-fs12 / bg-green-cta / hover:bg-green-cta-light">
              Apply Now
              <span className="w-3 h-3 / ml-1 hidden xs:inline-block">
                <ApplyNowIcon width={12} height={12} />
              </span>
            </div>
          </LinkToRedirect>
        </div>
        <p className="text-fs10 text-gray-600 / text-center leading-tight">
          <SecureSite
            issuerName={card.issuer.name}
            issuerSecureSite={card.issuerSecureSite}
          />
        </p>
        {card.ratesAndFees && card.ratesAndFees && (
          <div className="w-full / -mt-1 / text-center">
            {card.ratesAndFees && card.ratesAndFees !== 'N/A' && (
              <a
                className="inline-block text-primary-bright text-fs10 leading-snug"
                href={card.ratesAndFees}
                onClick={() => {
                  clickRatesAndFees({
                    applyNowLink: card.applyNowLink,
                    component: 'Card Nav Comparison Tile',
                    name: card.name,
                    url: window.location.href,
                  })
                }}
                rel="noopener noreferrer"
                target="_blank"
              >
                Rates &amp; Fees
              </a>
            )}
            {card.issuer.slug.current === 'american-express' && (
              <p className="text-fs10 text-gray-600 / text-center / w-full leading-snug">
                Terms Apply
              </p>
            )}
          </div>
        )}
      </div>
      <style jsx>{`
        .c-ribbon {
          height: 14px;
        }
        .c-ribbon--slimmer:before {
          left: -6px;
          border-width: 7px 0 7px 7px;
        }
        .c-ribbon--slimmer:after {
          right: -6px;
          border-width: 7px 7px 7px 0;
        }
      `}</style>
    </div>
  )
}
