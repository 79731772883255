import React from 'react'
import {
  PortableText,
  PortableTextListComponent,
  PortableTextListItemComponent,
} from '@portabletext/react'

import { blockSerializers } from './index'

import { CompareCredit } from '../../../types/compare-credit'
import { useExperiment } from 'utils/use-experiment-wrapper'

const List: React.FC<React.PropsWithChildren> = ({ children }) => (
  <ul className="c-list-disc / mb-2 pl-5">{children}</ul>
)

const ListItem: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <li className={`c-list-disc__item`}>
      {children}{' '}
      <style jsx>
        {`
          .c-list-disc__item {
            margin-bottom: 0.25em;
          }
          .c-list-disc__item:last-child {
            margin-bottom: 0;
          }
        `}
      </style>
    </li>
  )
}

export const ContentHighlight = ({
  cardOrderIds,
  referencedCards,
  categoryId,
  externalId,
}: {
  cardOrderIds: Record<string, string>
  referencedCards: null | Record<string, CompareCredit.Entity>
  categoryId: string
  externalId: string
}) => {
  const ContentHighlightRender: React.FC<{
    value: {
      title: string
      contentClass?: string
      border: boolean
      layoutDesktop?: boolean
      bgColor?: string
      content: any
    }
  }> = ({
    value: { title, contentClass, border, layoutDesktop, bgColor, content },
  }) => {
    // 2025.02: EXP-287 Offer Highlight v2
    const { experiment: config287, isLoading: isLoading287 } = useExperiment(
      'exp_287_offer_highlight_v2',
    )
    const variation287 = config287?.value?.name || 'control'

    // 2025.02: EXP-361 Offer Highlight PMAX
    const { experiment: config361, isLoading: isLoading361 } = useExperiment(
      'exp_361_offer_highlight_pmax',
    )
    const variation361 = config361?.value?.name || 'control'

    // 2025.02: EXP-365 Offer Highlight Pay-0 v1 & bt
    const { experiment: config365, isLoading: isLoading365 } = useExperiment(
      'exp_365_offer_highlight_pay0_v1_bt',
    )
    const variation365 = config365?.value?.name || 'control'

    // 2025.02: EXP-366 Offer Highlight best-credit-cards-h3
    const { experiment: config366, isLoading: isLoading366 } = useExperiment(
      'exp_366_offer_highlight_best_h3',
    )
    const variation366 = config366?.value?.name || 'control'

    return (
      <div
        className={`c-content-highight / ${contentClass && contentClass}
        ${bgColor == 'light-orange' ? 'bg-[#FEFAEE]' : 'bg-primary-light-bg'}
        ${
          layoutDesktop
            ? 'md:flex md:flex-col mb-3 p-3 rounded-sm'
            : 'mb-6 p-4 xxs:px-6 xxs:py-5 rounded'
        }
        ${
          !isLoading287 && variation287 == 'v1-offer-highlight'
            ? 'c-content-highight--exp287 t-exp287-v1'
            : 'c-content-highight--exp287 t-exp287-control'
        }
        ${
          !isLoading361 && variation361 == 'v1-offer-highlight'
            ? 'c-content-highight--exp361 t-exp361-v1'
            : 'c-content-highight--exp361 t-exp361-control'
        }
        ${
          !isLoading365 && variation365 == 'v1-offer-highlight'
            ? 'c-content-highight--exp365 t-exp365-v1'
            : 'c-content-highight--exp365 t-exp365-control'
        }
        ${
          !isLoading366 && variation366 == 'v1-offer-highlight'
            ? 'c-content-highight--exp366 t-exp366-v1'
            : 'c-content-highight--exp366 t-exp366-control'
        }
        `}
      >
        <h3
          className={`c-content-highight__title / mb-2 text-primary font-bold leading-snug
          ${layoutDesktop ? '' : 'font-serif text-2xl xs:text-3xl'}
          `}
        >
          {title}
        </h3>
        {border ? (
          <hr className="c-content-highight__border / w-24 xs:w-28 / border-3 border-tetriary / my-3" />
        ) : null}
        <div className="c-content-highight__content">
          <PortableText
            value={content}
            components={{
              ...blockSerializers({
                cardOrderIds,
                categoryId,
                externalId,
                referencedCards,
              }),
              list: List as PortableTextListComponent,
              listItem: ListItem as PortableTextListItemComponent,
            }}
          />
        </div>
      </div>
    )
  }
  return ContentHighlightRender
}
