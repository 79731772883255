import React from 'react'
import last from 'lodash/fp/last'
import Image from 'next/image'
import Link from 'next/link'
import { PortableText, PortableTextBlockComponent } from '@portabletext/react'

import pageRoutes from '../../pageRoutes.json'
import disclosure from '../../ssg/disclosures/disclosures.json'
import { getCurrentYear, nextImageHelper } from '../../utils'

export function FooterMin(props: {
  generalDisclaimer: { content: any[]; name: string }
  editorialNote: { content: any[]; name: string }
  compareCreditAwardsProgram: { content: any[]; name: string }
}) {
  const Normal = ({ children }: { children: any }) => (
    <p className="text-gray-400 mb-6">{children}</p>
  )

  return (
    <footer className="bg-primary text-white">
      <div className="flex">
        <span className="w-1/6 h-1 bg-primary-mid"></span>
        <span className="w-1/6 h-1 bg-primary-mid-light"></span>
        <span className="w-1/6 h-1 bg-secondary"></span>
        <span className="w-1/6 h-1 bg-secondary-light"></span>
        <span className="w-1/6 h-1 bg-primary-bright"></span>
        <span className="w-1/6 h-1 bg-primary-light"></span>
      </div>

      <div className="container-fluid pt-10 pb-24">
        <div className="flex flex-wrap text-fs13">
          <div className="flex flex-col justify-center w-full">
            <h4 className="w-full font-bold mb-6 flex justify-center text-center">
              <span>
                <div className="w-8 inline-block mr-4">
                  <Image
                    src="/static/logos/compare-credit_logomark.svg"
                    alt="compare credit logo mark"
                    height={37}
                    width={32}
                    style={nextImageHelper.responsive}
                    sizes="100vw"
                  />
                </div>
              </span>
              <span className="mt-2">
                &reg;{getCurrentYear()} Compare Credit
              </span>
            </h4>
            <div className="w-full mb-10">
              <ul className="flex justify-center w-full">
                <li className="mr-6">
                  <Link href={pageRoutes['terms-conditions']}>
                    <span className="hover:text-primary-bright cursor-pointer / transition-all">
                      Terms &amp; Conditions
                    </span>
                  </Link>
                </li>
                <li>
                  <Link href={pageRoutes['privacy']}>
                    <span className="hover:text-primary-bright cursor-pointer / transition-all">
                      Privacy Policy
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
            <hr className="w-full mb-10 border-white opacity-25" />
            <div className="text-center mx-auto max-w-4xl">
              <h4 className="font-bold mb-2 text-center">
                {props.generalDisclaimer.name}
              </h4>
              <PortableText
                value={props.generalDisclaimer.content}
                components={{
                  block: { normal: Normal as PortableTextBlockComponent },
                }}
              />
            </div>
            <div className="text-center mx-auto max-w-4xl">
              <h4 className="font-bold mb-2 text-center">
                {props.editorialNote.name}
              </h4>
              <PortableText
                value={props.editorialNote.content}
                components={{
                  block: { normal: Normal as PortableTextBlockComponent },
                }}
              />
            </div>
            <div className="text-center mx-auto max-w-4xl">
              <h4 className="font-bold mb-2 text-center">
                {props.compareCreditAwardsProgram.name}
              </h4>
              <PortableText
                value={props.compareCreditAwardsProgram.content}
                components={{
                  block: { normal: Normal as PortableTextBlockComponent },
                }}
              />
            </div>

            <div className="flex w-full justify-center">
              <ul className="flex flex-row items-center justify-around / w-full max-w-[34rem] / text-center">
                <li className="">
                  <a
                    href="https://www.inc.com/profile/compare-credit"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="visit inc 5000"
                    className="inline-block / w-16 xs:w-18 sm:w-[5.5rem] md:w-[5rem] lg:w-[6rem]"
                  >
                    <Image
                      src="/static/as-seen-on/badge-inc-5000.png"
                      height={72}
                      width={72}
                      alt="INC 5000"
                      aria-hidden={true}
                      style={nextImageHelper.responsive}
                      sizes="100vw"
                    />
                  </a>
                </li>
                <li className="">
                  <div className="c-img-trust / inline-block w-22 xs:w-[6.5rem] sm:w-[7rem]">
                    <Image
                      src="/static/logos/norton-white.svg"
                      alt="norton white logo"
                      height={60}
                      width={112}
                      style={nextImageHelper.responsive}
                      sizes="100vw"
                    />
                  </div>
                </li>
                <li className="">
                  <div className="c-img-trust / inline-block w-22 xs:w-[6.5rem] sm:w-[7rem]">
                    <Image
                      src="/static/logos/bbb-base-white.svg"
                      alt="bbb base white logo"
                      height={39}
                      width={112}
                      style={nextImageHelper.responsive}
                      sizes="100vw"
                    />
                  </div>
                </li>
                <li className="">
                  <div className="c-img-trust / inline-block w-12 xs:w-[3.5rem] sm:w-[4rem]">
                    <Image
                      src="/static/logos/equal-housing-opportunity-w.svg"
                      alt="equal housing opportunity white logo"
                      height={64}
                      width={64}
                      style={nextImageHelper.responsive}
                      sizes="100vw"
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

FooterMin.defaultProps = {
  generalDisclaimer: last(
    disclosure.filter((d) => d.name === 'General Disclaimer'),
  ),
  editorialNote: last(disclosure.filter((d) => d.name === 'Editorial Note')),
  compareCreditAwardsProgram: last(
    disclosure.filter((d) => d.name === 'CompareCredit Awards Program'),
  ),
}
