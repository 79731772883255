import Image from 'next/image'

import cn from 'utils/classnames'
import { CompareCredit } from '../../../../types/compare-credit'
import { nextImageHelper, supify } from '../../../utils'
import { CardRecommendedCredit } from '../../card-recommended-credit'
import { LinkToRedirect } from '../../link-to-redirect'
import { TrustSecure } from '../../trust-secure'
import { ApplyNowLinkV2 } from '../applyNowLinkV2'
import { AttributeRendererWithSlugV2 } from '../attributeRendererV2'
import CardMoreDetails from './card-more-details'
import ProductArt from './product-art'
import ProductCTAs from './product-ctas'
import ProductHighlights from './product-highlights'
import ProductRating from './product-rating'

export const StickyAside: React.FC<{
  cardTileAsideRef: React.RefObject<HTMLDivElement>
  cardTileIsSticky: boolean
  asideCardTileMargin: string
  isSticky: boolean
  asideOverflow: boolean
  unstickCardTile: boolean
  featuredRibbon: boolean
  featuredRibbonText: string
  productTip: any
  categoryId: string
  externalId: string
  orderId: string
  query: string
  displayName: boolean
  recommendedCredit: boolean
  clientLocation: {
    city: string
    country: string
    state: string
    status: string
  }
  referencedCards: null | Record<string, CompareCredit.Entities>
  cardOrderIds: Record<string, string>
  singleCardTip: {
    card: {
      _id: string
      slug: string
    }
    content: {
      children: any
    }[]
  }
  setModalOpen: (value: React.SetStateAction<boolean>) => void
  modalOpen: boolean
  hideMoreDetails: boolean
  hideTrustLabel: boolean
  hideLeadingBadge: boolean
  shouldShowAwardsBadge: boolean
}> = ({
  cardTileAsideRef,
  cardTileIsSticky,
  asideCardTileMargin,
  isSticky,
  asideOverflow,
  unstickCardTile,
  featuredRibbon,
  featuredRibbonText,
  productTip,
  categoryId,
  externalId,
  orderId,
  query,
  displayName,
  recommendedCredit,
  clientLocation,
  referencedCards,
  cardOrderIds,
  singleCardTip,
  setModalOpen,
  modalOpen,
  hideMoreDetails,
  hideTrustLabel,
  hideLeadingBadge,
  shouldShowAwardsBadge,
}) => {
  return (
    <>
      {isSticky && (
        <aside
          id="aside"
          ref={cardTileAsideRef}
          className={`c-aside / relative / hidden lg:flex lg:flex-col / w-full h-full / mb-12 md:mb-0 ${
            cardTileIsSticky === true ? '' : asideCardTileMargin
          } `}
        >
          <div
            className={`c-card-aside ${
              isSticky && asideOverflow ? `is-scrollable` : ''
            }
            ${isSticky && cardTileIsSticky ? `c-card-aside--fixed` : ''} ${
              isSticky && cardTileIsSticky && unstickCardTile
                ? `c-card-aside--fixed-bot`
                : ''
            }`}
          >
            <div id="inner-aside" className="c-card-aside__row">
              <div
                className={`c-card-aside__grid ${
                  isSticky && cardTileIsSticky
                    ? `grid grid-cols-12 gap-x-12 gap-y-6`
                    : ''
                }`}
              >
                <div
                  className={`c-card-aside__grid-col-2 ${
                    isSticky && cardTileIsSticky ? `col-span-4 col-start-9` : ''
                  }`}
                >
                  <div
                    className={cn(
                      'c-card-aside__col',
                      shouldShowAwardsBadge && 'w-full',
                      'pt-1.5',
                    )}
                  >
                    {featuredRibbon && featuredRibbonText && (
                      <div className="flex text-center">
                        <p className="c-ribbon c-ribbon--slimmer / inline-block / relative / w-auto mx-auto px-4 py-1 / font-bold text-primary text-sm uppercase tracking-wide / bg-tetriary">
                          {featuredRibbonText}
                        </p>
                      </div>
                    )}

                    <div
                      id="card-tile"
                      className="relative / z-30 / mb-2 w-full / hidden lg:block"
                    >
                      {shouldShowAwardsBadge && (
                        <div className="c-card-tile__awards-banner / absolute top-1 -left-10 xl:-left-11 z-20 / w-16 xl:w-18">
                          <span className="inline-block / w-full">
                            <Image
                              width={16}
                              height={16}
                              alt="CompareCredit Best Credit Cards 2025"
                              src="/static/awards/cc-awards-ribbon-2025.svg"
                              sizes="100vw"
                              style={nextImageHelper.responsive}
                              loading="lazy"
                            />
                          </span>
                        </div>
                      )}
                      <div className="c-card-aside__wrap">
                        <div
                          className={`relative m-auto w-full / mb-6 ${
                            productTip._type === 'card' ? 'lg:mt-7' : ''
                          } / xxs:px-8 pb-3 / bg-white shadow-lg rounded-lg z-10`}
                        >
                          <ProductArt
                            categoryId={categoryId}
                            externalId={externalId}
                            orderId={orderId}
                            productTip={productTip}
                            query={query}
                            width="w-full"
                          />
                          {displayName && (
                            <h3
                              className={`text-primary-mid leading-snug font-bold text-center text-base xl:text-lg`}
                            >
                              <LinkToRedirect
                                externalId={externalId}
                                linkParams={
                                  productTip._type === 'card'
                                    ? productTip.applyNowLinkParameters
                                    : []
                                }
                                orderId={orderId}
                                query={query !== '' ? query : undefined}
                                slug={productTip.slug}
                              >
                                <div
                                  className="inline-block / w-full / hover:text-primary-bright cursor-pointer"
                                  dangerouslySetInnerHTML={{
                                    __html: supify(productTip.name),
                                  }}
                                />
                              </LinkToRedirect>
                            </h3>
                          )}
                          <ProductRating
                            productTip={productTip}
                            fontSize="text-5xl"
                          />
                          {recommendedCredit ? (
                            <div className="mb-2.5 -mt-1">
                              <CardRecommendedCredit card={productTip} />
                            </div>
                          ) : null}

                          <ProductCTAs
                            orderId={orderId}
                            categoryId={categoryId}
                            externalId={externalId}
                            clientCountry={clientLocation.country}
                            productTip={productTip}
                            query={query}
                          />
                          <ProductHighlights
                            attributeRenderer={AttributeRendererWithSlugV2({
                              referencedCards,
                            })}
                            applyNowLinkRenderer={ApplyNowLinkV2({
                              externalId,
                              referencedCards,
                              categoryId,
                              cardOrderIds,
                            })}
                            content={singleCardTip.content}
                            categoryId={categoryId}
                          />

                          {productTip._type === 'card' && !hideMoreDetails && (
                            <CardMoreDetails
                              handleModal={setModalOpen}
                              modalOpen={modalOpen}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="w-full text-center">
                      <div className="inline-block w-40">
                        <TrustSecure
                          hideTrustLabel={hideTrustLabel}
                          hideLeadingBadge={hideLeadingBadge}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </aside>
      )}
      <style jsx>{`
        .c-ribbon__text {
          top: -1px;
        }
        /* CARD-ASIDE
      ======================================================= */
        .c-card-aside__row {
          max-width: 72rem;
          margin: auto;
        }

        @media (min-width: 1024px) {
          .c-card-aside__wrap {
            padding-left: 1rem;
            padding-right: 1rem;
            margin-left: -1rem;
            margin-right: -1rem;
            display: block;
            max-height: calc(100vh - 5rem);
            overflow-y: auto;
          }

          /* ===== THEME ===== */
          .c-card-aside--fixed {
            position: fixed;
            top: 0;
            right: 0;
            width: 100%;
            height: 1px;
          }
          .c-card-aside--fixed .c-card-aside__row {
            width: 100%;
            padding-top: 5rem;
            padding-left: 2rem;
            padding-right: 2rem;
          }
          .c-card-aside--fixed .c-card-aside__col {
            float: right;
          }
          .c-card-aside--fixed .c-card-aside__grid {
            height: 1px;
          }
          .c-card-aside--fixed.c-card-aside--fixed-bot {
            position: absolute;
            top: inherit;
            bottom: 0;
            height: auto;
          }
          .c-card-aside--fixed.c-card-aside--fixed-bot .c-card-aside__row {
            padding-top: 0;
            padding-left: 0;
            padding-right: 0;
          }
          .c-card-aside--fixed.c-card-aside--fixed-bot .c-card-aside__col {
            width: 100%;
          }
          .c-card-aside--fixed.c-card-aside--fixed-bot .c-card-aside__grid {
            grid: none;
            column-gap: inherit;
            height: auto;
          }
        }

        @media (min-width: 1152px) {
          .c-card-aside--fixed .c-card-aside__row {
            padding-left: 1rem;
            padding-right: 1rem;
          }
          .c-card-aside__col {
            padding-left: 1.5rem;
          }
        }
      `}</style>
    </>
  )
}
