import React from 'react'
import { CompareCredit } from '../../../types/compare-credit'
import { UserContext } from 'components/'
import { startCheckout } from 'clients/segment'
import { formatPhoneNumber, userTrackingAttributes } from 'utils/'
import useNetworkState from 'utils/use-network-status'
import cn from 'utils/classnames'

export function CallToApply(props: {
  classes?: string
  entity: CompareCredit.FormattedCard
  numOnly?: boolean
}) {
  const { clientLocation } = React.useContext(UserContext)
  const networkInfo = useNetworkState()

  const { entity } = props
  const handleClick = () => {
    const affiliate = entity.issuer.affiliate
    const tracking = userTrackingAttributes(affiliate)
    startCheckout(
      {
        affiliation: affiliate,
        category: 'credit-card',
        order_id: tracking.orderId,
        isCallToApply: true,
        products: [
          {
            brand: entity.issuer.slug.current,
            id: entity.slug,
            price: entity.cpa,
            product_id: entity.slug,
            quantity: 1,
            sku: entity.slug,
          },
        ],
        revenue: entity.cpa,
        network: networkInfo,
      },
      true,
    ).then((r) => r)
  }
  return (
    <>
      {clientLocation.country === 'US' ? (
        !props.numOnly ? (
          <a
            className={cn('c-btn c-btn--secondary', props.classes)}
            href={`tel:${entity.phoneNumber}`}
            onClick={handleClick}
          >
            Call to Apply
          </a>
        ) : (
          <>
            {' '}
            <span>
              or call{' '}
              <a
                href={`tel:${entity.phoneNumber}`}
                className="text-primary-bright font-semibold / hover:text-primary-mid hover:underline whitespace-nowrap"
                onClick={handleClick}
              >
                {formatPhoneNumber(entity.phoneNumber)}
              </a>
              {entity.phoneNumberVerbiage && ` ${entity.phoneNumberVerbiage}`}
            </span>
          </>
        )
      ) : null}
    </>
  )
}
