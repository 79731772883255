export const RPR_SERVICE_PARAMS = [
  'ad_group_id',
  'fbclid',
  'gclid',
  'msclkid',
  'rtid',
  'kw',
  'position',
  'utm_campaign',
  'utm_medium',
  'utm_source',
  'rdt_cid',
  'li_fat_id',
]

export type RprServiceQueryParams = (typeof RPR_SERVICE_PARAMS)[number]
