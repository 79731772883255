const attributeSelector: Record<string, string> = {
  feeAnnual: 'Annual Fee',
  feeBalanceTransfer: 'Balance Transfer Fee',
  cardType: 'Card Type',
  aprCashAdvance: 'Cash Advance APR',
  feeCashAdvance: 'Cash Advance Fee',
  creditNeeded: 'Credit Needed',
  feeForeignTransaction: 'Foreign Transaction Fee',
  aprIntroBalanceTransfer: 'Intro Balance Transfer APR',
  aprIntroPurchase: 'Intro Purchase APR',
  feeLatePayment: 'Late Payment Fee',
  name: 'Name',
  aprPenalty: 'Penalty APR',
  regularApr: 'Regular APR',
  aprBalanceTransfer: 'Regular Balance Transfer APR',
  aprPurchase: 'Regular Purchase APR',
  rewardsRate: 'Rewards Rate',
  bonusRewards: 'Bonus Rewards',
  bonusRewards1: 'Bonus Rewards 1',
  bonusRewards2: 'Bonus Rewards 2',
  bonusRewards3: 'Bonus Rewards 3',
  bonusRewards4: 'Bonus Rewards 4',
  bonusSignUp: 'Sign Up Bonus',
}

export default attributeSelector
