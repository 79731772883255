import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { PortableText, PortableTextBlockComponent } from '@portabletext/react'

import { CompareCredit } from '../../../types/compare-credit'
import { clickRatesAndFees } from '../../clients/segment'
import { LabelAd, LinkToRedirect } from 'components/'
import { CardArt } from 'components/card-art'
import { SecureSite } from 'components/secure-site'
import { supify, nextImageHelper } from 'utils/'
import cn from 'utils/classnames'
import { ApplyNowIcon } from '../link-to-redirect/ApplyNowIcon'

interface CardCartTileProps {
  card: CompareCredit.FormattedCard
  comparisonCart: CompareCredit.FormattedCard[]
  recCard?: CompareCredit.FormattedCard | null
  updateComparisonCart: (
    action: 'add' | 'remove',
    cards: CompareCredit.FormattedCard[],
  ) => void
  isPrime: boolean
  recCardIsInCart: boolean
  orderId: string
  categoryId: string
  externalId: string
}

const ExpertReviewHighlight = ({ children }: { children: any }) => (
  <span>{children}</span>
)

const AttributeRenderer = (props: CardCartTileProps) => {
  const render = <K extends keyof CompareCredit.Entity>(attrProps: {
    value: { attribute: K }
  }) => {
    return (
      <span>
        {props.card[`${attrProps.value.attribute}`] as React.ReactNode}
      </span>
    )
  }
  return render
}

export function CardCartTile(props: CardCartTileProps) {
  const {
    card,
    recCard,
    comparisonCart,
    updateComparisonCart,
    isPrime,
    recCardIsInCart,
    orderId,
    categoryId,
    externalId,
  } = props
  const isRecCard = React.useMemo(
    () => card.slug === recCard?.slug,
    [card.slug, recCard?.slug],
  )
  const recCardBanner = React.useMemo(
    () => (recCard?.ribbonText ? recCard.ribbonText : recCard?.customBadge),
    [recCard?.ribbonText, recCard?.customBadge],
  )

  return (
    <div
      id={`cart-${card.slug}`}
      className={cn(
        'relative / md:flex-grow lg:grow-0 / sm:w-1/3 / px-2 xxs:px-4 py-5 sm:pt-10 sm:pb-5 xl:px-5 / border-b border-gray-300 sm:border-b-0 sm:border-r',
        {
          'bg-primary-light-bg': isRecCard,
          'lg:w-1/4':
            comparisonCart.length >= 3 &&
            !recCardIsInCart &&
            recCard &&
            isPrime,
        },
      )}
    >
      <div className="flex flex-row flex-wrap / sm:flex-col sm:items-center sm:justify-start / lg:flex-row lg:items-start lg:justify-start">
        {isRecCard && recCardBanner && (
          <div className="-mt-5 sm:-mt-8 w-full shrink-0 mb-0 / sm:text-center lg:text-left">
            <p className="c-ribbon c-ribbon--slimmer c-ribbon--rt / relative inline-block / w-auto mb-2 px-2 py-1 / font-bold text-primary text-xs tracking-wide / bg-tetriary / sm:hidden lg:inline-block">
              {recCardBanner}
            </p>
            <p className="c-ribbon c-ribbon--slimmer / relative / w-auto mx-auto mb-2 px-2 py-1 / font-bold text-primary text-xs tracking-wide / bg-tetriary / hidden sm:inline-block lg:hidden">
              {recCardBanner}
            </p>
          </div>
        )}
        <div className="shrink-0 / w-1/4 sm:w-24 lg:w-1/4 / mb-1 / text-center">
          <div className="c-card-art / w-full lg:mb-1 / text-center flex items-center justify-center text-xs">
            <div className="w-full relative / rounded-sm shadow / overflow-hidden">
              <LinkToRedirect
                externalId={externalId}
                linkParams={card.applyNowLinkParameters}
                orderId={orderId}
                slug={card.slug}
              >
                <div
                  className="relative flex rounded-sm overflow-hidden"
                  aria-label={`Apply for the ${card.name} card`}
                >
                  <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-fs12 text-white">
                    <span className="w-full font-bold">Apply Now</span>
                  </span>
                  <CardArt
                    _rev={card._rev}
                    cardArt={card.cardArt}
                    imgClasses="w-full"
                    issuer={card.issuer.slug.current}
                    name={card.name}
                    orderId={orderId}
                    slug={card.slug}
                    categoryId={categoryId}
                    externalId={externalId}
                  />
                </div>
              </LinkToRedirect>
            </div>
          </div>
          <Link
            href={`/credit-cards/${card.issuer.slug.current}/${card.slug}`}
            className="inline-block / w-full / text-fs11 text-center text-primary-bright leading-snug / hover:underline hover:text-primary-mid"
          >
            More Details
          </Link>
          {card.ratesAndFees && card.ratesAndFees !== 'N/A' && (
            <a
              className="inline-block / w-full / text-fs11 text-center text-primary-bright leading-snug / hover:underline hover:text-primary-mid"
              href={card.ratesAndFees}
              onClick={() => {
                clickRatesAndFees({
                  applyNowLink: card.applyNowLink,
                  component: 'Card Cart Comparison Tile',
                  name: card.name,
                  url: window.location.href,
                })
              }}
              rel="noopener noreferrer"
              target="_blank"
            >
              Rates &amp; Fees
            </a>
          )}
          {card.issuer.slug.current === 'american-express' && (
            <p className="text-fs10 text-gray-600 / text-center / mb-3 md:mb-1 / w-full leading-4">
              Terms Apply
            </p>
          )}
        </div>
        <div className="w-3/4 sm:w-full lg:w-3/4 lg:pl-2 / pl-4 xs:pl-8 pr-8 sm:px-4 xl:pr-5 / sm:text-center lg:text-left">
          <Link
            href={`/credit-cards/${card.issuer.slug.current}/${card.slug}`}
            className="inline-block / w-full mb-1 sm:mb-2 / text-primary-mid leading-snug font-semibold sm:text-sm lg:text-sm / hover:text-primary-bright cursor-pointer / transition-all / focus:outline-none"
            dangerouslySetInnerHTML={{
              __html: supify(card.name),
            }}
          />
          <div className="flex flex-col / mb-2 sm:mb-3 / text-xs">
            <span className="font-bold">Key Benefit</span>
            <PortableText
              value={card.expertReviewHighlight as any}
              components={{
                block: ExpertReviewHighlight as PortableTextBlockComponent,
                types: {
                  cardAttribute: AttributeRenderer(props),
                },
              }}
            />
          </div>

          <span className="inline-block / w-48 lg:w-30 sm:max-w-full lg:max-w-full lg:pr-1">
            <LinkToRedirect
              externalId={externalId}
              linkParams={card.applyNowLinkParameters}
              orderId={orderId}
              slug={card.slug}
            >
              <div className="c-btn c-btn--primary / mb-1 py-1">
                Apply Now
                <span className="ml-1">
                  <div className="relative w-3 h-3 sm:w-4 sm:h-4 / hidden xxs:inline-block align-middle">
                    <ApplyNowIcon
                      aria-hidden="true"
                      role="presentation"
                      fill
                      sizes="100vw"
                    />
                  </div>
                </span>
              </div>
            </LinkToRedirect>
            <p className="text-fs13 text-gray-600 text-center">
              <SecureSite
                issuerName={card.issuer.name}
                issuerSecureSite={card.issuerSecureSite}
              />
            </p>
          </span>
        </div>
      </div>

      <button
        className="flex items-center justify-center / absolute top-0 right-0 / w-6 h-6 / mr-2 mt-3 sm:mr-4 sm:mt-10 lg:mr-2 lg:mt-8 xl:mr-4 / border border-black rounded-full / opacity-25 / hover:opacity-50"
        aria-label="remove Citi Diamond card from your cart"
        onClick={() => updateComparisonCart('remove', [card])}
        type="button"
        aria-controls={`cart-${card.slug}`}
      >
        <div className="w-4">
          <Image
            height={16}
            width={16}
            alt="remove"
            role="presentation"
            aria-hidden="true"
            src="/static/icons/icon-close-black.svg"
            style={nextImageHelper.responsive}
            sizes="100vw"
          />
        </div>
      </button>
      {isRecCard && (
        <div className="absolute bottom-[1.25rem] sm:bottom-[1.25rem] right-[1rem] sm:right-[.5rem] lg:right-[1rem]">
          <LabelAd />
        </div>
      )}
    </div>
  )
}
