import { PortableText, PortableTextBlockComponent } from '@portabletext/react'
import { useMemo } from 'react'
import propOr from 'lodash/fp/propOr'

import mapEntityPositions from 'utils/mapEntityPositions'
import { pinEntities } from 'utils/pin-entities'
import { getSortedAndFilteredBySortPosition } from 'utils/sort/sortPosition'
import { useIsPinningActive } from 'utils/useIsPinningActive'
import { formatPinnedProducts } from 'ssg/utils/format-pinned-products'
import type { CompareCredit } from '../../../../types/compare-credit'
import { SkeletonPreview } from './skeleton'

/**
 * Renders a block with summaries of some cards.
 *
 * Intended for use on a page which presents multiple cards to the user, to
 * provide a summary of the cards on the page with links to the product details
 * page for each.
 */
export const CardSummaryBlock: React.FC<{
  value: {
    cardTips: CompareCredit.CardTip[]
    tag: CompareCredit.CategoryTag
    title?: string
    pinnedCardTips?: CompareCredit.PinnedProduct[]
    sort: string
    referencedCards: null | Record<string, CompareCredit.Entity>
  }
}> = ({
  value: { cardTips, title, pinnedCardTips, sort, referencedCards },
}) => {
  const numberOfCards = cardTips.length

  const isPinningActive = useIsPinningActive(sort)

  const cardsData = useMemo(() => {
    const augmentedCardTips = cardTips.map((cardTip) => {
      const cardSlug = cardTip.card.slug

      const referencedCard = referencedCards?.[cardSlug]

      return {
        ...cardTip,
        cardSlug: cardTip.card.slug,
        sortPosition:
          referencedCard && 'sortPosition' in referencedCard
            ? referencedCard.sortPosition
            : undefined,
      }
    })

    const cardTipsSorted = getSortedAndFilteredBySortPosition(augmentedCardTips)

    const cardTipsWithPinsAppliedIfActive = isPinningActive
      ? pinEntities(
          cardTipsSorted,
          formatPinnedProducts(
            pinnedCardTips?.map((pinnedCard) => ({
              card: {
                _id: pinnedCard.card._id,
                slug: pinnedCard.card.slug,
              },
              frequencyPercent: pinnedCard.frequencyPercent,
            })) ?? [],
          ),
          mapEntityPositions(cardTips.map(({ card: { slug } }) => slug)),
        )
      : cardTipsSorted

    return cardTipsWithPinsAppliedIfActive.map(
      ({ _key, card: { slug: cardSlug }, description }) => ({
        _key,
        cardSlug,
        description,
      }),
    )
  }, [cardTips, referencedCards, sort, isPinningActive, pinnedCardTips])

  return (
    <div className="c-summary-cards-block / mt-4 mb-6 / pt-8 md:pt-10 / text-base leading-normal / border-t-2 border-slate-200">
      <p className="c-summary-cards-block__title / mb-3 / text-base text-primary-mid font-bold">
        {title}
      </p>
      {cardsData.length > 0 ? (
        cardsData.map(({ _key, cardSlug, description }) => (
          <CardSummary
            key={_key}
            cardSlug={cardSlug}
            description={description}
            referencedCards={referencedCards}
          />
        ))
      ) : (
        <SkeletonPreview n={numberOfCards} />
      )}
    </div>
  )
}

const Normal: PortableTextBlockComponent = ({ children }) => <p>{children}</p>

const AttributeRenderer = (
  referencedCards: Record<string, CompareCredit.Entity> | null,
) => {
  const render = ({
    value: {
      attribute,
      bonusRewards,
      cards: { _ref },
    },
  }: {
    value: { attribute: string; bonusRewards: string; cards: { _ref: string } }
  }) => {
    const referencedCard = Object.values(referencedCards ?? {}).find(
      ({ _id }) => _id.includes(_ref),
    )

    const attributeToRender =
      attribute === 'bonusRewards'
        ? bonusRewards
        : propOr('', attribute, referencedCard)

    return attributeToRender ? <span>{attributeToRender}</span> : null
  }
  return render
}

const CardSummary: React.FC<{
  cardSlug: string
  description: CompareCredit.CardTip['description']
  referencedCards: Record<string, CompareCredit.Entity> | null
}> = ({ description, referencedCards }) => {
  return (
    <div className="c-summary-cards-block__content c-copy / mb-3">
      <PortableText
        value={description}
        components={{
          block: { normal: Normal },
          types: {
            advertorialAttribute: AttributeRenderer(referencedCards),
          },
        }}
      />
    </div>
  )
}
