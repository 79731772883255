import React from 'react'
import prop from 'lodash/fp/prop'
import propOr from 'lodash/fp/propOr'

import { CompareCredit } from '../../../types/compare-credit'
import { supify } from '../../utils'

export const AttributeRendererWithSlugV2 = (data: {
  referencedCards: null | Record<string, CompareCredit.Entities>
}) => {
  const render = (props: {
    value: {
      attribute: string
      slug: string
      bonusRewards: string
    }
  }) => {
    const { referencedCards } = data
    const cardSlug = props.value.slug
    const card = prop(cardSlug, referencedCards)
    const attributeToRender =
      props.value.attribute === 'bonusRewards'
        ? props.value.bonusRewards
        : propOr('', props.value.attribute, card)
    return React.createElement('span', {
      dangerouslySetInnerHTML: {
        __html: supify(attributeToRender),
      },
    })
  }
  return render
}
