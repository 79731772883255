export const formatPinnedProducts = (
  pinnedProducts: {
    card: { _id: string; slug: string }
    frequencyPercent: number
  }[],
): Record<string, number> => {
  const formattedPinnedProducts: Record<string, number> = {}

  pinnedProducts?.forEach(({ card, frequencyPercent }) => {
    if (card) {
      formattedPinnedProducts[card.slug] = frequencyPercent
    }
  })

  return formattedPinnedProducts
}
