import React from 'react'
import {
  PortableText,
  PortableTextBlockComponent,
  PortableTextListItemComponent,
} from '@portabletext/react'
import { AttributeRendererWithSlug } from '../sanity-serializers/attributeRenderer'

const Normal = ({ children }: { children: any }) => <p>{children}</p>

const Heading1 = ({ children }: { children: any }) => (
  <h1 className="mt-6 mb-2 / text-primary text-xl font-semibold">{children}</h1>
)
const Heading2 = ({ children }: { children: any }) => (
  <h2 className="mt-6 mb-2 / text-primary text-xl font-semibold">{children}</h2>
)
const Heading3 = ({ children }: { children: any }) => (
  <h3 className="mt-6 mb-2 / text-primary text-xl font-semibold">{children}</h3>
)
const Heading4 = ({ children }: { children: any }) => (
  <h4 className="mt-6 mb-2 / text-primary text-xl font-semibold">{children}</h4>
)
const Heading5 = ({ children }: { children: any }) => (
  <h5 className="mt-6 mb-2 / text-primary text-xl font-semibold">{children}</h5>
)
const Heading6 = ({ children }: { children: any }) => (
  <h6 className="mt-6 mb-2 / text-primary text-xl font-semibold">{children}</h6>
)

const listItem = ({ children }: { children: any }) => (
  <li className="mb-1">{children}</li>
)

export function CategoryDescription(props: any) {
  const { description, referencedCards } = props

  React.useEffect(() => {
    const olList = document.getElementsByTagName('ol')
    for (let i = 0; i < olList.length; i++) {
      olList[i].classList.add('list-decimal', 'pl-6')
    }
    // TODO: when/if we create content that includes unordered lists
    // will need to add whatever class names we want for those
  }, [props.description])

  return (
    <div id="content-bottom" className="pt-8 pb-8 text-gray-700 text-sm">
      <hr className={`mb-8`} />

      <div className="c-copy">
        <PortableText
          value={description}
          components={{
            block: {
              normal: Normal as PortableTextBlockComponent,
              // TODO: right now this checks for any type of heading
              // and applies the same class list to all.
              // if we eventually have headings other than h3 and want
              // different styling, will need to change this accordingly
              h1: Heading1 as PortableTextBlockComponent,
              h2: Heading2 as PortableTextBlockComponent,
              h3: Heading3 as PortableTextBlockComponent,
              h4: Heading4 as PortableTextBlockComponent,
              h5: Heading5 as PortableTextBlockComponent,
              h6: Heading6 as PortableTextBlockComponent,
            },
            types: {
              advertorialAttribute: AttributeRendererWithSlug(referencedCards),
            },
            listItem: listItem as PortableTextListItemComponent,
          }}
        />
      </div>
    </div>
  )
}
