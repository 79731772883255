import React from 'react'
import { CardArt, LinkToRedirect } from '..'
import { CompareCredit } from '../../../types/compare-credit'
import cn from 'utils/classnames'
import { ApplyNowIcon } from '../link-to-redirect/ApplyNowIcon'

export const CardNav = (props: {
  card: CompareCredit.Entities
  categoryId: string
  externalId: string
  offerText?: string | null
  orderId: string
  type: 'card-nav' | 'card-nav-offer'
}) => {
  const { categoryId, externalId, offerText, orderId, type } = props
  const [browserSize, setBrowserSize] = React.useState({
    width: 0,
  })
  const [card, setCard] = React.useState(props.card)
  const [cardNavIsSticky, setCardNavIsSticky] = React.useState(false)

  React.useEffect(() => {
    setCard(props.card)
  }, [props.card])

  // Display card nav on scroll
  React.useEffect(() => {
    let pgOffset = 250

    // Capture window width/height on resize
    const handleResize = () =>
      setBrowserSize({
        width: window.innerWidth,
      })
    window.addEventListener('resize', handleResize)

    // Reset pgOffset cutoff
    if (browserSize.width > 767) {
      pgOffset = 580
    }

    const handleCardNavStick = () =>
      setCardNavIsSticky(window.pageYOffset > pgOffset)
    window.addEventListener('scroll', handleCardNavStick)

    return () => {
      window.removeEventListener('scroll', handleCardNavStick)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div
      aria-hidden={cardNavIsSticky}
      className={cn(
        'c-card-sticky / fixed left-0 z-70 / lg:hidden flex items-center / w-full / leading-snug bg-white shadow-lg / transition-all--25',
        {
          'is-shown': cardNavIsSticky,
        },
      )}
    >
      {card && (
        <div className="container-fluid / md:flex md:items-center md:justify-center / py-2 xs:py-3">
          <div
            className={cn('flex items-center justify-center / md:max-w-6xl', {
              'flex-wrap': type !== 'card-nav',
            })}
          >
            {type === 'card-nav' ? (
              <>
                <CardImage
                  card={card}
                  categoryId={categoryId}
                  externalId={externalId}
                  orderId={orderId}
                  type={type}
                />
                <CardName
                  card={card}
                  orderId={orderId}
                  type={type}
                  externalId={externalId}
                />
              </>
            ) : (
              <>
                <CardName
                  card={card}
                  orderId={orderId}
                  type={type}
                  externalId={externalId}
                />
                <CardImage
                  card={card}
                  categoryId={categoryId}
                  externalId={externalId}
                  orderId={orderId}
                  type={type}
                />
              </>
            )}
            {card._type === 'card' && (
              <div className="flex flex-col shrink-0 / pl-2 md:pl-3">
                {type === 'card-nav-offer' && (
                  <div className="mb-0.5 / uppercase text-primary font-bold">
                    {offerText}
                  </div>
                )}
                <LinkToRedirect
                  externalId={externalId}
                  linkParams={card.applyNowLinkParameters}
                  orderId={orderId}
                  slug={card.slug}
                >
                  <div
                    className={cn(
                      'flex flex-row items-center justify-center / md:w-auto / font-semibold text-white / rounded-sm / border border-secondary / bg-secondary hover:bg-secondary-dark',
                      {
                        'w-24 xxs:w-28 xs:w-32 sm:w-40 / p-1 sm:px-5 md:px-6 / text-fs13 xs:text-sm sm:text-base':
                          type === 'card-nav',
                        'w-36 xxs:w-40 / px-3 py-0.5 xs:py-1 / text-base':
                          type !== 'card-nav',
                      },
                    )}
                  >
                    Apply Now
                    <span className="hidden xxs:flex relative / w-3 h-3 sm:w-4 sm:h-4 / ml-1 xs:ml-2">
                      <ApplyNowIcon fill sizes="100vw" />
                    </span>
                  </div>
                </LinkToRedirect>
              </div>
            )}
          </div>
        </div>
      )}
      <style jsx>
        {`
          .c-card-sticky {
            top: -5rem;
            min-height: 4rem;
            opacity: 0;
          }
          .c-card-sticky.is-shown {
            opacity: 1;
            transform: translateY(5rem);
          }
          @media (min-width: 1024px) {
            .c-card-sticky {
              min-height: 5rem;
            }
          }
        `}
      </style>
    </div>
  )
}

const CardImage = (props: {
  card: CompareCredit.Entities
  categoryId: string
  externalId: string
  orderId: string
  type: string
}) => {
  const { card, categoryId, externalId, orderId, type } = props

  return (
    <div
      className={cn('flex / pr-2 md:pr-3', {
        'items-center justify-center': type !== 'card-nav',
      })}
    >
      {card && (
        <div
          className={cn(
            'c-card-art / flex items-center justify-center / mx-auto / text-center',
            {
              'w-14 xs:w-20': type === 'card-nav',
              'w-20 xs:w-22': type !== 'card-nav',
            },
          )}
        >
          <div className="relative / w-full / rounded-sm shadow overflow-hidden">
            {card._type === 'card' && (
              <LinkToRedirect
                externalId={externalId}
                linkParams={card.applyNowLinkParameters}
                orderId={orderId}
                slug={card.slug}
              >
                <div className="relative / flex / rounded-sm overflow-hidden">
                  <CardArt
                    _rev={card._rev}
                    cardArt={card.cardArt}
                    categoryId={categoryId}
                    externalId={externalId}
                    customCodeSnippets={card.customCodeSnippets}
                    imgClasses="relative top-0 z-0 / w-full h-full / object-cover"
                    issuer={card.issuer.slug.current}
                    name={card.name}
                    orderId={orderId}
                    slug={card.slug}
                  />
                </div>
              </LinkToRedirect>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

const CardName = (props: {
  card: CompareCredit.Entities
  orderId: string
  type: string
  externalId: string
}) => {
  const { card, orderId, type, externalId } = props
  return (
    <div
      className={cn('flex flex-col / md:px-3', {
        'pl-2 sm:pr-2': type === 'card-nav',
        'w-full': type !== 'card-nav',
      })}
    >
      <h4
        className={cn(
          'mb-1 / font-sans font-semibold xxs:text-sm xs:text-base sm:text-lg  leading-tight',
          {
            'text-left text-fs13': type === 'card-nav',
            'c-card-sticky__title / text-center text-fs11': type !== 'card-nav',
          },
        )}
      >
        {card._type === 'card' && (
          <LinkToRedirect
            externalId={externalId}
            linkParams={card.applyNowLinkParameters}
            orderId={orderId}
            slug={card.slug}
          >
            <div className="c-card-title / text-primary-mid hover:text-primary-bright / transition-all">
              {card.name}
            </div>
          </LinkToRedirect>
        )}
      </h4>
    </div>
  )
}
