import { CompareCredit } from '../../types/compare-credit'
import * as Sentry from '@sentry/nextjs'

/**
 * buildSecureHref - builds out the secure page link
 * @param props
 */
export const buildSecureHref = (props: {
  linkParams?: CompareCredit.Card['applyNowLinkParameters']
  slug: string
  query?: string
  orderId: string
  externalId: string
  profileId?: string
  position?: number
  pathName: string
  expGroup: 'control' | 'direct' | null
}) => {
  const {
    orderId,
    profileId,
    position,
    linkParams,
    slug,
    query,
    externalId,
    pathName,
    // expGroup,
  } = props

  if (!externalId) {
    Sentry.captureException(
      new Error('External ID is missing and is required for redirect links.'),
      {
        contexts: {
          data: {
            url: pathName,
          },
        },
      },
    )
  }

  const orderIdParam = orderId ? `&order_id=${orderId}` : ''
  const positionParam = position ? `&position=${position}` : ''
  const applyLinkParam = [
    ...(profileId ? [`&profileId=${profileId}`] : []),
    ...(linkParams ? linkParams.map((i: any) => `&${i.parameter}`) : []),
  ].join('')
  const encodedPathParam = encodeURIComponent(pathName)
  const pathParam = `&path_name=${encodedPathParam}`
  const commonParams = `category_ref=${externalId}${orderIdParam}${positionParam}${applyLinkParam}${pathParam}`
  // const path =
  //   {
  //     control: 'secure',
  //     direct: 'secure-direct',
  //   }[expGroup as string] || 'secure'
  return query
    ? `/secure/${slug}${query}&${commonParams}`
    : `/secure/${slug}?${commonParams}`
}
