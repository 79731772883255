import React from 'react'
import {
  PortableText,
  PortableTextListComponent,
  PortableTextListItemComponent,
} from '@portabletext/react'
import { blockSerializers } from './index'
import { CompareCredit } from '../../../types/compare-credit'

const List = ({ children }: { children: any }) => (
  <ul className="c-list-disc / pl-5">{children}</ul>
)

const ListItem = ({ children }: { children: any }) => {
  return (
    <li className={`c-list-disc__item`}>
      {children}{' '}
      <style>
        {`
          .c-list-disc__item {
            margin-bottom: 0.25em;
          }
          .c-list-disc__item:last-child {
            margin-bottom: 0;
          }
        `}
      </style>
    </li>
  )
}

export const HighlightModule = (data: {
  cardOrderIds: Record<string, string>
  referencedCards: null | Record<string, CompareCredit.Entities>
  categoryId: string
  externalId: string
}) => {
  const HighlightModuleRender = (props: {
    value: {
      hideHighlightBorder: boolean
      highlightBottomBorder: boolean
      highlightTitle: string
      highlightContent: any
      borderBottom: boolean
      borderBottomClr?: string
    }
  }) => {
    const {
      hideHighlightBorder,
      highlightTitle,
      highlightContent,
      borderBottom,
      borderBottomClr,
    } = props.value
    const { cardOrderIds, referencedCards, categoryId, externalId } = data

    const largeText = false

    return (
      <div
        className={`relative / w-full / mb-4 mt-2 / ${
          hideHighlightBorder
            ? ''
            : 'py-4 px-3 xs:px-6 / leading-snug / border-2 border-primary-bright'
        }
      ${borderBottom ? 'pb-3 border-b-2' : ''}
          ${
            borderBottom && borderBottomClr
              ? borderBottomClr
              : 'border-primary-bright'
          }

      ${largeText ? 'text-lg' : ''}
     `}
      >
        <div
          className={`${
            largeText
              ? 'text-lg'
              : hideHighlightBorder
              ? 'text-base'
              : 'text-sm xs:text-base'
          }`}
        >
          <p
            className={`inline-block / font-bold ${
              hideHighlightBorder
                ? 'mb-2 / text-primary leading-relaxed'
                : '-top-3 left-2 sm:l-4 / absolute / px-3 / text-primary-bright / bg-white'
            }`}
          >
            {highlightTitle}
          </p>
        </div>

        <PortableText
          value={highlightContent}
          components={{
            ...blockSerializers({
              cardOrderIds,
              categoryId,
              externalId,
              referencedCards,
            }),
            list: List as PortableTextListComponent,
            listItem: ListItem as PortableTextListItemComponent,
          }}
        />
      </div>
    )
  }
  return HighlightModuleRender
}
