import path from 'lodash/fp/path'
import Image from 'next/image'
import { useContext, useEffect, useMemo, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'

import { clickProduct, viewProduct } from 'clients/segment'
import { UserContext, useAwardsBadgeContext } from 'components/'
import { CreditCardLinkedData } from 'components/linked-data/card'
import { nextImageHelper } from 'utils/'
import { generateNewOrderId } from 'utils/orderId/generateNewOrderId'
import type { CompareCredit as CC } from '../../../types/compare-credit'
import {
  BottomSection,
  CollapseDetails,
  CustomBadge,
  LeftSection,
  PanelContainer,
  PanelContents,
  TopSection,
} from './sections'
import type { CompareCredit } from './types'

// TODO: having some types in this package and others in `packages/types` is a
// bit confusing. Might be worth going with one route or the other and sticking
// to it. One thought is having the types in `packages/types` be the base types
// that come straight out of Sanity, because those are the types likely to be
// shared across other packages (ETL, etc). Then anything that relates to UI
// would be co-located with the UI.

export const CardPanel: React.FC<
  {
    categoryId: string
    externalId: string
    entity: CC.FormattedCard | CC.NonPaidCard
    collapsed?: boolean
    propsOrderId?: string
    sortable?: boolean
  } & Partial<CompareCredit.CardPanelProps>
> = ({
  categoryId,
  externalId,
  entity,
  view = 'list',
  collapsed = false,
  propsOrderId,
  position,
  arrangementId,
  onClose,
  comparisonDisabled,
  featured,
  category,
  ...restProps
}) => {
  const orderId: string = useMemo(
    () => propsOrderId || generateNewOrderId(),
    [propsOrderId],
  )
  const [isCollapsed, setCollapsed] = useState(
    view === 'list' ? collapsed : false,
  )
  const { comparisonCart, updateComparisonCart } = useContext(UserContext)
  const { getBadgeData } = useAwardsBadgeContext()

  const handleCollapseDetails = (collapsed: boolean) => {
    if (!collapsed) {
      clickProduct({
        brand: issuerName,
        name: entity.name,
        sku: entity.slug,
      })
    }
    setCollapsed(collapsed)
  }

  const handleUpdateCart = (action: 'add' | 'remove') => {
    updateComparisonCart(action, [entity as CC.FormattedCard])
  }

  const cardIsInCart = useMemo(
    () =>
      comparisonCart.some(
        (comparisonCartCard) => comparisonCartCard.slug === entity.slug,
      ),
    [comparisonCart, entity],
  )

  useEffect(() => {
    const productDetails = {
      brand: entity.issuer.slug.current,
      id: entity.slug,
      product_id: entity.slug,
      name: entity.name,
      sku: entity.slug,
    }

    if (view === 'details' || view === 'modal') {
      viewProduct(productDetails)
    }
  }, [])

  const { showBadge, badgeTitle } = useMemo(
    () => getBadgeData(entity.slug, position),
    [entity.slug, position, getBadgeData],
  )

  const isDetail = view === 'details'
  const isModalOrDetail = view === 'details' || view === 'modal'
  const issuerName = path(['issuer', 'name'], restProps)

  return (
    <PanelContainer isDetail={isDetail} view={view} isCollapsed={isCollapsed}>
      <>
        {onClose && (
          <button onClick={onClose}>
            <img
              alt=""
              aria-label="Close"
              className="cursor-pointer absolute top-0 right-0 / w-8 / mt-1 mr-1 md:mt-4 md:mr-4 / opacity-25 / hover:opacity-75 focus:outline-none / transition-all z-10"
              src="/static/icons/icon-close-circle-black.svg"
            />
          </button>
        )}
        {entity.customBadge && view !== 'details' && (
          <CustomBadge text={entity.customBadge} view={view} />
        )}

        {showBadge && badgeTitle && (
          <div className="-mb-8 md:-mb-5 lg:-mb-6 / px-10 md:pl-14 lg:pl-18 xl:pl-20 / md:pr-0 pt-4 / text-center md:text-left">
            <div
              className="inline-block / w-auto relative / mb-3 / md:px-0 /
              uppercase text-primary font-bold text-center tracking-wide leading-snug text-sm lg:text-base"
            >
              <span className="inline-block / absolute -top-[2px] lg:-top-[4px] -left-8 lg:-left-10 / shrink-0 / w-5 lg:w-6">
                <Image
                  width={16}
                  height={16}
                  alt="CompareCredit Awards Ribbon"
                  src="/static/awards/cc-awards-ribbon-small-clr.svg"
                  aria-hidden={true}
                  sizes="100vw"
                  style={nextImageHelper.responsive}
                />
              </span>
              <span>{badgeTitle}</span>
            </div>
          </div>
        )}
        <PanelContents view={view}>
          <LeftSection
            categoryId={categoryId}
            externalId={externalId}
            orderId={orderId}
            updateCart={handleUpdateCart}
            inCart={cardIsInCart}
            featured={featured}
            entity={entity}
            show={view === 'list'}
            comparisonDisabled={comparisonDisabled}
            position={position}
            arrangementId={arrangementId}
            view={view}
            sortable={view === 'list'}
            isCollapsed={isCollapsed}
            setCollapsed={handleCollapseDetails}
          />
          <TopSection
            categoryId={categoryId}
            externalId={externalId}
            orderId={orderId}
            entity={entity}
            featured={isModalOrDetail ? false : featured}
            updateCart={handleUpdateCart}
            inCart={cardIsInCart}
            view={view}
            isCollapsed={isCollapsed}
            setCollapsed={handleCollapseDetails}
            category={category || ''}
            isMobile={isMobileOnly}
            comparisonDisabled={comparisonDisabled}
            position={position}
          />
          <BottomSection
            orderId={orderId}
            entity={entity}
            setCollapsed={setCollapsed}
            isCollapsed={view === 'list' ? isCollapsed : false}
            view={view}
          />
        </PanelContents>
        {view === 'list' && (
          <CollapseDetails
            orderId={orderId}
            entity={entity}
            isCollapsed={isCollapsed}
            setCollapsed={handleCollapseDetails}
          />
        )}
        <CreditCardLinkedData card={entity} />
      </>
    </PanelContainer>
  )
}
