type TextBlock = {
  _type: string
  children: {
    _type: string
    attribute?: string
    slug?: string
    bonusRewards?: string
  }[]
}

export const extractAdvertorialAttributeSlug = (
  arr: TextBlock[] | null,
): { attribute: string; slug: string }[] => {
  if (arr == null) return []
  const result = arr
    .map((child) => {
      return child.children
        ? child.children
            .filter(
              (block) => block._type === 'advertorialAttribute' && block.slug,
            )
            .map((block) => {
              return {
                slug: block?.slug as string,
                attribute: block.attribute as string,
                bonusRewards: block?.bonusRewards,
              }
            })
        : []
    })
    .flat()
  return result
}

export const extractCardAttributeSlugs = (
  arr: TextBlock[] | null,
): string[] => {
  return Object.keys(
    extractAdvertorialAttributeSlug(arr).reduce(
      (acc: Record<string, string>, val) => {
        const { slug } = val
        acc[slug] = slug
        return acc
      },
      {},
    ),
  )
}
