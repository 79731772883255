import React from 'react'
import {
  PortableText,
  PortableTextListComponent,
  PortableTextListItemComponent,
} from '@portabletext/react'
import { blockSerializers } from './index'

import { CompareCredit } from '../../../types/compare-credit'
import cn from 'utils/classnames'

/* eslint-disable react/display-name */
const List = ({ children }: { children: any }) => (
  <ul className="c-list-disc / mb-2 pl-5">{children}</ul>
)

const ListItem = ({ children }: { children: any }) => {
  return (
    <li className={`c-list-disc__item c-list-disc__item--gold`}>
      {children}{' '}
      <style jsx>
        {`
          .c-list-disc__item {
            margin-bottom: 0.25em;
          }
          .c-list-disc__item:last-child {
            margin-bottom: 0;
          }
        `}
      </style>
    </li>
  )
}

export const CalloutContent =
  (data: {
    cardOrderIds: Record<string, string>
    referencedCards: null | Record<string, CompareCredit.Entities>
    categoryId: string
    externalId: string
  }) =>
  (props: {
    value: {
      content: any
      smStyling?: string
      mdStyling?: string
      lgStyling?: string
    }
  }) => {
    const { content, smStyling, mdStyling, lgStyling } = props.value
    const { cardOrderIds, referencedCards, categoryId, externalId } = data

    return (
      <div
        className={cn(
          'c-callout-content / mt-4 mb-5 pl-6 / border-l-4 border-tetriary',
          smStyling,
          mdStyling,
          lgStyling,
        )}
      >
        <div className={`c-callout-content__wrap`}>
          <div className="c-callout-content__content / mb-lc-0">
            <PortableText
              value={content}
              components={{
                ...blockSerializers({
                  cardOrderIds,
                  categoryId,
                  externalId,
                  referencedCards,
                }),
                list: List as PortableTextListComponent,
                listItem: ListItem as PortableTextListItemComponent,
              }}
            />
          </div>
        </div>
        <style jsx>
          {`
            .c-callout-content__content b {
              color: #052f5e;
            }
          `}
        </style>
      </div>
    )
  }
