import Link from 'next/link'
import { useMemo } from 'react'

import { generateNewOrderId } from 'utils/orderId/generateNewOrderId'
import type { CompareCredit } from '../../../types/compare-credit'
import { StarRatingEditor } from '..'
import { CardArt } from '../card-art'
import { LoadingSkeleton } from './loading-skeleton'

export const TopCardsEducation: React.FC<{ cards: CompareCredit.Entity[] }> = ({
  cards,
}) => {
  return (
    <div className="mb-12">
      <p className="mb-4 pb-2 / font-bold text-fs13 xxs:text-sm sm:text-lg lg:text-sm text-primary text-center tracking-wide / border-b">
        Top Credit Cards from Our Partners
      </p>
      {cards.length > 0 ? (
        <ul className="flex flex-col lg:flex-col / leading-tight">
          {cards.map((card, i) => (
            <Card key={i} card={card} />
          ))}
        </ul>
      ) : (
        <LoadingSkeleton n={3} />
      )}
    </div>
  )
}

const Card: React.FC<{ card: CompareCredit.Entity }> = ({ card }) => {
  const orderId = useMemo(() => generateNewOrderId(), [])

  if (card._type !== 'card') return null

  const href = `/credit-cards/${card.issuer.slug.current}/${card.slug}`
  return (
    <li className="flex items-center lg:items-start justify-center / w-full / mb-4 md:mb-6 lg:mb-4 / cursor-pointer">
      <Link href={href} className="flex shrink-0 / w-1/3 sm:w-1/4 lg:w-1/3">
        <span className="inline-block / w-full / rounded-sm overflow-hidden">
          <CardArt
            _rev={card._rev}
            cardArt={card.cardArt}
            customCodeSnippets={card.customCodeSnippets}
            issuer={card.issuer.slug.current}
            name={card.name}
            slug={card.slug}
            categoryId={null}
            externalId={null}
            orderId={orderId}
          />
        </span>
      </Link>
      <div className="w-2/3 sm:w-3/4 lg:w-2/3 / pl-2 xxs:pl-4 sm:pl-6 sm:pt-3 / lg:pt-0 lg:pl-4 / text-left">
        <Link
          href={href}
          className="block / text-fs13 xxs:text-base sm:text-lg lg:text-fs13 text-primary-mid font-bold / transition-all / hover:text-primary-bright"
        >
          {card.name}
        </Link>
        <StarRatingEditor
          productSlug={card.slug}
          stars={card.expertReviewRating}
        />
      </div>
    </li>
  )
}
