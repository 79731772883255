import { useStatsigClient } from '@statsig/react-bindings'
import { usePathname, useSearchParams } from 'next/navigation'
import { useMemo } from 'react'

export const useShouldShowChaseFu = () => {
  const statsigClient = useStatsigClient()
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const isValidPath =
    pathname === '/credit-cards/tips/pay-0-interest-v1-hcpa/' &&
    searchParams.get('utm_medium')?.toLowerCase() === 'social'

  const isMetaInAppBrowser = useMemo(() => {
    return /FBAN|FBAV|Instagram/.test(navigator.userAgent)
  }, [])

  const shouldShowChaseFu = useMemo(() => {
    const shouldBeInExperiment = isValidPath && isMetaInAppBrowser

    return shouldBeInExperiment
      ? statsigClient
          .getExperiment('exp_370_chase_social_kickout_v2')
          .get('should_show_chase_fu', false)
      : true
  }, [statsigClient, isValidPath, isMetaInAppBrowser])

  return shouldShowChaseFu
}
