import type { CompareCredit } from '../../../../types/compare-credit'

export const getIssuer = (card: CompareCredit.Entity): string => {
  const { _type } = card

  switch (_type) {
    case 'card':
    case 'nonPaidCard':
      return card.issuer.slug.current
    case 'product':
      return card.brand.slug.current
  }
}
